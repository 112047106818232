.banner {
  height: 500px;
  position: relative;
  display: inline-block;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
  z-index: 9;
}

.banner .pic-box {
  width: 240px;
  height: 250px !important;
  padding: 0;
}

.banner .pic,
.banner .slick-slide img {
  width: 100%;
  height: 100%;
}

.banner .pic img {
  width: 100%;
}

.banner .row {
  margin: 0;
}

.banner .slick-slider,
.banner .slick-slider .slick-list,
.banner .slick-slider .slick-track,
.banner .slick-slider .slick-track div {
  height: 100%;
}

.banner .slick-slider {
  overflow: hidden;
}

.banner .slick-slider .slick-dots {
  bottom: 40px;
}

.banner .slick-prev,
.banner .slick-next {
  z-index: 99;
  width: 50px;
  height: 100px;
  background: #fff;
  left: 0px;
  opacity: 0.6;
}

.banner .slick-next {
  right: 0;
  left: initial;
}

.banner .slick-prev:before,
.banner .slick-next:before {
  content: "\f104";
  font-family: "FontAwesome";
  font-size: 40px;
  color: #000;
  opacity: 1;
}

.banner .slick-next:before {
  content: "\f105";
}

.banner .slick-slider .slick-slide {
  position: relative;
}

.banner-text {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  color: #fff;
  transform: translate(-50%, -50%);
  text-align: center;
  height: auto !important;
  width: 80%;
}

.banner-text .banner-title {
  font-size: 60px;
  font-weight: 700;
  font-family: "thirsty_script_extrabold_deRg";
  line-height: 70px;
  color: #fff !important;
}

.coivd-alert {
  position: absolute;
  bottom: 0;
  z-index: 99;
  font-size: 20px;
  color: #25408f;
  font-family: "MontserratRegular";
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: rgba(255, 255, 255, 0.8);
}

.coivd-alert p {
  display: initial !important;
  color: #25408f !important;
  /* font-weight: 600; */
}