.TN-accordion {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  ul.bullets li {
    list-style: disc;
  }

  &__icon {
    position: absolute;
    right: 20px;
  }

  button.btn-secondary {
    color: $color-white !important;
    background: $color-secondary !important;
    border: 0px !important;
    box-shadow: 0px 13px 27px -5px rgba(50, 50, 50, 0.25);
    padding-right: 30px;

    &:focus {
      box-shadow: none !important;
    }

    &.i-open::before {
      background-image: url("../../img/benefit/drop-down.png");
      background-repeat: no-repeat;
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      background-size: contain;
      right: 5px;
      transform: rotate(180deg);
      transition: 300ms linear all;
    }
   

    &.i-close::before {
      background-image: url("../../img/benefit/drop-down.png");
      background-repeat: no-repeat;
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      background-size: contain;
      right: 5px;
      transition: 300ms linear all;
    }

    &.i-opencheck::before {
      background-image: url("../../img/benefit/expand-down.png") !important;
      //right: 5px !important;
    }

    &.i-closecheck::before {
      background-image: url("../../img/benefit/expand-down.png") !important;
      //right: 5px !important;
      
    }
  }
}
