.header-benefits {
  padding: 80px 10px;
  margin-bottom: 120px;

  &::before {
    background-image: url("../../img/Benefits_Header.png");
    // top: -40px;
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: inherit;
    width: 100%;
    height: 100vh;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    background-position: right top;

    @media screen and (max-width: 1200px) {
      background-size: contain;
    }
  }

  &-mobile,
  .desktop-hide {
    display: none;
    margin-top: -50px;
  }

  &__text-box p {
    color: $color-primary-light !important;
  }

  &__sub-heading {
    color: $color-secondary;
  }

  .heading-primary {
    font-family: "Domine" !important;
    color: $color-primary;
    margin-bottom: 0px !important;
    font-family: normal;
    text-align: left;
    margin-bottom: 70px;
    font-size: 55px !important;
    line-height: 60px !important;

    @media screen and (max-width: 1200px) {
      font-size: 40px !important;
      line-height: 40px !important;
    }
  }

  .animatted-content {
    animation: moveInBottom 0.5s ease-out 0.75s;
  }

  @media screen and (max-width: 1024px) {
    &::before {
      height: 95vh;
      width: 50%;
    }

    // &::after {
    //   background-position: 351px -172px, 0px 0px !important;
    // }
    & {
      padding-bottom: 0px !important;
      margin-bottom: 100px;
    }

    &__bg {
      figure {
        right: -14%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0px;

    &::before {
      display: none !important;
    }

    margin-bottom: 0px;
    height: 100vh;
    // background-image: url("../../img/Path_1.svg");
    background-size: cover;
    background-position: bottom;
    position: relative;
    background-image: linear-gradient(to right top,
      #21ade4,
      #0ba4ea,
      #108bd5,
      #1a73be,
      #25408f);

    .heading-primary {
      font-size: 35px !important;
      line-height: 39px !important;
      margin-bottom: 25px !important;
    }

    &_mobile--hide,
    .mobile-hide {
      display: none;
    }

    .desktop-hide {
      display: block;
    }

    &__text-box {
      padding-top: 50px;
      // margin-bottom: 35px;
    }

    h1 {
      animation: none !important;
    }

    &-mobile {
      margin-top: 0px;
      display: block;
      // background-image: url("../../img/Path_1.svg");

      // background-image: linear-gradient(
      //   to right top,
      //   rgb(33, 173, 228),
      //   rgb(11, 164, 234),
      //   rgb(16, 139, 213),
      //   rgb(26, 115, 190),
      //   rgb(37, 64, 143)
      // );

      background: linear-gradient(to bottom,
          rgba(24, 189, 253, 1) 0%,
          rgba(70, 198, 247, 1) 42%,
          rgba(118, 209, 241, 1) 82%,
          rgba(120, 209, 241, 1) 84%);

      // height: 112vh;
      font-family: normal;
      padding-top: 40px;
      animation: moveInTop 3s ease-in-out;
      //animation: expand 5s ease-in-out;
      //-webkit-animation:  5s;
      position: absolute;
      top: 0;
      // display: inline-table;

      height: 400vh;
      // clip-path: ellipse(103% 100% at 52% 0%);
      // clip-path: ellipse(103% 100% at 52% 0%);
      // -webkit-clip-path: ellipse(103% 100% at 52% 0%);
      // background: linear-gradient(
      //   to bottom,
      //   #18bdfd 0%,
      //   #46c6f7 42%,
      //   #76d1f1 82%,
      //   #78d1f1 84%
      // );

      h1 {
        font-family: "Domine" !important;
        color: $color-primary !important;
        animation: moveInTop 4s ease-in-out;
        font-weight: bold;
        //font-size: 44px !important;
      }

      p {
        font-family: "Gotham book" !important;
        color: $color-white !important;
        line-height: 30px;
        font-weight: normal !important;
        font-size: 20px;
        animation: moveInTop 4s ease-in-out;

        span {
          margin-top: 15px;
          display: inline-block;
          transition: all 1s ease;

          &::before {
            @include bottom-arrow;
            position: relative !important;
            bottom: -15px;
            // bottom: -21px;
          }

          // @include bottom-arrow;
        }

        // &::before {
        //   @include bottom-arrow;
        //   bottom: -21px;
        // }
      }
    }
  }
}

.benefits--details {
  position: relative;

  &--image {
    text-align: -webkit-right;
  }

  h5 {
    color: $color-primary;
    font-weight: bold;
    font-family: "Gotham book" !important;
  }

  p {
    font-family: "Gotham book" !important;
    font-size: 18px;
    line-height: 27px;
    color: #707070;
  }

  @media screen and (max-width: 991px) {
    .card {
      box-shadow: 10px 10px 8px 8px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 10px 10px 8px 8px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 10px 10px 8px 8px rgba(0, 0, 0, 0.1);
    }
  }

  @media screen and (max-width: 767px) {
    .card {
      text-align: center;
    }

    .benefits--details--image {
      width: 100%;
      text-align: center;
      padding-top: 25px;
    }
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.benefits-mobile-header {
  display: none;
}

@media screen and (max-width: 600px) {
  .benefits-mobile-header {
    display: block;
    // background-image: url("../../img/benefit_movile_bottom.svg");
    clip-path: ellipse(95% 100% at 52% 0%);
    background: linear-gradient(to bottom,
        #18bdfd 0%,
        #46c6f7 42%,
        #76d1f1 82%,
        #78d1f1 84%);
    background-repeat: no-repeat;
    width: 100%;
    // height: 100vh;
    padding: 10px 0px 30px 17px;
    background-size: contain;

    // position: absolute;
    // z-index: 999;

    // margin-bottom: 150px;

    p {
      font-size: 20px;
      line-height: 30px;
      font-family: "Gotham book";
    }
  }

  .header-benefits-mobile:after {
    -webkit-clip-path: ellipse(103% 100% at 52% 0);
  }
}

.header-benefits-mobile:after {
  -webkit-clip-path: ellipse(103% 100% at 52% 0);
}

.benefits-mobile-ui {
  display: none;
  opacity: 0;

  transition-duration: 3s;
  transition-delay: 1s;

  p.bottom-text {
    color: $color-primary;
  }

  @media screen and (max-width: 601px) {
    display: block;
    position: relative;
    // height: 100vh;
    margin-top: 50px;
  }

  &-slider {
    // margin-top: -48vh;
    display: initial;

    // position: absolute;
    // width: 100%;
  }

  .slick-next {
    right: 0px;
    height: unset;
  }

  .slick-prev {
    left: 0px;
    height: unset;
  }

  .footer-text {
    flex-basis: 0;
    overflow: hidden;
    transition: all 1s ease;
    // z-index: -999;
  }

  & .pop-card {
    // position: absolute;
    // top: 0;
    // width: 100%;
    // height: 100%;
  }

  .card {
    box-shadow: 10px 10px 8px 8px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 10px 10px 8px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 10px 8px 8px rgba(0, 0, 0, 0.1);
  }

  .slick-slide img {
    display: initial !important;
  }

  .card {
    border-radius: 50px;
    text-align: center;
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 15px;

    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    // margin-top: -250px;
    margin-top: -500px;

    &-text {
      padding-bottom: 30px;
    }

    h5 {
      margin-bottom: 15px !important;
    }

    &::before {
      @include bottom-arrow;
    }
  }

  &:hover {
    -webkit-transform: translateY(-5px) scale(1);
    transform: translateY(-5px) scale(1);
  }

  .slick-slider {
    margin: auto 10px;
    // margin-top: 120%;
    // margin-top: 90%;
  }

  .slick {

    &-prev:before,
    &-next:before {
      content: "";
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      display: inline-block;
    }

    &-prev:before {
      border-right: 15px solid #707070;
    }

    &-prev:next {
      border-left: 15px solid #707070;
    }
  }

  .slick-list {
    overflow: inherit;
    // overflow-y: auto;
    // overflow-y: inherit;
    // overflow-x: scroll;
    // z-index: 999;
    // height: 300px;
  }

  // .slick-initialized {
  //   overflow: hidden;
  // }

  .slick-prev:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #707070;
    display: inline-block;
  }

  .slick-next:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid #707070;
    border-bottom: 15px solid transparent;
    display: inline-block;
  }

  .slick-dots {
    position: initial;
    bottom: -20px;
    z-index: -1;

    li {
      margin: 0px !important;
    }
  }
}

.benefit_qa {
  &-row {
    width: 80%;
    margin: 0 auto;
  }
}

// .benefits-main {
//   height: 100vh;
//   overflow-y: auto;
//   scroll-snap-type: y mandatory;
//   -webkit-overflow-scrolling: touch;
// }

*,
:after,
:before,
:focus {
  // margin: 0;
  // padding: 0;
  box-sizing: inherit;
  outline: 0;
}

body {
  overflow-x: hidden;
}

.benefit-ani {
  // animation: benefits100 5s;
  // animation-delay: 1s;
}

@keyframes benefits100 {
  0% {
    height: 100vh;
    clip-path: ellipse(103% 100% at 52% 0%);
    -webkit-clip-path: ellipse(103% 100% at 52% 0%);
  }

  //
  // 50% {
  //   height: 50vh;
  // }
  100% {
    height: 42%;
    clip-path: ellipse(103% 100% at 52% 0%);
    -webkit-clip-path: ellipse(103% 100% at 52% 0%);
  }
}

/**/
.mb-curve-hdr {
  background: linear-gradient(to bottom,
      #18bdfd 0%,
      #46c6f7 42%,
      #76d1f1 82%,
      #78d1f1 84%);
  position: absolute;
  z-index: 0;
  width: 150%;
  height: 125vh;
  left: 50%;
  top: 0;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  border: none;
  margin-left: -75%;
}

.header-benefits-mobile {
  background: none;
}

.hd-cntr-bl {
  height: 100vh;
}

/* Raju CSS */

@media (max-width: 768px) {
  .header-benefits .desktop-hide {
    display: none !important;
  }

  .header-benefits {
    height: 70vh !important;
    visibility: hidden;
    display: none;
  }

  .header-benefits-mobile {
    position: relative;
    height: auto;
    padding: 0px;
  }

  .hd-cntr-bl {
    height: auto;
  }

  .benefits-mobile-ui {
    opacity: 1 !important;
  }

  html {
    overflow-x: hidden;
  }

  .header-benefits-mobile .hd-cntr-bl {
    // background: linear-gradient(
    //   to bottom,
    //   #18bdfd 0%,
    //   #46c6f7 42%,
    //   #76d1f1 82%,
    //   #78d1f1 84%
    // );
    border-bottom-left-radius: 25%;
    border-bottom-right-radius: 25%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .benefits_mobile_content {
    padding: 10px 10px;
    // z-index: 1;
    position: relative;
  }

  .background-curve {
    background: linear-gradient(180deg,
        #18bdfd 0,
        #46c6f7 42%,
        #76d1f1 82%,
        #78d1f1 84%);
    position: absolute;
    z-index: -1;
    width: 150%;
    height: 80vh;
    left: 50%;
    top: 0;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    border: none;
    margin-left: -75%;
  }

  .benefits--details .benefits--details--image {
    width: 100%;
    text-align: center;
    padding-top: 25px;
  }
}

@media (max-width: 375px) {
  .header-benefits-mobile h1 {
    font-size: 32px;
  }
}