@mixin block-display {
  text-align: center;
  width: 100%;
  display: block;
}

@mixin bottom-arrow {
  content: "";
  position: absolute;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid $color-primary;
  bottom: 30px;
  left: 47%;
  animation: topBottom 4s infinite;
}

// HOME HEADING ANIMATIONS

@mixin headingSkew {
  -moz-transform: translate(-38px, -18%) skew(13deg, -12deg);
  -webkit-transform: translate(-38px, -18%) skew(13deg, -12deg);
  -o-transform: translate(-38px, -18%) skew(13deg, -12deg);
  -ms-transform: translate(-38px, -18%) skew(13deg, -12deg);
  transform: translate(-38px, -18%) skew(13deg, -12deg);
}

@mixin HeadingAniAtZero {
  opacity: 0;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  transform: rotate(-8deg);
}

@mixin HeadingAniAtFifty {
  opacity: 1;
  -webkit-transform: translate3d(0, -130%, 0);
  transform: translate3d(0, -130%, 0);
  transform: rotate(-8deg);
}

@mixin HeadingAniHundred {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  transform: rotate(-5deg);
}

// changes
