.makeiteasy-page .small-title span {
  margin-right: 25px;
}
.makeiteasy-page .small-title img {
  width: 45px;
  height: 45px;
}
.makeiteasy-page .main-block {
  padding: 50px 0;
  -webkit-box-shadow: 0px 20px 50px 10px rgba(9, 36, 157, 0.1);
  -moz-box-shadow: 0px 20px 50px 10px rgba(9, 36, 157, 0.1);
  box-shadow: 0px 20px 50px 10px rgba(9, 36, 157, 0.1);
  border-radius: 10px;
  margin-left: 40px;
  margin-bottom: 50px;
  margin-right: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.makeiteasy-page .left-block {
  position: relative;
  left: -40px;
  padding-left: 0;
}
.makeiteasy-page .pic {
  border-radius: 15px;
  width: 100%;
  height: 100%;
}
.makeiteasy-page .right-block {
  padding-right: 50px;
}
.makeiteasy-page .main-block:nth-child(even) {
  margin-right: 50px;
  margin-left: 0;
}
.makeiteasy-page .main-block:nth-child(even) .left-block {
  order: 2;
  left: inherit;
  right: -40px;
  padding-right: 0;

  padding-left: 15px;
}
.makeiteasy-page .main-block:nth-child(even) .right-block {
  padding-right: 15px;
  padding-left: 50px;
}
