@font-face {
  font-family: "FontAwesome";
  src: url("fonts/fontawesome-webfont.eot?v=4.6.2");
  src: url("fonts/fontawesome-webfont.eot?#iefix&v=4.6.2")
      format("embedded-opentype"),
    url("fonts/fontawesome-webfont.woff2?v=4.6.2") format("woff2"),
    url("fonts/fontawesome-webfont.woff?v=4.6.2") format("woff"),
    url("fonts/fontawesome-webfont.ttf?v=4.6.2") format("truetype"),
    url("fonts/fontawesome-webfont.svg?v=4.6.2#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Job Details Style Start Here */
.job-details-block .inner-content .job-info .grey-block p {
  font-size: 14px;
  color: #000;
  padding: 0;
  line-height: 22px;
}

.job-details-block .inner-content .job-info .grey-block em {
  color: #605f64;
}

.job-details-block .inner-content .job-info li {
  vertical-align: top;
  margin: 0;
  display: inline-block;
}

.job-details-block .inner-content .detail-info li:first-child {
  width: 80%;
}

.job-details-block .block-box {
  padding: 2px;
  height: auto;
  margin-bottom: 20px;
}

.job-details-block .block-box .inner-text {
  padding: 30px 30px 0;
}

.job-details-block .block-box .inner-text h3 {
  padding-bottom: 20px;
  display: inline-block;
}

.job-details-block .block-box .inner-text p {
  padding-bottom: 10px;
}

.job-details-block .block-box .date-sec .date,
.job-details-block .block-box .date-sec .duration {
  width: 50%;
  display: inline-block;
}

.job-details-block .block-box .inner-text .city {
  display: inline-block;
  color: #21ace3;
}

.job-details-block .block-box .inner-text .btn-section .button {
  margin: 20px 0;
}

.job-details-block .block-box .job-post-info {
  border-top: 1px solid #cccfde;
  border-bottom: 1px solid #cccfde;
  padding: 10px 0;
  margin: 0 0 20px;
}

.job-details-block .inner-content p,
.job-details-block .inner-content li,
.job-details-block .inner-content span {
  color: #7c7c7c;
  font-size: 16px;
  font-family: "Figtree Regular" !important;
}

.job-details-block .block-box .job-post-info span {
  width: 340px;
  display: inline-block;
}

.job-details-block .block-box .job-post-info span:last-child {
  width: auto;
}

.job-details-block .inner-content em {
  font-style: normal;
  color: #333333;
}

.job-details-block .job-post-info span em {
  color: #25408f;
}

.job-post-info .time {
  display: inline-block;
  padding-bottom: 0 !important;
}

.job-details-block .forward-link,
.job-details-block .date {
  margin: 0;
}

.job-details-block .job-info .job-status li {
  display: inline-block;
  width: 33%;
  padding: 0 0 10px;
}

.job-details-block {
  padding: 0 0 100px;
}

.benefits-blk .sub-list li {
  display: inline-block;
  width: 50%;
}

.benefits-blk .sub-list li a {
  color: #25408f;
}

.benefits-blk .sub-list li a:hover {
  color: #21ace3;
}

.benefits-blk .team-block {
  padding: 20px 30px;
  background: #e8eaf6;
  text-align: center;
}

.benefits-blk .team-block .col-4 {
  padding-right: 10px;
  padding-left: 10px;
}

.benefits-blk .team-block .block-box {
  margin: 0;
}

.job-id {
  padding: 0 0 5px;
}

.job-id em {
  color: #21ace3 !important;
}

.job-details-block .job-post-info .time::before {
  top: -8px;
}

.job-details-block .city {
  padding-bottom: 10px;
}

.job-details-block .city::before {
  top: -2px;
}

.comparison-blk .indices-list li {
  width: 33%;
  display: inline-block;
  color: #606060 !important;
  vertical-align: top;
  padding: 0 10px 0 0;
}

.indices-list li h6 {
  color: #505050 !important;
  font-family: "MontserratRegular";
}

.job-details-block .comparison-blk .inner-text {
  padding: 30px 30px;
}

.comparison-blk .indices-list .memphis-list a {
  color: #25408f;
}

.comparison-blk .indices-list .nashville-list span {
  color: #54985f;
}

.comparison-blk .indices-list .nashville-list a {
  color: #21ace3;
}

.comparison-blk .filter-fielld .select-box {
  width: 80px !important;
  color: #25408f;
  padding: 5px 10px 5px 15px;
  font-size: 14px;
  height: 30px;
  background-position: 85% 8px;
  display: inline-block;
}

.comparison-blk .filter-fielld label {
  color: #000;
  padding: 0 10px 0 0;
  margin: 0;
  font-family: "MontserratRegular";
  font-size: 14px;
}

.job-details-block .properties-blk.block-box .inner-text {
  padding: 30px 30px;
}

.job-details-block .properties-blk .content .slick-arrow,
.job-details-block .properties-blk .content .slick-dots li button:before {
  display: none !important;
}

.job-details-block .properties-blk .content .slick-dots li button {
  background: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #25408f;
  border: 2px solid #25408f !important;
}

.job-details-block .properties-blk .content .slick-dots li.slick-active button {
  background: #2aafe4;
}

.job-details-block .main-content .col-3.right-block {
  padding-top: 40px;
}

.event-sec .facebook-blk {
  height: 350px;
}

.event-sec .facebook-blk iframe {
  width: 100%;
  height: 100%;
}

.weather-sec {
  background: #4b8ab5;
  text-align: center;
  border-radius: 10px;
  padding: 10px 0 0;
  overflow: hidden;
  margin-bottom: 20px;
}

.weather-sec .weather-city {
  color: #fff;
  line-height: 24px !important;
  padding: 0 0 5px;
}

.weather-sec .weather-city span {
  display: block;
  color: #fff !important;
  font-size: 14px;
}

.weather-sec .sessions,
.weather-sec .weather-degrees {
  width: 50%;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.job-details-block .weather-sec .weather-degrees {
  color: #fff;
  font-size: 22px;
  line-height: normal;
}

.job-details-block .weather-sec .weather-degrees em {
  display: block;
  font-size: 12px;
  color: #fff;
}

.weather-sec .day-list .weather-degrees {
  font-size: 14px;
}

.weather-sec .day-list h6 {
  font-size: 14px !important;
  color: #fff !important;
  padding: 0;
}

.weather-sec .day-list .row {
  margin: 0;
  padding: 10px 0;
}

.weather-sec .day-list {
  margin: 15px 0 0;
}

.weather-sec .day-list .list-points {
  padding: 0 5px;
  text-align: left;
}

.searchjobs-block .latestjobs .inner-content {
  margin: 30px 0 0;
}

.weather-sec .day-list .weather-degrees {
  font-size: 14px;
}

.weather-sec .day-list h6 {
  font-size: 14px !important;
  color: #fff !important;
  padding: 0;
}

.benefits-blk .team-block .button {
  margin: 30px 0 0;
}

.benefits-blk .team-block .slick-prev {
  left: -35px;
}

.benefits-blk .team-block .slick-next {
  right: -35px;
}

.benefits-blk .team-block .inner-content {
  margin: 0;
}

.job-details-block .benefit-list.inner-text {
  padding: 30px 30px 20px;
}

.job-details-block .team-block .social-icons a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.benefits-blk .team-block .social-icons a:before {
  font-size: 16px;
  line-height: 30px;
}

.benefits-blk .team-block .social-icons a.linkedin:before,
.benefits-blk .team-block .social-icons a.instagram:before,
.benefits-blk .team-block .social-icons a.comment:before {
  line-height: 28px;
}

.benefits-blk .team-block .button {
  margin: 30px 0 0;
}

.benefits-blk .team-block .slick-prev {
  left: -35px;
}

.benefits-blk .team-block .slick-next {
  right: -35px;
}

.benefits-blk .team-block .inner-content {
  margin: 0;
}

.job-details-block .benefit-list.inner-text {
  padding: 30px 30px 20px;
}

.searchjobs-block .pagination-blk {
  float: right;
}

.job-details-block .team-block .card .card-title {
  min-height: 52px;
}

.job-details-block .benefit-list.inner-text table {
  width: 100% !important;
}

.certification {
  position: relative;
  padding: 0 10px 0 30px !important;
}

.certification:before {
  content: "";
  position: absolute;
  left: 0;
  top: -4px;
  background: url("../images/certifications-icon.png") no-repeat 0 0;
  width: 30px;
  height: 30px;
}

.job-info.block-box .btn-section .button {
  width: auto;
  font-size: 16px;
}

.properties-blk.block-box .button {
  padding: 0 30px 0 30px;
}

.properties-blk.block-box .button::after,
.properties .main-content .back-btn::after {
  display: none;
}

.properties-blk.block-box .btn-blk {
  padding: 15px 0 0;
  text-align: center;
}

.properties-blk.block-box .btn-blk .read-more {
  margin: 10px 0 0 20px;
  display: inline-block;
}

.properties-blk.block-box .button {
  background-color: #007bff;
}

.properties .main-content .back-btn {
  margin: 0;
}

.custom-pagi.disabled {
  background-color: transparent !important;
}

.break-me.page-link {
  background-color: transparent !important;
}

.break-me.page-link {
  width: 25px !important;
}

.break-me.page-link a:hover,
.break-me.page-link a {
  color: #7c7c7c;
  width: 25px !important;
}

.disabledcustom-pagi a {
  background-color: #e9ecef !important;
}

.infobox {
  position: relative;
  background: #7875eb;
  padding: 40px;
  border-radius: 3px;
  /* width: 350px; */
  color: white;
  clip-path: circle(30px at 92% 17%);
  transition: background 0.5s ease, clip-path 0.5s ease-in-out;
}

.infobox__icon {
  position: absolute;
  top: 19px;
  right: 25px;
  fill: white;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.infobox__contents {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0s;
}

.infobox__title {
  font-size: 24px;
  margin-bottom: 10px;
}

.infobox__text {
  line-height: 1.3;
  margin-bottom: 10px;
}

.infobox__link {
  color: white;
}

.infobox:hover {
  clip-path: circle(100% at 50% 50%);
  background-color: #ffffff !important;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
}

.infobox:hover .infobox__contents {
  opacity: 1;
  transition-delay: 0.3s;
}

.infobox:hover .infobox__icon {
  opacity: 0;
}

.forward-link1.btn-light:hover {
  background-color: #ffffff !important;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
}

.search-wrapper {
  display: flex;
  padding: 0.5rem;
  border-radius: 64px;
  box-shadow: 0 0 120px 40px rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.85);
  transition: all ease-out 0.8s;
}

.search-wrapper:hover {
  box-shadow: 0 0 12px 3px rgba(255, 255, 255, 0.4);
}

.search-wrapper .input {
  width: 0;
  height: 0;
  padding: 0;
  border: 0;
  background-color: #ffffff !important;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
  opacity: 0;
  outline: none;
  background: #fff;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  transition: all linear 0.2s;
}

.search-wrapper button.social_share {
  width: 50px;
  height: 42px;
  appearance: none;
  border-radius: 50%;
  border: none;
  outline: none;
  /* background: linear-gradient(to top, #09203f 0%, #537895 100%); */
  background: #fff;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
  cursor: pointer;
  pointer-events: none;
}

.search-wrapper:hover .input {
  width: 280px;
  height: 64px;
  padding: 0.3rem 1rem;
  opacity: 1;
}

.search-wrapper:hover button.social_share {
  border-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  pointer-events: all;
}

/* .search-wrapper button.social_share::after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  margin: auto;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z'/%3E%3C/svg%3E")
    no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
} */

.search-wrapper button.social_share::after {
  content: "\f1e0";
  display: block;

  margin: auto;
  color: #000;
  font-family: "FontAwesome";

  pointer-events: none;
}

.search-wrapper button.social_share:hover::after {
  transform: rotate(90deg);
}

.search-wrapper button.social_share:hover {
  background-image: linear-gradient(to right, #21ade4 0%, #25408f 100%);
}

/* .checking {
  position: absolute;
    height: 177px !important;
    bottom: 2px;
    width: 45px!important;
    right: -1px;
    top: 37px;
    background: red !important;
} */

/* .share-rotate {
  transform: rotate(-90deg);
  transform-origin: 89% 41%;
  z-index: 999;
}

.share-rotate .rotate-icon {
  transform: rotate(90deg);
} */

/* Jhani */

.button-share {
  background: #fff;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
  color: white;
  text-decoration: none;
  border-radius: 60px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 29px;
  /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.button-share:hover {
  max-width: 300px;
}

.icon-share {
  font-size: 16px;
  margin-right: 15px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
}

.icon-share::before {
  content: "\f1e0";
  display: block;

  margin: auto;
  color: #21ade4;
  font-family: "FontAwesome";

  pointer-events: none;
  right: 8px;
  /* position: absolute;
  z-index: 999; */
}

.button-share .text {
  white-space: nowrap;
  padding-right: 15px;
}

@media only screen and (max-width: 600px) {
}
