.team-detail-blk .designation {
  font-size: 20px;
  padding: 0 0 30px;
}
.team-detail-blk .designation::before {
  display: none;
}
.team-detail-blk .phone,
.team-detail-blk .mail {
  font-size: 22px;
  display: inline-block;
  padding: 0 50px 40px 30px;
  color: #25408f;
}
.team-detail-blk .phone:hover,
.team-detail-blk .mail:hover {
  color: #21ace3;
}
.team-detail-blk .phone:before {
  font-size: 24px;
}
.team-detail-blk .mail:before {
  font-size: 22px;
  top: -2px;
}
.team-detail-blk .inner-block .left-block .pic {
  border-radius: 10px;
  height: 450px;
  -webkit-box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  -moz-box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  margin: 0;
}
.team-detail-blk .inner-block .inner-content {
  padding: 50px 0 70px;
  margin: 0;
}
.team-detail-blk .inner-block .social-icons {
  -webkit-box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  -moz-box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  padding: 20px 40px;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.team-detail-blk .inner-block .right-block {
  padding-left: 35px;
}
.team-detail-blk .breadcrumb {
  padding: 20px 0 !important;
  border-bottom: 1px solid #d6e2f0;
  margin: 0;
}

.team-detail-blk .video-block {
  width: 100%;
  height: 400px;
  border: 2px solid #b7c3cf;
  margin-top: 30px;
}
.team-detail-blk .video-block > div,
.team-detail-blk .video-block iframe {
  width: 100% !important;
  height: 100% !important;
}
