.fadeInDown-header {
  // -webkit-animation-name: fadeInDown-header;
  // animation-name: fadeInDown-header;
  // -webkit-animation-duration: 1s;
  // animation-duration: 1s;
  // -webkit-animation-fill-mode: backwards;
  // animation-fill-mode: backwards;
  // animation-delay: 6s;

  //transform: translateY(-2rem);

  // overflow: hidden;
  // width: 100%;
}

.header {
  &:hover {
    opacity: 1 !important;
  }

  // .nav .nav-item a {
  //   color: $color-primary;
  // }

  // .nav .nav-item a:hover,
  // .nav .nav-item a.active {
  //   color: $color-secondary;
  // }

  .header .button {
    margin: 0 0 0 10px;
    padding: 10px 25px;
    display: inline;
    line-height: 26px;
  }

  .login-btn {
    background: #21ace3;
    border: 2px solid #21ace3;
    color: #fff;
    padding: 6px 30px !important;

    &:hover {
      -webkit-mask-image: linear-gradient(-75deg,
          rgba(0, 0, 0, 0.6) 30%,
          #000 50%,
          rgba(0, 0, 0, 0.6) 70%);
      -webkit-mask-size: 200%;
      animation: shine 2s infinite;
    }
  }

  @media screen and (max-width: 1024px) {

    //opacity: 1 !important;
    .logo img {
      width: 80%;
    }
  }

  @media screen and (max-width: 991px) {
    //opacity: 1 !important;
  }

  @media screen and (max-width: 767px) {
    height: 56px !important;
    line-height: 56px;

    .menu-icon {
      // margin: 2px 0;
      line-height: 25px;
    }

    .sticky-header.sticky .menu-icon {
      height: 80px !important;
      margin: 2px 0 !important;
    }
  }

  @media screen and (min-width: 992px) {
    .right-block {
      width: auto !important;
    }

    .nav .nav-item a {
      color: $color-primary;

      &:hover {
        -webkit-mask-image: linear-gradient(-75deg,
            rgba(0, 0, 0, 0.6) 30%,
            #000 50%,
            rgba(0, 0, 0, 0.6) 70%);
        -webkit-mask-size: 200%;
        animation: shine 2s infinite;
      }
    }

    .nav .nav-item a:hover,
    .nav .nav-item a.active {
      color: $color-secondary;
    }
  }
}

// .menu-open .menu-block {
//   opacity: 1 !important;
// }

.sticky-header.sticky .menu-icon {
  // margin: 2px 0 !important;
}

.TN-header-ui {
  // background-image: linear-gradient(
  //     to right bottom,
  //     rgba(170, 147, 147, 0.192),
  //     rgba(170, 147, 147, 0.192)
  //   ),
  //   url("../../img/TN-header_layer_1.jpg");
  background-image: url("../../img/TN-header_layer_1.jpg");

  // background: linear-gradient(
  //   to right bottom,
  //   rgba(170, 147, 147, 0.192),
  //   rgba(170, 147, 147, 0.192)
  // );

  background-size: cover;
  background-position: top;
  height: 103vh;
  width: 100%;
  background-position: 45% 50%;
  //background-attachment: fixed;
  z-index: -2;
  overflow: hidden;
  margin-top: -100px;
  display: inline-block !important;

  // animation: moveInBottom 2s ease-in;
  //animation-delay: 6s;
  transform: translate(0);
  //animation-delay: 3s;

  &_fade_img {
    position: absolute;
    bottom: 2px;
    width: 100%;
    left: -168px;

    img {
      transform: scale(1.1);
    }
  }

  &_text {
    position: absolute;
    width: 50%;
    top: 55%;
    left: 74%;
    transform: translate(-50%, -50%);
    z-index: -1;

    h1 {
      text-align: left;
      font-size: 70px;
      // transform: rotate(-8deg);
      transform: rotate(-5deg);
      transition: 300ms ease all;
      z-index: 999;
      backface-visibility: hidden;
      // text-shadow: 4px 6px 2px $color-primary;
      text-shadow: 3px 2px 2px #25408e;

      opacity: 0;
      position: absolute;

      // Local and staging issue for top
      // top: -22%;
      top: -8%;

      left: -1%;
      // animation: fadeInTitle 4s backwards;
      //animation: fadeInTitle 4s cubic-bezier(0, 0.9, 0.76, 0) forwards;
      // animation-delay: 4s;
    }

    // chnages

    p {
      font-family: "Gotham book" !important;
      // font-size: 18px;
      font-size: 22px;
      line-height: 30px;
      padding-top: 60px;
      padding-left: 40px;
      // text-shadow: 1px 1px 1px #000, 3px 3px 5px #0c0c0c;
      // text-shadow: 3px 3px 3px #000, 5px 5px 7px #0c0c0c;
      text-shadow: 1px 1px 1px #25408f, 0px 1px 0px #25408f;
      // font-weight: 600;
    }

    .button {
      margin-left: 40px;
      // display: flex;
      // width: fit-content;
    }
  }

  &_front_img {
    background-image: url("../../img/TN-header_layer_2.png");
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: 45% 50%;

    right: 0px;
    bottom: 0px;
    animation: moveInBottom 2s ease-in;
    transform: translate(0);
    z-index: 2;

    //animation-delay: 3s;
  }

  .fade-out {
    height: 100vh;
    transform: scale(1.2);
    bottom: 0;
    display: flex;
    animation: fadeOut ease-out 4s;
    -webkit-animation: fadeOut ease-out 4s;
    -moz-animation: fadeOut ease-out 4s;
    -o-animation: fadeOut ease-out 4s;
    -ms-animation: fadeOut ease-out 4s;
    //animation-delay: 2s;
    // visibility: hidden;
    opacity: 0;
  }

  .text-delay {
    // animation: moveInBottom 0.75s ease-in-out 8s both !important;
    // -webkit-animation: moveInBottom 0.75s ease-in-out 8s both !important;
    // animation: moveInBottom 0.75s ease-in-out 8s backwards !important;
    // -webkit-animation: moveInBottom 0.75s ease-in-out 8s backwards !important;
  }

  //@media screen and (max-width: 1920px) {
  @media screen and (min-width: 1545px) {
    &_text {
      top: 50%;
      left: 75%;

      p {
        font-size: 30px;
        line-height: 45px;
        padding-top: 260px;
      }
    }

    h1 {
      font-size: 90px;
      top: 21%;
      // animation: fadeInTitleLarge 2s backwards;
      // animation-delay: 4s;
    }
  }

  // @media screen and (max-width: 1024px) {
  @media screen and (max-width: 1233px) {
    &_text {
      left: 74%;
      top: 53%;

      p {
        padding-top: 95px;
        padding-left: 24px;
      }

      .button {
        margin-left: 27px;
      }
    }

    h1 {
      font-size: 55px;
      top: 6%;
      // animation: fadeInTitleMidDefault 2s backwards;
      // animation-delay: 4s;
    }
  }

  @media screen and (max-width: 991px) {
    &_text {
      p {
        font-size: 16px;
      }
    }

    h1 {
      font-size: 50px;
      top: 10%;
      // animation: fadeInTitleTab 2s backwards;
      // animation-delay: 4s;
    }
  }

  @media screen and (max-width: 855px) {
    &_text {
      p {
        font-size: 14px;
        line-height: 24px;
      }
    }

    h1 {
      font-size: 40px;
      top: 14%;
      //animation: fadeInTitleMidDefault 1.5s backwards;
      // animation: fadeInTitleTab 2s backwards;
      // animation-delay: 4s;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 990px) {
    background-position: 25% 50%;
  }
}

.TN-header-ui-mobile {
  display: none;

  // @media screen and (max-width: 600px) {
  @media screen and (max-width: 767px) {
    display: block;
    height: 83vh;
    margin-top: -100px;
    // background-image: linear-gradient(
    //     to right bottom,
    //     rgba(238, 238, 238, 0.192),
    //     rgba(68, 68, 68, 0.144)
    //   ),
    //   url("../../img/TN-header_layer_1.jpg");
    background-image: url("../../img/TN-header_layer_1.jpg");

    background-size: cover;
    background-position: 20% 50%;
    z-index: 2;
    width: 100%;
    transition: all ease 500ms;
    // animation: mobile-header-1 2s ease;

    position: relative;
    // overflow: hidden;

    // Added code start

    // &_front_img {
    //   background-image: url("../../img/TN-header_layer_2.png");
    //   position: absolute;
    //   background-size: cover;
    //   width: 100%;
    //   height: 100%;
    //   background-position: 45% 50%;

    //   right: 0px;
    //   bottom: 0px;
    //   animation: mobile-header-1 2s ease-in;

    //   transform: translate(0);
    //   z-index: 2;
    // }

    // Added code ends

    &_text {
      position: absolute;
      top: 46%;
      left: 60%;
      transform: translate(-50%, -50%);
      z-index: -1;

      h1 {
        text-align: center;
        font-size: 45px;
        transform: rotate(-8deg);
        transition: 300ms ease all;
        z-index: 999;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        text-shadow: 4px -4px 2px #25408f;
        line-height: 45px;
        opacity: 0;
        transition: all ease 500ms;
        // animation: moveBackFroentMobile 4s cubic-bezier(0.95, -0.01, 0.16, 0.58);
        // animation: moveBackFroentMobile 2s backwards;
        // animation-delay: 3s;
      }
    }
  }

  @media screen and (max-width: 510px) {
    background-position: 25% 50%;
  }
}

/*
######################################
 HEADER PART - 2
 #####################################
*/

.TN-header-ui_2 {
  // position: relative;
  // background-image: linear-gradient(
  //     to right bottom,
  //     rgba(179, 162, 162, 0.122),
  //     rgba(179, 162, 162, 0.122)
  //   ),
  //   url("../../img/TN_header_bg.png");
  background-image: url("../../img/TN_header_bg.jpg");
  background-size: cover;
  height: 103vh;
  width: 100%;
  background-position: center;
  z-index: -2;
  overflow: hidden;
  margin-top: -100px;
  display: inline-block !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
  outline: 1px solid transparent;
  animation: moveInHeader2 8s ease-in-out;

  &_text {
    position: absolute;
    width: 90%;
    top: 35%;
    left: 6%;

    h1 {
      text-align: left;
      // font-size: 68px;
      font-size: 75px;
      transform: rotate(-5deg);
      transition: 300ms ease all;
      z-index: 999;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      // text-shadow: 4px 6px 2px $color-primary;
      // text-shadow: 0px 0px 0 rgb(36, 63, 142), 1px 1px 0 rgb(37, 64, 143),
      //   2px 2px 0 rgb(37, 64, 143), 3px 3px 0 rgb(37, 64, 143),
      //   4px 4px 0 rgb(37, 64, 143), 5px 5px 0 rgb(37, 64, 143),
      //   6px 6px 0 rgb(37, 64, 143), 7px 7px 0 rgb(37, 64, 143),
      //   8px 8px 7px rgb(37, 64, 143), 8px 8px 1px rgb(37, 64, 143),
      //   0px 0px 7px rgb(37, 64, 143);
      text-shadow: 5px 4px 1px #25408f;
      position: absolute;
      top: -25%;
      left: -1%;
      //animation: fadeInTitle-2 5s backwards cubic-bezier(0.8, 0.21, 0.15, 1);
      // animation: fadeInTitle-2 5s backwards;
      // animation-delay: 4s;
    }

    p {
      // font-size: 18px;
      font-family: "Gotham book" !important;
      padding-top: 83px;
      //text-shadow: 4px 6px 2px #000;
      // text-shadow: 0px 0px 0 rgb(37, 64, 143), -1px 0px 0 rgb(37, 64, 143),
      //   -2px 0px 0 rgb(37, 64, 143), -3px 0px 0 rgb(37, 64, 143),
      //   -4px 0px 0 rgb(37, 64, 143), -5px 0px 0 rgb(37, 64, 143),
      //   -6px 0px 0 rgb(37, 64, 143), -7px 0px 0 rgb(37, 64, 143),
      //   -8px 0px 7px rgb(37, 64, 143), -8px 0px 1px rgb(37, 64, 143),
      //   0px 0px 7px rgb(37, 64, 143);

      font-size: 22px;
      line-height: 30px;
      font-weight: 600;

      // text-shadow: 0 0 0 #313338, -1px 0 0 #000000, -2px 0 5px #000000,
      //   -3px 0 0 #0a0a0a, -4px 0 0 #000000, -5px 0 0 #020202, -6px 0 0 #000000,
      //   -7px 0 0 #0a0a0a, -8px 0 7px #040404, -8px 0 1px #121212,
      //   0 0 7px #000000;

      // text-shadow: 0 0 3px #25408f, 0 0 5px #25408f, 0 0 5px #243f8e,
      //   0 0 19px #25408f, 20px 12px 0px rgb(0 53 205 / 0%);
      text-shadow: 0 0 3px rgb(37, 64, 143), 0 0 5px rgb(37, 64, 143),
        0 0 5px rgb(36, 63, 142), 0 0 19px rgb(37, 64, 143),
        20px 12px 0px #0035cd00;

      //text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(0, 0, 0);
    }
  }

  .text-delay {
    animation: fadeInLeft 1s ease-in-out 6s both;
    -webkit-animation: fadeInLeft 1s ease-in-out 6s both;
  }

  @media screen and (max-width: 1326px) {
    h1 {
      font-size: 60px;
    }

    &_text p {
      padding-top: 60px;
    }
  }

  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 50px;
    }

    &_text p {
      padding-top: 50px;
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 40px;
    }

    &_text p {
      padding-top: 40px;
    }
  }
}

.TN-header-ui-mobile_2 {
  display: none;

  //@media screen and (max-width: 600px) {
  @media screen and (max-width: 767px) {
    display: block;
    height: 105vh;
    margin-top: -100px;
    // background-image: linear-gradient(
    //     to right bottom,
    //     rgba(238, 238, 238, 0.192),
    //     rgba(68, 68, 68, 0.144)
    //   ),
    //   url("../../img/TN_header_bg.png");
    background-image: url("../../img/TN_header_bg.jpg");
    background-size: cover;
    background-position: bottom;
    z-index: 2;
    width: 100%;
    transition: all ease 500ms;
    // animation: mobile-header-2 2s ease-in;
    animation: mobile-header-2 0.8s ease;

    position: relative;

    // &::before {
    //   position: absolute;
    //   content: "";
    //   // width: 42%;
    //   width: 35.1%;
    //   height: 100%;
    //   background-image: linear-gradient(
    //       to right bottom,
    //       rgba(238, 238, 238, 0.192),
    //       rgba(68, 68, 68, 0.144)
    //     ),
    //     url("../../img/TN_header_bg2-ani.jpg");
    //   // background: url("../../img/TN_header_bg2-ani.jpg");
    //   overflow: hidden;
    //   background-size: cover;
    //   background-position: bottom;
    //   animation: moveBackFroentMobile2Before 5.5s ease-in-out;
    //   opacity: 0;
    //   //transition: all ease 500ms;
    //   -webkit-transition: opacity 0.6s;

    //   transition: opacity 0.6s;
    //   box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
    //   outline: 1px solid transparent;
    // }

    &_text {
      position: absolute;

      top: 34%;

      left: 60%;

      // left: 49%;
      transform: translate(-49%, -49%);
      z-index: -1;
      overflow: hidden;
      transition: all ease 500ms;

      h1 {
        text-align: center;
        font-size: 55px;
        transform: rotate(-5deg);
        transition: 300ms ease all;
        z-index: 999;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        // text-shadow: 5px -1px 2px #25408f;
        text-shadow: 3px 0px 1px #25408f;
        line-height: 55px;
        // opacity: 0;
        //transition: all ease 500ms;
        // animation: moveBackFroentMobile2 4s
        //   cubic-bezier(0.95, -0.01, 0.16, 0.58);
        // animation-delay: 2s;
        // animation: moveBackFroentMobile2 2.5s ease-in-out 2s backwards;
        // -webkit-animation: moveBackFroentMobile2 2.5s ease-in-out 2s backwards;
        // transition: all ease 500ms;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header-home {
    display: none !important;
  }

  .home-about__rating {
    color: $color-primary-light !important;
  }
}

.home-mobile {
  display: none;

  // @media screen and (max-width: 600px) {
  @media screen and (max-width: 767px) {
    background-color: $color-primary;
    display: block;
    // animation: scaleBack all 100s ease-in-out;

    position: absolute;
    //bottom: -140px;
    bottom: -50px;
    z-index: 99;

    // -webkit-animation-name: fadeInUp-mobileText;
    // animation-name: fadeInUp-mobileText;
    // -webkit-animation-duration: 1s;
    // animation-duration: 1s;
    // -webkit-animation-fill-mode: both;
    // animation-fill-mode: both;
    // animation-delay: 6s;

    .button {
      background-color: $color-secondary !important;
      // display: block;
      margin: 0 auto;
      // width: 64%;
      font-size: 18px;
    }

    p {
      font-size: 19px;
      line-height: 27px;
    }
  }

  @media screen and (max-width: 767px) {
    & .button {
      padding: 0 60px 0 30px;
    }
  }
}

.home-about {
  overflow: hidden;

  &__rating {
    svg {
      color: $color-primary;
      width: 2em;
      height: 2em;
    }

    span {
      color: $color-gray-dark;
      font-size: 17px;
    }
  }

  .slick-slider,
  .slick-list,
  .slick-track {
    height: auto !important;
  }

  &__quote p {
    font-family: "Gotham book" !important;
    font-style: italic;
    font-weight: bold;
    font-style: 18px;
    color: $color-primary-light;
    font-weight: normal;
  }

  cite {
    font-style: normal;
    font-size: 20px;
    // font-weight: 500;
    color: $color-primary-light;
  }

  &__logos img {
    -webkit-filter: grayscale(0);
    filter: none;
    transition: all ease 500ms;

    &:hover {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);

      transform: scale(1.02);
    }
  }

  @media screen and (min-width: 991px) {
    &__text {
      padding-left: 60px !important;
    }
  }

  @media screen and (max-width: 767px) {
    span {
      padding-top: 0px !important;
    }

    margin-top: 185px;

    &__rating {
      svg {
        color: $color-gray-dark;
        width: 1.5em;
        height: 1.5em;
      }
    }

    &.container {
      padding-top: 0px !important;
    }

    &__rating,
    h2,
    .col-md-6 {
      text-align: center !important;
      justify-content: center;
    }
  }
}

.act-btn {
  position: absolute;
  top: 64%;
  left: 7%;
}

//

.section--home__gallery {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;

    figure {
      img {
        object-fit: cover;

        &:focus-visible {
          outline: none !important;
        }
      }

      &:focus-visible {
        outline: none !important;
      }
    }

    .slick-initialized .slick-slide {
      padding: 0px !important;
    }
  }
}

.section--home__blog {
  overflow: hidden;

  // padding-bottom: 50px;
  .card .col {
    background-color: $color-secondary-light;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px !important;
    padding-right: 5px;

    .card-title {
      font-size: 20px;
      color: $color-primary-light;
    }
  }

  a {
    font-family: "Gotham book";
    color: #707070;
    font-size: 16px;
  }

  .slick-slide img {
    object-fit: cover;
  }

  .slick-slider {
    overflow: visible !important;
  }

  // @media screen and (max-width: 600px) {
  //   a {
  //     color: $color-primary-light;
  //     font-size: 14px;
  //     font-style: italic;
  //   }
  //   .align-items-stretch.mx-1 {
  //     margin-right: 0px !important;
  //     margin-left: 0px !important;
  //     padding-left: 0px !important;
  //     padding-right: 0px !important;
  //   }
  // }

  .testimonials {
    p {
      font-size: 20px;
      font-style: italic;
      font-family: inherit !important;
      font-weight: normal;
      color: #7e8183;
      line-height: 28px;
    }

    cite {
      font-size: 18px;
      // font-weight: 600;
      color: #707070;
      font-style: normal;
    }

    .card-block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    // .slick-slide {
    //   margin: 0 5px;
    // }
  }
}

.section--home__marquee {
  background-color: $color-secondary-light;

  p p {
    padding-bottom: 0px;
    font-family: "Domine";
    color: $color-secondary;
    font-size: 18px;
  }
}

.section--home__benefits {
  p {
    line-height: 20px;
  }

  a.effect-shine p:hover {
    -webkit-mask-image: linear-gradient(-75deg,
        rgba(0, 0, 0, 0.6) 30%,
        #000 50%,
        rgba(0, 0, 0, 0.6) 70%);
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
  }

  figure img {
    transition: all ease 300ms;
  }

  figure img:hover {
    -webkit-transform: scaleY(1.02);
    -moz-transform: scaleY(1.02);
    transform: scaleY(1.02);
    cursor: pointer;
    height: 100%;
    display: block;
  }
}

.mobile--order {
  .section--callout {
    display: none;
    background-color: $color-primary;

    .button {
      background-color: $color-secondary;
      font-size: 18px;
    }
  }

  // @media screen and (max-width: 600px) {
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;

    .job-board-mobile,
    .job-board {
      order: 2;
    }

    // .section--home__gallery {
    //   order: 2;
    // }
    .section--home__benefits {
      order: 3;

      & .slick-slider {
        margin: auto 40px;
        // padding-bottom: 25px;
      }

      & .slick-dots li {
        margin: 0px !important;
      }
    }

    .section--home__blog {
      order: 1;
    }

    .section--callout {
      order: 4;
      display: block;

      @media screen and (max-width: 767px) {
        & .button {
          padding: 0 60px 0 30px;
        }
      }
    }
  }
}

.mobile-display {
  display: none !important;

  //@media screen and (max-width: 600px) {
  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

.mobile-hide {
  display: block;

  //@media screen and (max-width: 600px) {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.job-board-mobile {
  h5 {
    font-size: 24px !important;
  }

  h6 {
    font-size: 18px !important;
  }

  .slick-slide {
    height: 0px !important;
  }

  .slick-slide.slick-active {
    height: 100% !important;
  }
}

.job-board {
  overflow: hidden;

  &_label {
    color: $color-secondary;
  }

  .slick-initialized .slick-slide {
    padding: 0px;
  }

  .slick-slide {
    height: 0px !important;
  }

  .slick-slide.slick-active {
    height: 100% !important;
  }

  span.animated-content:before {
    position: absolute;
    content: "";
    //display: block;
    width: 150px;
    height: 25px;
    //background: red;
    border-radius: 50px;
    left: -52px;
    top: 100px;
    animation: home-job-board 25.5s infinite cubic-bezier(0.74, 0.33, 0.83, 0.67);
    animation-delay: 4s, 480ms;
  }

  @media screen and (max-width: 767px) {
    .heading-secondary {
      text-align: center !important;
      font-size: 24px !important;
      padding-bottom: 0px !important;
    }
  }
}

.job-board figure .animated-content_auto {
  position: absolute;
  width: auto;
  min-width: 8em;
  height: 1.5em;
  border: 1px;

  border-radius: 50px;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.4);
  transform: translate(-1em, -1em);
  background-color: #fff;
  animation: move 34s ease infinite;
}

.job-board figure .animated-content_auto::before {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  content: "0";
  padding: 3px 5px;
  color: #fff;
  animation: count 34s ease infinite;
  /*     animation-play-state: paused; */
}

.homeshortcut-nav {
  position: fixed;
  right: 25px;
  top: 50%;
  z-index: 9999999999;
  animation: fadeIn 0.75s ease-in-out 8s both;
  -webkit-animation: fadeIn 0.75s ease-in-out 8s both;

  &__links {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    cursor: default;

    .active {
      a {
        -webkit-transform: scale3d(1.3, 1.3, 1.3);
        transform: scale3d(1.3, 1.3, 1.3);

        &:after {
          height: 100%;
        }
      }
    }
  }

  li {
    position: relative;
    display: block;
    margin: 0 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 10px;
    }

    a {
      padding-top: 20px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;
      border-radius: 50%;
      text-indent: -999em;
      cursor: pointer;
      position: absolute;
      overflow: hidden;
      background-color: transparent;
      box-shadow: inset 0 0 0 2px #007bff;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 0 1px #fff;
        -webkit-transition: height 0.3s ease;
        transition: height 0.3s ease;
      }
    }
  }
}

.bottom-margin {
  margin-bottom: 80px !important;

  @media screen and (max-width: 767px) {
    margin-bottom: 60px !important;
  }
}

figure.gf-slide {
  picture {
    @media screen and (max-width: 767px) {
      text-align: center;
      display: inline-block;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;

      @media screen and (max-width: 767px) {
        width: auto;
      }
    }
  }
}

.applynow-on {
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
    position: fixed;
    // height: 100%;

    // position: absolute;
    // right: 119px;
    right: 3.9%;
    z-index: 9999;
    top: 19px;

    a {
      background: #21ace3;
      border: 2px solid #21ace3;
      color: #fff;
      padding: 6px 32px !important;

      &:hover {
        -webkit-mask-image: linear-gradient(-75deg,
            rgba(0, 0, 0, 0.6) 30%,
            #000 50%,
            rgba(0, 0, 0, 0.6) 70%);
        -webkit-mask-size: 200%;
        animation: shine 2s infinite;
      }
    }

    .button {
      height: 40px;
      line-height: 28px;

      &::after {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .specialHome {
    padding-right: 125px;
  }
}

.btn-text:link {
  @media screen and (max-width: 767px) {
    border-bottom: 0px;
  }
}

.desktop-hide {
  display: none !important;

  @media screen and (max-width: 767px) {
    display: block !important;
  }
}