*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// html {
//   // this defines what one rem is...
//   font-size: 62.5%;
// }

body {
  box-sizing: border-box;
}
