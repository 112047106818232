@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  50% {
    //opacity: 0.5;
    transform: translateY(0.5rem);
  }

  100% {
    //opacity: 1;
    transform: translate(0);
  }
}

@keyframes topBottom {
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
}

@keyframes scaleBack {
  0% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes moveInDeepBottom {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
    //transition: transform 500ms linear;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.5, 1.5);
  }
  50% {
    //transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes expand {
  0% {
    height: 0px;
  }
  100% {
    height: 100vh;
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

// @keyframes mobile-header-1 {
//   0% {
//     transform: scale(1);
//     background-position: initial;
//   }
//   50% {
//     background-position: 35% 50%;
//   }
//   100% {
//     transform: scale(1);
//   }
// }

@keyframes mobile-header-1 {
  0% {
    //transform: scale(1.5);
    // background-position: 16% 53%;

    //transform: scale(3.2) translate(128px, -100px);
    transform: scale(4) translate(35%, -24%);
    // -webkit-transform: scale(2) translate(350px, -350px);
    // -o-transform: scale(2) translate(350px, -350px);
    // -moz-transform: scale(2) translate(350px, -350px);

    overflow: hidden;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes mobile-header-3 {
  0% {
    height: 108vh;
    // height: 105vh;
  }

  100% {
    height: 80vh;
  }
}

@keyframes mobile-header-2 {
  // 0% {
  //   transform: scale(4rem);
  // }

  0% {
    transform: translateY(4rem);
    transform: scale(0.5rem);
  }

  50% {
    //transform: translateY(6rem);
    transform: scale(1.2);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes grow {
  0% {
    transform: translateY(4rem);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(-5deg);
  }
}

// @keyframes home-job-board {
//   0% {
//     // left: 0px;
//     // top: 20px;
//     background: red;
//     content: "suresh Anna";
//   }

//   25% {
//     //transform: translateX(500px);
//     left: 400px;
//     top: 100px;
//     background: yellow;
//     content: "Jhani";
//   }

//   50% {
//     top: 250px;
//     left: 400px;
//     background: green;
//     content: "Naresh";
//   }

//   75% {
//     left: 0px;
//     top: 375px;
//     background: violet;
//     content: "Abhi";
//   }

//   100% {
//     left: 250px;
//     top: 500px;
//     background: darkblue;
//     content: "CEO";
//     //opacity: 0;
//   }
// }

@keyframes move {
  26.39% {
    transform: translate(23em, 6em);
  }
  33.33% {
    transform: translate(23em, 6em);
  }

  59.72% {
    transform: translate(1em, 12em);
  }
  66.67% {
    transform: translate(1em, 12em);
  }

  93.06% {
    transform: translate(12em, 20em);
  }
  100% {
    transform: translate(12em, 20em);
  }
}

@keyframes count {
  0% {
    content: "START YOUR SEARCH.";
    background-color: red;
    border-radius: 50px;
  }
  3% {
    content: " ";
    background-color: #fff;
  }

  33.33% {
    content: "GET ALL OF THE DETAILS.";
    background-color: red;
    border-radius: 50px;
  }

  34% {
    content: " ";
    background-color: #fff;
  }
  67.66% {
    content: "LEARN ABOUT YOUR DESTINATION.";
    background-color: yellowgreen;
    border-radius: 50px;
  }
  75% {
    content: " ";
    background-color: #fff;
  }
  99.99% {
    content: "ADVENTURE AWAITS.";
    background-color: blueviolet;
    border-radius: 50px;
  }
}

@keyframes job-board-animate-1 {
  0% {
    color: #21ade4;
  }

  25%,
  50%,
  75%,
  100% {
    color: #25408f;
  }
}

@keyframes job-board-animate-2 {
  0% {
    color: #d8127f;
  }

  25%,
  50%,
  75%,
  100% {
    color: #25408f;
  }
}

@keyframes job-board-animate-3 {
  0% {
    color: #e6c232;
  }

  25%,
  50%,
  75%,
  100% {
    color: #25408f;
  }
}

@keyframes job-board-animate-4 {
  0% {
    color: #9fc64f;
  }

  25%,
  50%,
  75%,
  100% {
    color: #25408f;
  }
}

.job-board-animate-1 {
  -webkit-animation: job-board-animate-1 6s infinite alternate ease-in-out;
  -moz-animation: job-board-animate-1 6s infinite alternate ease-in-out;
  -o-animation: job-board-animate-1 6s infinite alternate ease-in-out;
  animation-delay: 1s;
}

.job-board-animate-2 {
  -webkit-animation: job-board-animate-2 6s infinite alternate ease-in-out;
  -moz-animation: job-board-animate-2 6s infinite alternate ease-in-out;
  -o-animation: job-board-animate-2 6s infinite alternate ease-in-out;
  animation-delay: 3s;
}

.job-board-animate-3 {
  -webkit-animation: job-board-animate-3 6s infinite alternate ease-in-out;
  -moz-animation: job-board-animate-3 6s infinite alternate ease-in-out;
  -o-animation: job-board-animate-3 6s infinite alternate ease-in-out;
  animation-delay: 5s;
}

.job-board-animate-4 {
  -webkit-animation: job-board-animate-4 6s infinite alternate ease-in-out;
  -moz-animation: job-board-animate-4 6s infinite alternate ease-in-out;
  -o-animation: job-board-animate-4 6s infinite alternate ease-in-out;
  animation-delay: 7s;
}

@keyframes moveBackFroent {
  // 0% {
  //   transform: rotate(-10deg) translate(-20%, -100%);
  // }

  // 20% {
  //   transform: rotate(-10deg) translate(-20%, -200%);
  // }
  // 50% {
  //   transform: rotate(-10deg) translate(-20%, -200%);
  // }

  // RAJU

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    font-size: 60px;
    transform: rotate(-8deg);
    top: 26%;
    right: 20%;
  }
  25% {
    opacity: 1;
    -webkit-transform: translate3d(0, -150%, 0);
    transform: translate3d(0, -150%, 0);
    font-size: 70px;
    transform: rotate(-8deg);
    top: 18%;
    right: 10%;
  }
  40% {
    opacity: 1;
    -webkit-transform: translate3d(0, -150%, 0);
    transform: translate3d(0, -150%, 0);
    font-size: 70px;
    transform: rotate(-8deg);
    top: 18%;
    right: 10%;
  }
  50% {
    opacity: 1;
    -webkit-transform: translate3d(0, -130%, 0);
    transform: translate3d(0, -130%, 0);
    font-size: 70px;
    transform: rotate(-8deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    font-size: 64px;
    transform: rotate(-8deg);
    top: 34%;
    right: 6%;
  }
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
    transform: scaleX(5);
  }

  100% {
    opacity: 0.5;
    transform: scaleY(5);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate(-28rem, -4rem);
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveBackFroentMobile {
  0% {
    // transform: rotate(-10deg) translate(-1%, 200%);
    transform: rotate(-10deg) translate(0, -20%);
    opacity: 0;
  }

  50% {
    transform: rotate(-10deg) translate(-1%, -100%);
    opacity: 1;
    font-size: 60px;
    line-height: 60px;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInDown-header {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    overflow: hidden;
  }
}

@keyframes fadeInUp-mobileText {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes fadeInTitleLarge {
  0% {
    @include HeadingAniAtZero;
    font-size: 75px;
    top: 15%;
    left: -18%;
    @include headingSkew;
  }
  50% {
    @include HeadingAniAtFifty;
    font-size: 100px;
    top: -16%;
    left: -18%;
  }
  100% {
    @include HeadingAniHundred;
    font-size: 90px;
    top: 21%;
    left: -1%;
  }
}

@keyframes fadeInTitleMidLarge {
  0% {
    @include HeadingAniAtZero;
    font-size: 75px;
    transform: rotate(-8deg);
    top: -45%;
    left: -18%;
    @include headingSkew;
  }
  50% {
    @include HeadingAniAtFifty;
    font-size: 80px;
    top: -55%;
    left: -18%;
  }
  100% {
    @include HeadingAniHundred;
    font-size: 70px;
    top: -22%;
    left: -1%;
  }
}

@keyframes fadeInTitle {
  0% {
    @include HeadingAniAtZero;
    font-size: 35px;
    top: -15%;
    left: -24%;
    @include headingSkew;
  }
  50% {
    @include HeadingAniAtFifty;
    // font-size: 70px;
    // top: -61%;
    // left: -18%;
    font-size: 70px;
    top: -70%;
    left: -18%;
  }
  100% {
    @include HeadingAniHundred;
    font-size: 70px;
    // top: -22%;
    top: -8%;
    left: -1%;
  }

  // 0% {
  //   @include HeadingAniAtZero;
  //   font-size: 35px;
  //   top: -20%;
  //   left: -24%;
  //   @include headingSkew;
  // }
  // 50% {
  //   @include HeadingAniAtFifty;
  //   font-size: 70px;
  //   top: -61%;
  //   left: -18%;
  // }
  // 100% {
  //   @include HeadingAniHundred;
  //   font-size: 65px;
  //   top: -22%;
  //   left: -1%;
  // }
}

@keyframes fadeInTitleMidDefault {
  0% {
    @include HeadingAniAtZero;
    font-size: 30px;
    top: 6%;
    left: -18%;
    @include headingSkew;
  }
  50% {
    @include HeadingAniAtFifty;
    font-size: 42px;
    top: -18%;
    left: -18%;
  }
  100% {
    @include HeadingAniHundred;
    font-size: 55px;
    top: 6%;
    left: -1%;
  }
}

@keyframes fadeInTitleTab {
  0% {
    @include HeadingAniAtZero;
    font-size: 35px;
    top: 6%;
    left: -18%;

    @include headingSkew;
  }
  50% {
    @include HeadingAniAtFifty;
    font-size: 40px;
    top: -18%;
    left: -18%;
  }
  100% {
    @include HeadingAniHundred;
    font-size: 50px;
    top: 10%;
    left: -1%;
  }
}

.fadeInTitle {
  -webkit-animation-name: fadeInTitle;
  animation-name: fadeInTitle;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  animation-delay: 5s;
  animation-duration: 1.5s;
  /*-webkit-animation-fill-mode: both;
animation-fill-mode: both;*/
  animation-fill-mode: backwards;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

// Header -2
@keyframes fadeInTitle-2 {
  0% {
    opacity: 0;
    top: -50%;
    left: 0%;
    transform: rotate(-5deg);
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
    top: -50%;
    left: 50%;
    transform: rotate(-5deg);
  }

  75% {
    top: -50%;
    left: -1%;
    transform: rotate(-5deg);
  }

  100% {
    top: -25%;
    left: -1%;
    transform: rotate(-5deg);
  }

  // 0% {
  //   opacity: 0;
  //   top: -50%;
  //   left: 0%;
  //   transform: rotate(-5deg);
  // }

  // 25% {
  //   opacity: 0.5;
  // }

  // 50% {
  //   opacity: 1;
  //   top: -50%;
  //   left: 54%;
  //   transform: rotate(-5deg);
  // }

  // 75% {
  //   top: -50%;
  //   left: -1%;
  //   transform: rotate(-5deg);
  // }

  // 100% {
  //   top: -25%;
  //   left: -1%;
  //   transform: rotate(-5deg);
  // }
}

@keyframes moveInHeader2 {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1.1);
    transform: translateY(-2%);
  }

  75% {
    transform: scale(1.1);
    transform: translateY(2%);
  }

  100% {
    //opacity: 1;
    //transform: translate(0);
    transform: scale(1);
  }
}

@keyframes mobile-header-2 {
  0% {
    transform: scale(1.2);
    // background-position: -41px -69px;
    // // height: 105vh;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes moveBackFroentMobile2 {
  0% {
    transform: translateX(-40%) rotate(-5deg);
    //transform: translateX(-10%) rotate(-5deg);
    text-shadow: 5px -1px 2px #25408f;
    opacity: 0;
    font-size: 25px;
    line-height: 25px;
    width: 50% !important;
  }
  //
  50% {
    //transform: translateX(40%);
    transform: translateX(15%) rotate(-5deg);
    text-shadow: 5px -1px 2px #25408f;
    opacity: 1;
    font-size: 25px;
    line-height: 25px;
    left: 50%;
  }

  100% {
    transform: translateX(0) rotate(-5deg);

    // transform: translateX(0);
    opacity: 1;
    font-size: 55px;
    line-height: 55px;
  }

  // 0% {
  //   transform: translate(-20%, 0);
  //   transform: rotate(-5deg);
  //   font-size: 35px;
  //   line-height: 35px;
  // }

  // 50% {
  //   transform: translate(40%, 0);
  //   font-size: 35px;
  //   line-height: 35px;
  // }
  // 100% {
  //   transform: translate(0, 0);
  //   transform: rotate(-5deg);
  //   opacity: 1;
  //   font-size: 55px;
  // }
}

@keyframes moveBackFroentMobile2Before {
  0%,
  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transition: opacity 0.6s;

    transition: opacity 0.6s;
  }
}
