.news-page .inner-block .text h4 {
  padding: 0 0 10px 0;
  min-height: 62px;
}

.news-page .inner-block {
  padding: 100px 0 50px;
}

.news-page .inner-block .text .date {
  padding: 0 0 10px 30px;
}

.news-page.news-block .inner-content {
  text-align: center;
}

.news-page .inner-block .block {
  margin-bottom: 30px;
  text-align: left;
}

.news-page.news-block .block-box .pic {
  height: 200px;
}

.news-page .pagination-blk {
  margin-top: 50px;
}

.blog-search {
  border-top: 1px solid #d6e2f0;
  padding: 20px 15px;
}

.categary-select {
  float: right;
}

.blog-search .categary-select select,
.blog-search .seach-box input {
  border: 2px solid #d6e2f0;
  background-color: transparent;
  width: 260px !important;
  color: #777777;
  position: relative;
}

.blog-search label {
  font-size: 16px;
  line-height: 22px;
  color: #777777;
  font-family: "MontserratRegular";
  margin-right: 25px;
}

.news-page .inner-content {
  margin: 0 0 50px;
}

.blog-search .seach-box input {
  background: url(../images/search-icon.png) no-repeat;
  background-position: 90% 16px;
  padding: 10px 45px 10px 30px;
}

.blog-search .categary-select select {
  background: url(../images/arrow-down-brown.png) no-repeat;
  background-position: 85% 18px;
}

.blog-search input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777;
}

.blog-search input::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
}

.blog-search input:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000;
}

.blog-search input:-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
}

/* News Details Block Style */
.share-blk span {
  color: #666666;
  font-size: 18px;
  line-height: 24px;
  /* font-weight: 500; */
  display: inline-block;
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.newsdetails-block .share-blk {
  margin: 20px 0 20px;
}

.newsdetails-block .inner-block {
  padding: 0 0 100px;
}

.newsdetails-block .breadcrumb {
  padding: 25px 0 !important;
  border-bottom: 1px solid #d6e2f0;
}

.newsdetails-block .inner-content {
  margin: 50px 0 0;
}

.newsdetails-block .inner-content .pic {
  margin: 0 0 40px;
  width: 100%;
}

.newsdetails-block .inner-content .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsdetails-block .inner-content h2 {
  padding: 0 0 15px;
}

.newsdetails-block .date,
.author {
  font-family: "MontserratRegular";
  color: #666666;
  font-size: 18px;
  padding: 0 60px 0 30px;
  position: relative;
  display: inline-block;
}

.author em {
  /* font-weight: 600; */
  font-style: normal;
  color: #515151;
}

.author::before {
  content: "";
  position: absolute;
  left: 0;
  top: -3px;
  background: url(../images/author-icon.png) no-repeat 0 0;
  width: 25px;
  height: 30px;
}

.newsdetails-block .event-sec .pic {
  height: 135px;
  margin: 0;
}

.related-news-blk .related-news {
  padding: 5px;
  border-bottom: 1px solid #cccfde;
  margin: 0 0 20px;
  text-align: left;
}

.related-news-blk .related-news:last-child {
  border: 0;
}

.related-news .news-text {
  padding: 20px 15px;
}

.related-news .news-text h5 {
  padding: 0 0 5px;
}

.newsdetails-block .related-news .date {
  font-family: "MontserratRegular";
  color: #666666;
  font-size: 16px;
  padding: 0 0 0 30px;
}

.newsdetails-block .related-news .date::before {
  display: block;
  top: 0;
}

.newsdetails-block .event-sec .button {
  color: #fff !important;
  margin: 10px 0 30px;
  text-align: center;
  font-size: 16px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100% !important;
}

.newsdetails-block .image-gallery-slide-wrapper {
  margin-bottom: 10px;
  box-shadow: 0px 10px 10px 0 rgba(37, 64, 143, 0.1);
  outline: none !important;
  height: 500px;
  position: relative;
}

.newsdetails-block .image-gallery-thumbnail {
  width: 24%;
  height: 135px;
  border: 3px solid #cccfde;
  border-radius: 15px;
  overflow: hidden;
  margin: 0 5px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner,
.image-gallery-thumbnail .image-gallery-thumbnail-inner img {
  width: 100%;
  height: 100%;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 3px solid #25408f !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.newsdetails-block .image-gallery-left-nav .image-gallery-svg,
.newsdetails-block .image-gallery-right-nav .image-gallery-svg {
  height: 50px;
  width: 30px;
  background: #25408f;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.image-gallery-icon:hover .image-gallery-svg {
  transform: scale(1) !important;
  background: #21ace3;
}

.image-gallery-play-button,
.image-gallery-fullscreen-button {
  display: none;
}

.image-gallery-slide {
  outline: none !important;
}

.image-gallery-icon {
  filter: none !important;
}

.most-viewed {
  padding: 15px 15px 20px;
  border-bottom: 1px solid #cccfde;
  text-align: left;
}

.newsdetails-block .mostview-blk .button {
  margin: 20px 0;
}

.newsdetails-block .most-viewed .pic {
  float: left;
  width: 100px;
  height: 75px;
  border: 3px solid #fff;
  box-shadow: 0px 10px 10px 0 rgba(37, 64, 143, 0.1);
  margin-right: 10px;
}

.most-viewed .text {
  float: left;
  width: 50%;
}

.most-viewed .text h6 {
  padding: 0;
}

.newsdetails-block .related-news-blk .head-title {
  background: #21ace3;
}

.news-block .block-box {
  box-shadow: 0px 0px 30px 10px rgba(9, 36, 157, 0.1);
  border-radius: 0 !important;
}

.news-block .block-box:hover {
  box-shadow: 0px 0px 50px 30px rgba(9, 36, 157, 0.1);
}

.newsdetails-block .image-gallery-slide-wrapper iframe,
.newsdetails-block .image-gallery-slide-wrapper .image-gallery-slide,
.newsdetails-block .image-gallery-slide-wrapper .image-gallery-swipe,
.newsdetails-block .image-gallery-slide-wrapper .image-gallery-slides,
.newsdetails-block .image-gallery-slide-wrapper .image-gallery-slides div {
  width: 100%;
  height: 100%;
}

.newsdetails-block .category {
  bottom: 185px;
}

.newsdetails-block .side-bar .button {
  width: 180px;
}

.author-blk .author-pic {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin: 0 20px 0 0;
  vertical-align: top;
  border-radius: 50%;
}

.author-blk .text {
  display: inline-block;
  width: 80%;
  font-size: 18px;
  vertical-align: top;
}

.author-blk .text .social-icons {
  display: block;
  margin-top: 20px;
}

.author-blk .text .social-icons a {
  margin: 0 10px 0 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.author-blk .text .social-icons a::before {
  line-height: 25px;
  font-size: 14px;
}

.author-blk .text .social-icons a.instagram::before,
.author-blk .text .social-icons a.linkedin::before {
  line-height: 22px;
}

.author-blk .inner-sec {
  box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  padding: 20px;
}