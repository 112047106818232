.testimonial-page .figure-sec .pic {
  height: 250px;
  width: 100%;
  border: 10px solid #ffffff;
  margin: 0;
}
.testimonial-page .figure-sec {
  text-align: center;
}
.testimonial-page .inner-content .row.testi-block {
  margin: 0 0 50px;
  background: #ffffff;
  padding: 20px 20px;
  position: relative;
}
.testimonial-page .inner-content .right-block,
.testimonial-page .inner-content .left-block {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
  vertical-align: middle;
}
.testimonial-page .inner-content h3 {
  color: #25408f;
  padding: 0;
}
.testimonial-page .figure-sec span {
  font-size: 16px;
  line-height: 22px;
  color: #4b4b4b;
}
.testimonial-page .inner-content .right-block {
  padding-left: 35px;
}
.testimonial-page .content-box p:last-child {
  padding: 0;
}
.testimonial-page .inner-content .testi-block:nth-child(even) .left-block {
  order: 2;
}

.testimonial-page .content-box .text {
  position: relative;
  padding: 0 50px;
    margin-top: 30px;
}
.testimonial-page .content-box .text::before,
.testimonial-page .content-box .text::after {
  content: "“";
  position: absolute;
  font-size: 100px;
  color: #25408f;
  font-family: "OswaldRegular";
  line-height: 0;
  top: 35px;
  left: 0;
  
}

.testimonial-page .content-box .text::after {
  bottom: 28px;
  top: inherit;
  right: 0;
  transform: rotate(180deg);
  left: inherit;
}
.align-middle {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

/* .testimonial-page .figure-sec .slick-initialized .slick-slide{padding: 0;height: 100%;position: relative;}
.testimonial-page .slick-prev,
.testimonial-page .slick-next{top:50% !important;left: 0;}

.testimonial-page .slick-next{right: 0; left: inherit;}
.testimonial-page .slick-initialized.slick-slider,
.testimonial-page .slick-initialized .slick-list,
.testimonial-page .slick-initialized .slick-track,
.testimonial-page .slick-initialized div,
.testimonial-page .slick-initialized div item,
.testimonial-page .figure-sec iframe{height: 100%;} */

.testimonial-page .figure-sec .slick-initialized .slick-slide{padding: 0;height: 100%;position: relative;}
  .testimonial-page .slick-prev,
  .testimonial-page .slick-next{top:50% !important;left: 0;}
  .testimonial-page .slick-next{right: 0; left: inherit;}
  
  .testimonial-page .slick-initialized.slick-slider,
  .testimonial-page .slick-initialized .slick-list,
  .testimonial-page .slick-initialized .slick-track,
  .testimonial-page .slick-initialized div,
  .testimonial-page .slick-initialized item,
  .testimonial-page .figure-sec iframe{height: 100%;}