@font-face {
  font-family: "Figtree Regular";
  src: url("./fonts/Figtree-Regular.ttf");
  src: url("./fonts/Figtree-Regular.ttf") format("truetype"),
}

@font-face {
  font-family: "Figtree Black";
  src: url("./fonts/Figtree-Black.ttf");
  src: url("./fonts/Figtree-Black.ttf") format("truetype"),
}

@font-face {
  font-family: "Figtree ExtraBold";
  src: url("./fonts/Figtree-ExtraBold.ttf");
  src: url("./fonts/Figtree-Regular.ttf") format("truetype");
}






body {
  font-family: "Figtree Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: $color-gray-dark;
  // padding: 3rem;
}

button:focus {
  outline: 0 !important;
}

h1,
h2,
h3,
h2 a {
  font-family: "Domine", sans-serif !important;
}

h4,
h5 {
  font-family: "Figtree ExtraBold", sans-serif !important;
}

p {
  font-family: "Figtree Regular", sans-serif !important;
}

.heading-primary {
  font-family: "thirsty_script_extrabold_deRg";
  color: $color-white;
}

.heading-secondary {
  // font-family: "Domine" !important;
  color: $color-primary;
  font-size: 35px !important;
  font-weight: bold;
  line-height: 51px !important;
  @include block-display;

  @media screen and (max-width: 767px) {
    font-size: 28px !important;
    line-height: 35px !important;
  }
}

.heading-tertiory {
  color: $color-secondary;
  font-size: 26px !important;
  @include block-display;

  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
}