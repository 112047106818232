.team-block .remove-logo .profile-tag {
  display: none;
}

.date_picker_field .react-datepicker-wrapper {
  width: 100%;
}

.registration-form .date_picker_field .form-group figure.date-icon {
  top: 15px;
  right: 22px;
}

.date_picker_grid {
  position: relative;
}

/*=== COOKIES ===*/
.team-block .card figure {
  overflow: inherit;
}

.team-block .card figure img.profile-tag {
  position: absolute;
  top: -4px;
  left: 0;
  max-width: 55px;
  height: auto;
}

.team-detail-blk .inner-block .right-block h1 img {
  height: 35px;
  position: relative;
  /* right: 12px; */
  right: 0px;
  top: -19px;
}

#popup-modal {
  position: fixed;
  z-index: 100000;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
}

.cookies-area .popup-content h2 {
  font-size: 23px !important;
  font-family: inherit;
  border-radius: 10px;
}

.cookies-area .popup-content {
  height: 480px;
  margin: 9vh auto;
}

iframe#company_join_and_refer {
  width: 100%;
  height: 1310px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.py-6 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.max-w-md {
  max-width: 28rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.cookies-area .popup-content p {
  text-align: left;
  font-size: 12px;
  line-height: inherit;
  color: #505050;
  font-weight: 300 !important;
  padding-bottom: 20px;
}

.cookies-area .popup-content h3 {
  margin: 0px 0 10px 0 !important;
  padding: 0px;
  font-size: 1.3em !important;
  font-weight: normal;
  text-align: left;
  color: inherit;
  font-family: inherit;
}

.cookies-area {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgb(37, 64, 143);
  padding: 15px 10px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  z-index: 99999999999999;
}

.cookies-area p button {
  border-bottom: solid 1px #fff !important;
  background: none;
  border: none;
  color: #fff;
  padding: 0px;
  font-size: 14px;
  line-height: normal;
  height: auto;
  padding-bottom: 4px;
  margin-left: 5px;
  transition: all 0.5s ease;
}

.cookies-area p {
  margin: 0 !important;
  display: inline-block;
  color: #fff;
  padding: 0px;
  font-size: 14px;
}

.cookies-area p a {
  border-bottom: solid 1px #fff !important;
  color: #fff;
  transition: all 0.5s ease;
}

.cookies-area p a:hover {
  color: #21ade4;
  transition: all 0.5s ease;
}

.cookies-area p button:hover {
  color: #21ade4;
  transition: all 0.5s ease;
}

.cookies-area-none {
  display: none;
}

/**/
.award-banner::before {
  width: 592px;
}

.mbl-banner-logo {
  display: none;
}

.red-text {
  font-size: 16px !important;
  color: #fff;
  line-height: normal !important;
  text-align: center;
}

h1.main-title-one {
  font-size: 34px !important;
  line-height: 42px !important;
  /* font-weight: 500; */
  padding-bottom: 10px;
  text-align: center;
}

.main-title-sub {
  font-size: 24px !important;
  text-align: center;
  color: #000;
  line-height: normal !important;
  text-align: center;
}

h2.main-title-two {
  font-size: 28px !important;
  line-height: 42px !important;
  padding-bottom: 10px;
  text-align: center;
}

h2.main-title-two-sub {
  font-size: 20px !important;
  display: inline-block;
}

h3.main-title-two {
  font-size: 22px !important;
  line-height: 42px !important;
  padding-bottom: 10px;
  text-align: center;
}

.award-banner .mbl-banner-img {
  display: none;
}

.award-banner .award-banner-left h2 {
  padding: 0;
  color: #25408f;
  font-size: 60px !important;
  font-family: "DomineBold";
  line-height: 60px !important;
  margin-bottom: 20px !important;
}

.award-banner .award-banner-left p {
  font-size: 20px;
  color: #707070;
  line-height: 30px;
  margin-bottom: 14px !important;
}

.award-banner .award-banner-left figure {
  margin: 0;
}

.award-banner {
  position: relative;
  margin-bottom: 20px;
}

.award-banner::before {
  position: absolute;
  content: "";

  background: url("https://df8de9x9ieyx9.cloudfront.net/award-banner-bg.png") no-repeat;
  background-size: cover;
  /* width: 900px; */
  height: 100%;
  right: 0;
}

.award-banner .award-banner-left figure img {
  max-width: 400px;
  height: auto;
}

.award-banner .award-banner-right figure {
  position: absolute;
  right: 0;
  top: 0;
}

.award-banner .award-banner-layer {
  padding: 50px 0;
}

.award-banner .award-banner-right {
  position: absolute;
  right: 0;
  top: 80px;
}

.award-banner .award-banner-right img {
  max-width: 800px;
  height: 100%;
  object-fit: cover;
}

.content-body h3 {
  color: #4c565c;
  /* font-weight: 500; */
  padding-bottom: 15px;
}

.content-body ul li {
  font-size: 16px;
  line-height: 23px;
  font-family: "MontserratRegular";
  color: #505050;
  padding-bottom: 9px;
  padding-left: 13px;
  position: relative;
}

.content-body ul li::before {
  position: absolute;
  content: "";
  background: #505050;
  width: 5px;
  height: 5px;
  left: 0;
  top: 10px;
  display: block;
  border-radius: 60px;
}

.nominee-form h2 {
  color: #25408f;
  font-size: 20px !important;
  /* font-weight: 500; */
  line-height: normal !important;
  padding-bottom: 15px;
  margin: 0 15px !important;
  display: block;
  width: 100%;
}

.nominee-form .form-block .row {
  padding: 0 15px;
}

.nominee-form .form-block .form-group label {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 6px;
}

.nominee-form .form-block .form-group input {
  width: 100%;
  border: solid 2px #b9c8df;
  border-radius: 60px;
  font-size: 16px;
  color: #8c8c8c !important;
  font-weight: 400 !important;
  padding: 0 20px;
  height: 44px;
  /* font-family: 'Domine', sans-serif; */
}

.nominee-form .form-block .form-group input::placeholder {
  font-size: 16px;
}

.nominee-form .form-block .form-group textarea {
  width: 100%;
  border: solid 2px #b9c8df;
  border-radius: 20px;
  font-size: 16px;
  color: #8c8c8c !important;
  font-weight: 600 !important;
  padding: 10px 20px;
  height: 125px;
}

.nominee-form .form-block .form-group textarea::placeholder {
  font-size: 16px;
  font-weight: 600 !important;
}

.nominee-form .form-block {
  margin: 0 auto;
  padding: 50px 90px;
}

.nominee-form .form-block span {
  padding: 0 15px 12px;
  /* font-weight: 600; */
  color: #4c565c;
  font-size: 16px;
}

.nominee-form .form-block .form-group.select-field-grid label {
  display: inline-block;
  width: 14%;
  float: left;
  color: #000;
  font-size: 14px;
}

.nominee-form .form-block .form-group.select-field-grid input {
  background: none;
  border: none;
  padding: 0;
  border-radius: 0;
  height: auto;
  width: 25%;
  display: inline-block !important;
}

.nominee-form .form-block .form-group.select-field-grid .form-text {
  font-style: italic;
  font-size: 12px;
}

.nominee-form .form-block .form-group select {
  width: 100% !important;
  border: solid 2px #b9c8df;
  border-radius: 60px;
  font-size: 16px;
  color: #8c8c8c !important;
  font-weight: 400 !important;
  padding: 0 20px;
  height: 44px;
  /* background-size: 19px !important;
  background-position: 88% !important; */
  background: none !important;
}

.nominee-form .form-block .form-group img {
  position: absolute;
  right: 40px;
  max-width: 20px;
  top: 43px;
  z-index: 2;
}

/* .nominee-form .form-block .form-group .arrow-sec {
  display: block;
  padding: 0;
  position: relative;
} */

.nominee-form .form-block .form-bttn-grid {
  padding: 0 15px;
  margin-top: 8px;
}

.nominee-form .form-block .form-bttn-grid .blue-bttn {
  padding: 0 60px;
}

.faq-sec {
  padding: 80px 0 30px;
}

.faq-sec h2 {
  text-align: center;
  color: #25408f;
  font-size: 35px;
  font-weight: 700;
  line-height: 51px;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Domine" !important;
  margin: 0;
  padding-bottom: 3rem !important;
}

.faq-sec #accordion {
  max-width: 80%;
  margin: 0 auto;
}

.faq-sec #accordion .card {
  margin-bottom: 15px;
}

.faq-sec #accordion .card-header {
  padding: 0;
  position: relative;
}

.faq-sec #accordion .card-header button {
  position: relative;
}

.faq-sec #accordion .card-header button {
  color: #fff !important;
  background: #21ade4 !important;
  border: 0 !important;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 50, 0.25);
  padding-right: 30px;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  /* font-weight: 500; */
  text-decoration: none;
  padding: 14px 30px 15px 20px;
}

.accordion>.card>.card-header.active-toggle::before {
  transform: rotate(180deg);
  transition: all 0.3s linear;
}

.accordion .card-header {
  color: #fff !important;
  background: #21ade4 !important;
  border: 0 !important;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 50, 0.25);
  padding-right: 30px;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 18px;
  /* font-weight: 600; */
  text-decoration: none;
  padding: 14px 30px 15px 20px;
  position: relative;
  border-radius: 5px !important;
  cursor: pointer;
}

.accordion .card-body {
  padding: 1.25rem;
  border-bottom: solid 1px #ccc;
}

.faq-sec .accordion {
  max-width: 80%;
  margin: 0 auto;
}

.accordion .card {
  margin-bottom: 15px;
}

.accordion>.card>.card-header:before {
  position: absolute;
  content: "";
  background: url("../images/accoordian-icon.png") no-repeat;
  background-size: cover;
  width: 25px;
  height: 28px;
  right: 8px;
  top: 15px;
  transition: all 0.3s linear;
}

.accordion>.card>.card-header.active-toggle::before {
  transform: rotate(180deg);
  transition: all 0.3s linear;
  height: 27px;
}

.honorees-slider .slick-slider .honorees-slider-block figure img {
  max-width: 100%;
}

.honorees-slider .slick-slider .honorees-slider-block figure {
  margin: 0;
}

.honorees-slider {
  padding-top: 40px;
}

.honorees-slider h2 {
  text-align: center;
  color: #25408f;
  font-size: 35px;
  font-weight: 700;
  line-height: 51px;
  text-align: center;
  width: 100%;
  display: block;
  font-family: "Domine" !important;
  margin: 0;
  padding-bottom: 25px !important;
}

/**/
div#left-tabs-example-tabpane-handbook {
  height: 100vh;
}

.profile-cont-grid {
  height: 100%;
}

.profile-cont-grid .handbook-pdf-grid {
  height: 100%;
}

.profile-cont-grid .handbook-pdf-grid iframe {
  height: 100%;
  width: 100%;
}

/**/
.sign-form-area .form-group .form-label {
  font-family: "Poppins";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 16px;
  line-height: 18px;
  color: #3a3c54;
}

.sign-form-area .form-group .form-control {
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.sign-form-area .sign-form-bttn {
  background: #21ade4;
  border: none;
  width: 100%;
  border-radius: 5px;
  font-size: 18px;
  /* font-weight: 600; */
  padding: 10px 0;
}

#step-sec aside h2 {
  font-size: 17px !important;
  line-height: normal !important;
  padding-bottom: 12px;
}

.document-grid .document-block .document-in aside .sky-bttn {
  display: block;
}

#step-sec .blue-bttn {
  width: 100%;
}

#step-sec h3 {
  text-align: left;
  padding: 0 15px 30px;
  font-size: 20px !important;
}

.pdf-block figure {
  text-align: center;
  margin-bottom: 40px;
}

.recruiter-modal#Tn-popup .modal-content .pdf-block h2 {
  font-style: normal;
  /* font-weight: 500; */
  font-size: 22px !important;
  line-height: 32px !important;
  text-align: center;
  color: #000000;
}

.pdf-block p {
  color: #000;
  font-size: 15px;
  line-height: 25px;
}

.pdf-form .signatureCanvas {
  min-height: 200px;
  height: 200px;
}

.pdf-form .form-control {
  border-radius: 6px;
  background: #fafafb;
  border: solid 1px #cacaca;
}

.pdf-form .form-group {
  margin-bottom: 20px;
}

.sign-bttn {
  background: #1fabe2;
  color: #fff;
  box-shadow: none;
  width: 100%;
  border: none;
  border-radius: 6px;
  height: 40px;
  font-size: 18px;
  /* font-weight: 600; */
  margin-top: 10px;
}

.recruiter-modal#Tn-popup.handbook-popup .modal-content {
  padding: 50px 40px;
}

.pdf-footer {
  margin-top: 70px;
}

.pdf-footer .pdf-footer-cont p {
  font-size: 14px;
  line-height: 21px;
}

.pdf-footer .pdf-footer-cont p span {
  font-style: italic;
  /* font-weight: 600; */
}

.pdf-footer .pdf-footer-cont {
  text-align: center;
}

.pdf-footer .row {
  padding: 40px 15px 0;
}

.pdf-footer .row p {
  font-size: 14px;
}

.pdf-form .form-group input {
  border-radius: 6px;
  background: #fafafb;
  border: solid 1px #cacaca;
  padding: 0 15px;
}

#handbook-steps-grid .blue-bttn {
  background-color: #25408f !important;
  cursor: pointer !important;
  display: inline-block !important;
  padding: 0 40px !important;
  height: 51px;
  text-align: center !important;
  line-height: 50px !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-weight: 600 !important;
  position: relative;
  overflow: hidden;
  /* font-family: "Montserrat", sans-serif; */
  background: #25408f;
  border: none !important;
}

#handbook-steps-grid .sky-bttn {
  cursor: pointer !important;
  display: inline-block !important;
  height: 51px !important;
  text-align: center !important;
  line-height: 50px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-weight: 600 !important;
  position: relative !important;
  overflow: hidden !important;
  /* font-family: "Montserrat", sans-serif !important; */
  background: #21ade4 !important;
  border: none !important;
  padding: 0 40px !important;
  height: 51px !important;
  font-size: 16px !important;
  background-color: #21ade4 !important;
  width: 100%;
}

/**/
.sign-save-popup .check-sec span {
  position: relative;
  top: -5px;
  padding-left: 30px;
  vertical-align: middle;
}

.sign-save-popup .check-sec a {
  position: relative;
  top: 0px;
  font-size: 14px;
  padding-left: 10px;
}

.sign-save-popup .check-sec label {
  cursor: pointer;
}

.login-page .form-sec {
  padding: 40px;
}

.login-page .form-sec .button:focus {
  border: none;
  box-shadow: none;
}

.login-page .form-sec .form-group span.error {
  /* font-weight: 600; */
  padding: 5px 0 0 21px;
  display: block;
}

#edit-profile-layout .profile-cont-grid form .profile-show p::before {
  display: none;
}

#edit-profile-layout .profile-cont-grid form .profile-show p.phone span::before {
  content: "\f095";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "FontAwesome";
  font-size: 20px;
  color: #21ade4;
}

#edit-profile-layout .profile-cont-grid form .profile-show p.mail span::before {
  content: "\f0e0";
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 0;
  font-family: "FontAwesome";
  color: #21ade4;
}

.form-group input::placeholder {
  color: #8c8c8c !important;
  font-weight: 300 !important;
}

.input-disable.form-group input {
  background-color: #f0f0f0 !important;
  border-color: #f0f0f0;
  border-radius: 30px;
}

.input-disable.form-group button {
  background-color: #f0f0f0 !important;
  border-color: #f0f0f0 !important;
  border-radius: 30px;
  box-shadow: none !important;
}

.profile-grid .profile-grid-left .nav-item:last-child a::before {
  display: none;
}

/*==== LOGIN-PAGE ===*/
.login-page {
  padding: 0;
}

.login-page .login-grid {
  height: 100vh;
  display: table;
  width: 100%;
}

.login-page .inner-block {
  height: inherit;
  vertical-align: middle;
  display: table-cell;
}

.login-page .inner-block .row {
  border-radius: 10px;
  box-shadow: 0px 20px 50px 10px rgb(9 36 157 / 10%);
  overflow: hidden;
  background: #fff;
  margin: 30px 0;
}

.login-page .inner-block .row .left-block {
  padding: 0;
}

.back-bttn button::after {
  display: none;
}

.preview-bttn.back-bttn {
  text-align: right;
}

.preview-bttn.back-bttn button {
  margin-bottom: 30px;
  text-align: right;
}

.back-bttn button svg {
  vertical-align: middle;
}

.back-bttn button span {
  vertical-align: middle;
  padding-left: 9px;
  line-height: normal;
}

.profile-form-popup .profile-form-block .form-group {
  margin-bottom: 25px !important;
}

/**/
.block.country-code .form-group select {
  line-height: 35px !important;
  background: url(../images/sortarrow.png) no-repeat !important;
  background-size: 19px !important;
  background-position: 88% !important;
}

.registration-form .inout-block .block_new button {
  background: url(../images/sortarrow.png) no-repeat;
  background-size: 23px;
  background-position: 95%;
  background-color: #ffff !important;
}

.form-bttn button .bttn-loader-icon {
  width: 32px !important;
  height: 32px !important;
  margin-right: -17px;
}

/*=== DELETE-ACCOUNT =====*/
.delete-profile {
  text-align: center;
  margin: 0 auto;
  padding: 40px 10px;
}

.delete-profile .delete-profile-in figure img {
  border-radius: 100%;
  max-width: 100px;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}

.delete-profile h2 {
  font-size: 28px !important;
  color: #25408f;
  padding-top: 15px;
}

.delete-profile p {
  padding-bottom: 10px;
}

.delete-profile p span {
  display: inline-block;
  position: relative;
  font-size: 19px;
  padding-bottom: 0;
}

.delete-profile p span::before {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "FontAwesome";
  font-size: 20px;
  color: #21ade4;
}

.delete-profile p span.phone::before {
  content: "\f095";
}

.delete-profile p span.mail::before {
  content: "\f0e0";
}

.delete-profile .delete-profile-in figure {
  margin: 20px 0;
}

.delete-profile .button-sec {
  margin-top: 20px;
}

.delete-profile .button-sec button {
  margin: 0 14px;
}

.login-page.delete-profile-grid .row {
  width: 60%;
  margin: 0 auto;
}

.delete-profile .delete-profile-in h3 {
  font-size: 16px !important;
  max-width: 300px;
  margin: 0 auto !important;
  padding-bottom: 10px;
  display: block;
}

.delete-profile .delete-profile-in .title {
  margin-bottom: 15px !important;
}

.login-page.delete-profile-grid .preview-bttn {
  width: 60%;
  margin: 0 auto;
  text-align: right;
  padding-bottom: 22px;
}

.login-page.delete-profile-grid .preview-bttn button::after {
  display: none;
}

.login-page.delete-profile-grid .preview-bttn button svg {
  vertical-align: middle;
  position: relative;
  top: 1px;
}

.login-page.delete-profile-grid .preview-bttn button span {
  vertical-align: middle;
  padding-left: 8px;
}

.delete-profile .button-sec .blue-bttn {
  background: #f01227;
}

.delete-profile .button-sec .blue-bttn::before {
  background: #ca1d2f;
}

.recruiter-modal#Tn-popup.edit-profile-modal .delete-profile .button-sec button.clear-bttn:hover {
  color: #000 !important;
}

/*=== 404-PAGE =====*/
.error-block {
  margin: 80px 0;
}

.notfound-block {
  margin: 40px 0;
}

.not-found-page .not-found-page-in svg {
  font-size: 90px;
  margin-bottom: 25px;
}

.not-found-page .not-found-page-in h2 {
  padding: 0 0 22px 0;
  font-size: 50px !important;
  color: #25408f;
  font-weight: 800;
}

.not-found-page .not-found-page-in p {
  font-size: 20px;
}

.not-found-page .not-found-page-in {
  box-shadow: 0px 20px 50px 10px rgb(9 36 157 / 10%);
  overflow: hidden;
  background: #fff;
  padding: 40px;
}

/*==== DATE-FORM ====*/
.data-form {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  padding: 50px 100px;
}

.main-page .form-group #comment_text {
  height: 150px;
  border-radius: 5px;
  box-shadow: none !important;
  color: #505050 !important;
  border: 2px solid #b9c8df;
  padding: 10px 20px;
  font-family: "MontserratRegular" !important;
  font-size: 16px !important;
  outline: none;
  width: 100% !important;
}

.check-box-grid .form-check .form-check-input {
  float: left;
  vertical-align: middle;
  opacity: 1;
  left: 0;
  top: 3px;
  width: 23px;
  height: 23px;
}

.check-box-grid .form-check label {
  padding: 0 0 0 20px;
  vertical-align: middle;
}

.check-box-grid .form-check {
  text-align: left;
  display: inline-block;
}

.check-box-grid .form-check label::before {
  opacity: 0 !important;
}

.check-box-grid .form-check .form-check-input {
  position: absolute;
  left: 0;
}

.check-box-grid .form-check label {
  padding-left: 33px;
  vertical-align: middle;
  font-size: 16px;
}

.check-box-grid .form-check {
  margin-bottom: 16px;
  display: block;
}

/* .list-boxes .btn-group input {
    position: absolute !important;
    left: 0 !important;
    margin-right: 20px !important;
    display: inline-block !important;
    top: -3px;
  }
  
  .main-page .list-boxes.check-box-block .form-group .btn.btn-default span {
    vertical-align: middle;
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 14px;
  } */

/*=== OTP-DESIGN ===*/
.otp-input-wrapper {
  width: 240px;
  text-align: left;
  display: inline-block;
}

.otp-input-wrapper input {
  padding: 0;
  width: 264px;
  font-size: 32px;
  /* font-weight: 600; */
  color: #3e3e3e;
  background-color: transparent;
  border: 0;
  margin-left: 12px;
  letter-spacing: 48px;
  font-family: sans-serif !important;
}

.otp-input-wrapper input:focus {
  box-shadow: none;
  outline: none;
}

.otp-input-wrapper svg {
  position: relative;
  display: block;
  width: 240px;
  height: 2px;
}

.otp-field-grid {
  padding: 0 !important;
}

.otp-field-grid .otp-input-wrapper {
  margin-bottom: 30px;
}

.otp-field-grid .button {
  display: block;
  margin-bottom: 13px !important;
}

.otp-field-grid span a {
  color: #21ade4;
  font-size: 15px;
}

.otp-field-grid .forget-psw {
  padding: 16px 0;
}

.form-block.otp-field-grid input[type="text"] {
  width: auto;
  font-size: 24px;
  letter-spacing: 53px;
}

p.helper {
  font-size: 11px;
  padding: -21px;
  position: absolute;
  top: 91px;
  right: 23px;
}

.login-grid .form-sec .title {
  font-size: 28px !important;
  line-height: 42px !important;
}

/*=== PRIVACY-POLICY ===*/
.list li {
  padding-left: 24px;
  position: relative;
  padding-bottom: 22px;
}

.list li:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 60px;
  top: 10px;
  left: 0;
}

.list li p strong {
  color: #000;
}

.policy-content h4 {
  font-size: 20px;
}

.form-progress-icon span.form-tag {
  background: #e29c2d;
  color: #fff;
  font-size: 13px;
  padding: 4px 20px;
  border-radius: 60px;
}

.policy-content em {
  font-family: "MontserratRegular";
  color: #505050;
}

/*== COMMON-STYLES ====*/
.related-job-grid {
  left: -25px;
}

.disable-field.block.country-code .form-group select {
  background: #f0f0f0 !important;
  border-color: #f0f0f0;
  border-radius: 30px;
  cursor: unset;
}

.delete-profile .delete-profile-in .title {
  font-size: 35px !important;
}

.main-page .form-group #comment_text::placeholder {
  font-weight: normal;
  color: #505050 !important;
}

.sub-label {
  font-size: 11px;
}

.preview-bttn button::after {
  display: none;
}

.preview-bttn button svg {
  margin-right: 10px;
}

#becookie_close_button {
  outline: none !important;
  box-shadow: none !important;
  top: 15px !important;
}

/**/
.handbook-top-sec .row {
  align-items: center;
}

.handbook-top-sec h3 {
  padding: 0;
}

.handbook-top-sec {
  padding-bottom: 30px;
}

.handbook-sec .customViewer {
  border: solid 2px #21ade4;
  border-radius: 15px;
}

.handbook-sec .customViewer .mgrpdf-navigation {
  border-radius: 0 0 13px 13px;
  line-height: 49px;
}

.handbook-sec .customViewer .mgrpdf-navigation h3 {
  padding: 0;
  font-size: 18px !important;
  margin: 0 20px !important;
  color: #000 !important;
}

.handbook-sec .customViewer .mgrpdf-navigation {
  border-radius: 0 0 13px 13px;
  line-height: 49px;
  background-color: #e3ebf8 !important;
}

.handbook-top-sec .edit-btn {
  text-align: center;
  line-height: 36px;
  background: #21ade4;
}

.handbook-top-sec .edit-btn:hover {
  background: #25408f;
}

.handbook-top-sec .edit-btn svg {
  width: 20px;
  height: 20px;
  color: #fff;
}

.handbook-sec .main-bttn-block {
  text-align: right;
  margin-top: 30px;
}

#handbook-pdf-grid figure {
  width: 100%;
  max-width: 100%;
  flex: 100%;
  background: none;
  border: none;
}

#handbook-pdf-grid figure button {
  background: #efefef;
  font-size: 37px;
  border: solid 1px #dddcf2;
  padding: 10px;
  border-radius: 10px;
  width: 70px;
  height: 70px;
}

#handbook-pdf-grid figure button svg {
  position: relative;
  top: -8px;
}

#handbook-pdf-grid .document-in {
  padding: 30px 20px;
  display: table;
  width: 100%;
  text-align: center;
}

#handbook-pdf-grid .document-grid .document-block .document-in .row {
  align-items: center;
  vertical-align: middle;
  display: table-cell;
}

#handbook-pdf-grid .document-grid .document-block .document-in .doc-cont span {
  padding: 0;
}

#handbook-pdf-grid .doc-cont {
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 0;
}

#handbook-pdf-grid .preview-bttn {
  text-align: right;
  margin-bottom: 20px;
}

button.handbook-bttn {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: none;
  box-shadow: none !important;
  text-shadow: none;
  background: none;
  border-radius: 8px;
}

.profile-list aside span label {
  font-size: 12px;
  margin: 0;
  display: block;
  text-align: right;
}

.handbook .form-bttn .btn-right button.blue-bttn {
  margin-right: 10px;
}

#handbook-pdf-grid .document-in figure button {
  border-radius: 70px;
  width: 60px;
  height: 60px;
  transition: all 0.5s ease;
}

#handbook-pdf-grid .document-in figure button svg {
  color: #d54127;
  font-size: 27px;
  top: -14px;
  transition: all 0.5s ease;
}

#handbook-pdf-grid .document-in:hover figure button {
  background: #21ade4;
}

#handbook-pdf-grid .document-in:hover figure button svg {
  color: #fff;
}

#handbook-pdf-grid .document-in:hover .doc-cont span {
  transition: all 0.5s ease;
  color: #21ade4;
}

/* .reviews {
  margin-left: 100px;
} */

/*==== RESPONSIVE ====*/
@media (max-width: 1700px) {
  .award-banner .award-banner-left h2 {
    font-size: 56px !important;
    line-height: 58px !important;
  }

  .award-banner .award-banner-left figure img {
    max-width: 360px;
  }

  .award-banner .award-banner-left p {
    margin-bottom: 5px !important;
  }
}

/*1599 to 1440 */
@media (max-width: 1599px) {
  .award-banner::before {
    width: 662px;
  }

  .award-banner .award-banner-left h2 {
    font-size: 50px !important;
  }

  .award-banner::before {
    width: 600px;
  }
}

/*1439 to 1360 */
@media (max-width: 1439px) {
  .award-banner .award-banner-right img {
    max-width: 740px;
  }

  .award-banner .award-banner-left h2 {
    font-size: 45px !important;
  }
}

/*1359 to 1280 */
@media (max-width: 1359px) {}

/*1279 to 1152 */
@media (max-width: 1279px) {
  .award-banner .award-banner-right img {
    max-width: 670px;
  }

  .award-banner .award-banner-left h2 {
    font-size: 40px !important;
    line-height: 54px !important;
  }

  .award-banner::before {
    width: 530px;
  }

  .award-banner .award-banner-left figure img {
    max-width: 270px;
  }

  .faq-sec {
    padding: 40px 0 30px;
  }
}

/*1151 to 1024 */
@media (max-width: 1151px) {
  .banner-inner .item {
    height: 330px;
  }

  #handbook-pdf-grid .document-grid .document-block .document-in figure svg {
    background: none;
    padding: 8px;
  }

  .award-banner .award-banner-right img {
    max-width: 480px;
  }

  .award-banner .award-banner-layer {
    padding: 50px 0;
  }

  .award-banner .award-banner-left h2 {
    font-size: 38px !important;
    line-height: 42px !important;
  }

  .award-banner .award-banner-left figure img {
    max-width: 220px;
  }

  .award-banner .award-banner-left p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 14px !important;
    margin: 0 !important;
  }

  .nominee-form .form-block {
    margin: 0 auto;
    padding: 50px 40px;
  }

  .nominee-form h2 {
    font-size: 17px !important;
  }

  .award-banner::before {
    width: 450px;
  }
}

/*1023 to 992 */
@media (max-width: 1023px) {
  .login-grid .form-sec .title {
    font-size: 27px !important;
    line-height: 40px !important;
  }

  .delete-profile .delete-profile-in .title {
    padding-bottom: 9px;
    font-size: 32px !important;
  }

  .handbook-sec .customViewer .mgrpdf-navigation h3 {
    font-size: 16px !important;
  }

  .handbook-sec .customViewer canvas {
    width: 100% !important;
    height: auto !important;
    border-radius: 13px;
  }

  .content-body h3 {
    font-size: 19px !important;
  }

  .faq-sec .accordion {
    max-width: 90%;
    margin: 0 auto;
  }
}

/*1023 to 970 */
@media (max-width: 991px) {
  .delete-profile h2 {
    font-size: 24px !important;
  }

  .award-banner .award-banner-left h2 {
    font-size: 34px !important;
  }
}

/* 992 */
@media only screen and (min-width: 992px) {
  .honorees-slider {
    padding-top: 0;
  }
}

/*969 to 900 */
@media (max-width: 969px) {
  .delete-profile .delete-profile-in .title {
    font-size: 25px !important;
  }

  #handbook-steps-grid .blue-bttn {
    padding: 0 20px !important;
  }

  #handbook-steps-grid .sky-bttn {
    padding: 0 20px !important;
  }

  #handbook-steps-grid .blue-bttn {
    padding: 0 20px !important;
  }

  #handbook-steps-grid .sky-bttn {
    padding: 0 20px !important;
  }

  .nominee-form .form-block .form-group.select-field-grid label {
    width: 20%;
  }

  .nominee-form .form-block .form-group.select-field-grid input {
    width: 32%;
  }

  .award-banner::before {
    width: 390px;
  }
}

/*899 to 800 */
@media (max-width: 899px) {
  .delete-profile {
    text-align: center;
    margin: 0 auto;
  }

  .delete-profile .delete-profile-in figure img {
    max-width: 120px;
  }

  .delete-profile p {
    padding-bottom: 10px;
    line-height: normal;
    padding-bottom: 0;
  }

  .delete-profile p span {
    font-size: 18px;
  }

  .delete-profile .delete-profile-in figure img {
    max-width: 95px;
  }

  .data-form {
    padding: 50px 40px;
  }

  .policy-content h4 {
    font-size: 17px !important;
  }

  #handbook-steps-grid aside {
    width: 100%;
    max-width: 100%;
    flex: 100%;
    margin-bottom: 30px;
  }

  #handbook-steps-grid aside:last-child {
    margin-bottom: 0;
  }

  #handbook-pdf-grid .document-grid .document-block {
    margin-bottom: 24px;
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }

  /* .award-banner .award-banner-layer .award-banner-left {
  max-width: 100%;
  width: 100%;
  flex: 100%;
  text-align: center;
} */
  .award-banner::before {
    width: 440px;
  }

  /* .award-banner .award-banner-layer {
  padding: 40px 0;
}
.award-banner .award-banner-right img {
  max-width: 350px;
}
.award-banner .award-banner-left h2 {
  font-size: 29px !important;
  line-height: 34px !important;
  margin-bottom: 9px !important;
}
.award-banner .award-banner-left p {
  font-size: 16px;
  line-height: 22px;
  margin: 0 !important;
  padding: 0 0 15px 0;
}
.award-banner .award-banner-left figure img {
  max-width: 150px;
} */
  .award-banner .award-banner-left h2 {
    font-size: 27px !important;
  }

  .award-banner .award-banner-left p {
    font-size: 16px;
  }

  .award-banner::before {
    width: 340px;
  }
}

/*799 to 768 */
@media (max-width: 799px) {
  .nominee-form .form-block .form-group input {
    font-size: 14px;
  }

  .nominee-form .form-block .form-group input::placeholder {
    font-size: 14px;
  }

  .nominee-form .form-block .form-group select {
    font-size: 14px;
  }

  .nominee-form .form-block .form-group select::placeholder {
    font-size: 14px;
  }

  .nominee-form .form-block .form-group textarea {
    font-size: 14px;
  }

  .nominee-form .form-block .form-group textarea::placeholder {
    font-size: 14px;
  }
}

/*767 to 736 */
@media (max-width: 767px) {
  .sticky-header {
    top: 60px;
    z-index: 1000;
  }

  .cookies-area {
    position: fixed;
    top: 0;
    bottom: inherit;
    padding: 10px 30px;
  }

  .head-top {
    top: 0 !important;
  }

  .cookies-area #close-banner {
    font-size: 26px !important;
    top: 2px;
    right: 5px;
  }

  .cookies-area p {
    font-size: 13px;
    line-height: 1.4;
  }

  .main-title-sub {
    padding: 20px 0;
  }

  .delete-profile h2 {
    padding: 5px 0 18px;
  }

  .login-page.delete-profile-grid .row {
    width: 90%;
    margin: 0 auto;
  }

  .delete-profile .delete-profile-in .title {
    font-size: 27px !important;
  }

  .login-page.delete-profile-grid .preview-bttn {
    width: 90%;
  }

  .content-body ul li {
    font-size: 15px;
  }

  .faq-sec .accordion .card {
    border: none;
    border-bottom-width: medium;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    border-bottom: solid 1px #000 !important;
    border-radius: 0 !important;
    margin: 0;
  }

  .faq-sec .accordion {
    max-width: 100%;
    margin: 0 auto;
  }

  .accordion .card-header {
    color: #3652a5 !important;
    background: none !important;
    border: 0 !important;
    font-size: 16px;
    padding: 10px 50px 15px 0;
    /* font-weight: 600; */
    box-shadow: none !important;
  }

  .accordion .card-body {
    padding: 5px 0;
    border-bottom: solid 1px #ccc;
  }

  .accordion .card-body p {
    padding-bottom: 10px;
  }

  .faq-sec h2 {
    text-align: left;
    padding: 0 0 5px !important;
    color: #000;
    font-size: 18px !important;
  }

  .faq-sec {
    padding: 0 0 30px;
  }

  .award-banner .award-banner-layer .award-banner-left {
    max-width: 50%;
    flex: 50%;
    width: 50%;
  }

  .award-banner::before {
    width: 390px;
  }

  .honorees-slider {
    padding-top: 0;
    margin-bottom: 40px;
  }
}

/*735 to 667 */
@media (max-width: 735px) {}

/*666 to 600 */
@media (max-width: 666px) {
  .delete-profile .button-sec button {
    margin: 0 8px;
  }

  .handbook-sec .customViewer .mgrpdf-navigation h3 {
    font-size: 14px !important;
    margin: 0 10px !important;
  }

  .nominee-form .form-block {
    padding: 50px 0px;
  }

  .nominee-form .form-block .form-group.select-field-grid label {
    width: 100%;
  }

  .nominee-form .form-block .form-group.select-field-grid input {
    width: auto;
  }

  .award-banner-left {
    background: #25408f;
    padding: 10px !important;
  }

  .award-banner .award-banner-layer {
    padding: 30px 0;
  }

  .award-banner .mbl-banner-img {
    display: block;
  }

  .award-banner .award-banner-right {
    display: none;
  }

  .award-banner .award-banner-left figure img {
    max-width: 100%;
  }

  .award-banner .award-banner-left h2 br {
    display: none;
  }

  .award-banner .award-banner-left h2 {
    font-size: 26px !important;
    line-height: 34px !important;
    margin-bottom: 7px !important;
    font-family: inherit !important;
    font-weight: 560;
    color: #fff !important;
  }

  .award-banner .award-banner-left p {
    font-size: 16px;
    line-height: 22px;
    margin: 0 !important;
    padding: 0 0 15px 0;
    color: #fff;
  }

  .cont-grid-area figure img {
    max-width: 270px !important;
  }

  .cont-grid-area {
    padding: 24px 0 !important;
  }

  .award-banner .award-banner-left p br {
    display: none;
  }

  .award-banner .award-banner-layer .award-banner-left {
    max-width: 100% !important;
    flex: 100% !important;
    width: 100% !important;
  }

  .award-banner::before {
    display: none;
  }

  .mbl-banner-logo {
    display: block;
  }

  .desk-banner-logo {
    display: none;
  }

  .award-banner {
    margin-bottom: 0;
  }

  .nominee-form h2 {
    margin: 0 !important;
  }

  .nominee-form form .form-group {
    padding: 0;
  }

  .nominee-form h2 {
    margin: 0 !important;
  }

  .nominee-form form .form-group {
    padding: 0;
  }

  .accordion>.card>.card-header:before {
    background: url("../images/arrow-down.png") no-repeat;
    background-size: cover;
    width: 13px;
    height: 10px;
    right: 14px;
    top: 16px;
    transition: all 0.3s linear;
  }

  .accordion>.card>.card-header.active-toggle:before {
    background: url("../images/arrow-down.png") no-repeat;
    background-size: cover;
    width: 13px;
    height: 10px;
    right: 14px;
    top: 16px;
    transform: rotate(180deg);
    transition: all 0.3s linear;
  }

  .award-banner .award-banner-left p {
    padding: 0 10px;
    color: #fff !important;
  }

  .award-banner .award-banner-left .my-3 {
    margin-top: 0 !important;
  }
}

/*599 to 568 */
@media (max-width: 599px) {
  .delete-profile h2 {
    font-size: 22px !important;
  }

  .delete-profile .button-sec button {
    margin: 0 6px;
  }

  .data-form {
    padding: 50px 20px;
  }

  .login-page.delete-profile-grid .row {
    width: 100%;
    margin: 0 auto;
  }

  .login-page.delete-profile-grid .preview-bttn {
    width: 100%;
  }

  .form-progress-icon span.form-tag {
    font-size: 11px;
    margin: 0 0 0 0;
    padding: 4px 13px;
  }

  .recruiter-modal#Tn-popup.handbook-popup .modal-content {
    padding: 50px 30px;
  }

  .pdf-footer .row {
    padding: 10px 15px 0;
    text-align: center;
  }

  .pdf-footer .row p {
    display: block;
    width: 100%;
  }
}

/*567 to 480  */
@media (max-width: 567px) {
  .delete-profile .button-sec button {
    margin: 0 10px;
  }

  .delete-profile {
    padding: 30px 10px;
  }

  form.pdf-form .row {
    padding: 0 15px;
  }

  .handbook .form-bttn {
    margin-bottom: 20px;
  }

  .handbook-sub-btn .col-12 {
    padding: 0;
  }

  .handbook .pdf-block figure img {
    max-width: 100%;
  }

  .cookies-area .popup-content {
    margin: 9vh auto;
    width: 90%;
    padding: 20px !important;
  }
}

/*479 to 414  */
@media (max-width: 479px) {
  .form-block.otp-field-grid input[type="text"] {
    width: auto;
    font-size: 19px;
    letter-spacing: 44px;
    margin-left: 29px;
  }

  .otp-input-wrapper svg {
    width: 200px;
    margin: 0 auto;
  }

  .cont-grid-area {
    padding: 20px 10px !important;
  }

  .award-banner .award-banner-left h2 {
    font-size: 21px !important;
  }
}

/*413 to 375*/
@media (max-width: 413px) {
  .cookies-area {
    padding: 7px 20px;
  }

  .cookies-area p {
    font-size: 12px;
  }

  .delete-profile .button-sec button {
    padding: 0 25px !important;
    margin: 0 5px !important;
  }

  #handbook-steps-grid h3 {
    padding: 10px 0 20px;
  }

  .document-grid .document-block .document-in .row {
    padding: 0;
  }

  .handbook .form-bttn .btn-right button.blue-bttn {
    margin-right: 5px;
  }

  .cont-grid-area figure img {
    max-width: 270px !important;
  }

  .accordion .card-header {
    padding: 10px 40px 15px 0;
  }

  .honorees-slider .slick-slider .honorees-slider-block figure img {
    max-width: 100%;
    width: 100%;
    height: 280px;
    object-fit: cover;
    object-position: 0 -40px;
  }
}

.document-grid .document-block .document-in .row {
  padding: 0;
}

.handbook .form-bttn .btn-right button.blue-bttn {
  margin-right: 5px;
}

#cookies-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgb(37, 64, 143);
  padding: 10px;
  display: none;
  height: 60px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  font-family: inherit;
}

#cookies-banner p {
  margin: 0;
  display: inline-block;
  color: #fff;
  padding: 0px;
  font-size: 14px;
  font-family: inherit;
}

#cookies-banner p a {
  color: #fff;
  text-decoration: underline;
}

#cookies-banner button {
  margin-left: 10px;
  color: #fff;
  /* font-weight: 600; */
  border: none;
  background: transparent;
  border-bottom: 1px solid #fff;
  font-size: 14px;
  font-family: inherit;
}

/* Styles for the popup modal */
.popup-modal {
  /*position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  justify-content: center;
  align-items: center*/
  display: block;
  position: fixed;
  z-index: 100000;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  display: none;
}

.popup-content {
  position: relative;
  background-color: #eee;
  color: #808080;
  margin: 13vh auto;
  padding: 0;
  border: none;
  width: 550px;
  border-radius: 10px;
  font-size: 12px;
  padding: 25px 40px 25px;
  text-align: left;
  line-height: 18px;
  background-color: #fff;
  overflow: auto;
}

.popup-content h2 {
  display: flex;
  height: auto;
  margin: 0;
  padding: 0px;
  justify-content: center;
  padding-top: 23px;
  padding-bottom: 23px;
  background: #fff;
  color: #000;
  /* font-weight: 500; */
  font-size: 23px;
  font-family: inherit;
  border-radius: 10px;
}

.popup-content h3 {
  margin: 20px 0 3px 0;
  padding: 0px;
  font-size: 1.3em;
  font-weight: normal;
  text-align: left;
  color: inherit;
  font-family: inherit;
}

.popup-content p {
  text-align: left;
  font-size: 12px;
  line-height: inherit;
  margin: 0;
}

#close-button {
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 29px;
  font-weight: 100;
  font-family: none;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

#ok-button {
  font-size: 13px;
  color: #fff;
  padding: 11px 55px;
  margin: 0;
  cursor: pointer;
  text-align: center;
  background: #21ace3;
  border: none;
  line-height: 12px;
  /* font-weight: 600; */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 140px;
  margin: 0 auto;
}

.popup-content h2 {
  margin-top: 0;
}

#close-banner {
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  font-size: 30px !important;
  text-decoration: none;
  border: none !important;
  font-weight: normal !important;
  line-height: 0;
}

/*374 to 320*/
@media (max-width: 374px) {
  .delete-profile p span {
    font-size: 15px;
  }

  .delete-profile h2 {
    font-size: 22px !important;
  }

  div#handbook-pdf-grid {
    margin-left: -15px;
    margin-right: -15px;
  }

  .cont-grid-area figure img {
    max-width: 260px !important;
  }

  .faq-sec h2 {
    font-size: 16px !important;
  }

  .cookies-area p {
    font-size: 12px;
    line-height: 15px;
  }
}

#close-banner {
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  font-size: 30px !important;
  text-decoration: none;
  border: none !important;
  font-weight: normal !important;
  line-height: 0;
  background-color: transparent;
  color: #fff;
}

/* REFER FRIEND STYLES GOES HERE */
.refer-friend h1 {
  font-size: 55px;
}

@media (max-width: 991px) {
  .refer-friend h1 {
    font-size: 35px;
  }
}

.form-refer-friend {
  position: relative;
  background: linear-gradient(0deg,
      rgba(37, 64, 143, 1) 0%,
      rgba(42, 194, 254, 1) 100%);
}

.form-refer-friend .wrapper:before {
  content: "";
  position: absolute;
  width: 90%;
  height: 100%;
  right: 110px;
  bottom: 15px;
  background: url("https://df8de9x9ieyx9.cloudfront.net/bannerImages/refer-friend_form.png");
  background-size: 12% auto;
  background-repeat: no-repeat;
  background-position: right bottom;
}

@media (max-width: 1440px) {
  .form-refer-friend .wrapper:before {
    right: 75px;
  }
}

@media (max-width: 1370px) {
  .form-refer-friend .wrapper:before {
    right: 0px;
  }
}

.reviews {
  width: 100% !important;
}

@media (max-width: 375px) {
  .reviews {
    padding: 15px !important;
  }
}

@media (max-width: 767px) {
  .form-refer-friend .wrapper:before {
    display: none;
  }

  .reviews {
    width: 100% !important;
  }
}

/*=== STYLES MODIFIED 09/20/2023 ===*/
@media (max-width: 1279px) {
  .job-details-block.job-details-ui .related-jobs .slick-list .slick-slide div {
    position: initial;
  }
}

.loading-spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #004a99;
  background-color: #f5f5f5;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: loadingSpin 2s linear infinite;
}

@keyframes loadingSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .video-container {
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 991px) {
  .video-container {
    height: 350px;
  }
}
@media (max-width: 539px) {
  .video-container {
    height: 270px;
  }
}

@media (max-width: 538px) {
  .video-container {
    height: 200px;
  }
}

.youtube-video {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 100%;
  width: 100%;
}

.youtube-video.show {
  opacity: 1;
} */

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.classic-button-type_1 {
  background-color: #21ace3 !important;
  color: #fff !important;
  text-decoration: none !important;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  padding: 0 25px !important;
  /* font-weight: 600; */
  font-size: 16px;
  position: relative;
  overflow: hidden;
  border: 0 !important;
  border-radius: 60px !important;
  box-shadow: #004a99 6px 6px 26px;
}

.classic-button-type_1:focus {
  outline: 2px solid transparent !important;
}

.classic-button-type_1:hover {
  -webkit-mask-image: linear-gradient(-75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%);
  -webkit-mask-size: 200%;
  -webkit-animation: shine 2s infinite;
  animation: shine 2s infinite;
}

.expandable-html p {
  padding-bottom: 0;
}

.reviews-btn {
  padding: 1em !important;
}

.reviews-btn::before {
  background-color: #609c00 !important;
  border-radius: 0px !important;
}

.button.refer-btn {
  font-size: 18px;
  line-height: 50px;
  font-family: "Figtree Black";
  background: #25408f;
  border-radius: 30px;
  color: #fff;
  padding: 0 60px 0 30px;
  height: 50px;
  position: relative;
  display: inline-block;
  outline: none;
  z-index: 1;
  overflow: hidden;
  border: 0;
}

.button.refer-btn:hover {
  background: #25408f;
}

.reviews .slick-slide {
  height: 0px !important;
}

.reviews .slick-slide.slick-active {
  height: 100% !important;
}

@media (max-width: 560px) {
  .reviews-btn {
    width: 100% !important;
  }
}

.relevent-jobs-pay h4 {
  font-family: Figtree Black !important;
}

/* 

@media (max-width: 767px) {
  .notice.custom-alert {
    position: relative;
    margin-top: 62px;
  }



}

@media (max-width: 572px) {
  .notice.custom-alert p {
    margin-left: 10px !important;
  }

  .notice.custom-alert img {
    position: absolute;
    left: 19px;
    top: 29px;
    width: 25px;
    height: 25px;
  }
} */

/* Desktop version (default) */
.desktop-banner {
  display: block;
}

.mobile-banner {
  display: none;
}

/* Mobile version: Show icon & limited text */
@media (max-width: 768px) {
  .desktop-banner {
    display: none;
  }

  .mobile-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }

  .mobile-banner img {
    margin-right: 5px;
  }

  .mobile-banner a {
    color: #0056b3 !important;
    text-decoration: none;
    font-weight: bold;
  }
}

@media (max-width: 1199px) {
  .job-info.block-box.tab-job-info-box ul {
    display: block !important;
  }
}

@media (max-width: 1029px) {
  .profile-block.latestjobs .inner-content .job-info {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  footer .footer-inner .block.contact .content-area {
    max-width: 100% !important;
  }

  footer .footer-inner .block.contact .content-area .links {
    max-width: 35%;
    margin: 0 auto;
  }

  footer .footer-inner .block.contact .content-area {
    padding: 20px 0;
  }


}

footer .footer-inner {
  padding-bottom: 0px !important;
}

footer .footer-inner .hcs-rated-img {
  position: relative;
  right: 15px;
  margin-bottom: 7px;
}

@media (max-width: 767px) {
  footer .footer-inner .hcs-rated-img {
    position: relative;
    right: 0px;
    margin-bottom: 7px;
  }

  .footer-inner .contact-block figure.pic {
    display: flex !important;
    justify-content: center;
  }

  .footer-inner .contact-block figure.pic img {
    width: 200px !important;
  }
}

@media (max-width: 381px) {
  footer .footer-inner .hcs-rated-img.mobile .hcs-img {
    margin-right: 5px !important;
  }
}

@media (max-width: 668px) {
  .registr-info .apply-job-btns .btn {
    width: 100% !important;
    margin: 5px 0;
  }
}

@media (max-width: 668px) {
  .check-sec {
    flex-direction: column;
  }

  .check-field.pl-3 {
    margin-left: 15px !important;
  }
}