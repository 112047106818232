.about-block .inner-content {
  margin: 50px 0 0;
}

.about-block .inner-block a {
  color: #25408f;
}
.about-block .inner-block a:hover {
  color: #21ace3;
}
.about-block .inner-block .descrop {
  padding: 0;
}

.about-block .info-box {
  border-radius: 5px;
  background: #21ace3;
  margin: 0 10px;
  display: inline-block;
  height: 100%;
  position: relative;
}
.about-block .info-box .pic {
  width: 100%;
  height: 100%;
}
.about-block .info-box .text {
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 9;
}
.about-block .info-box .text h6 {
  color: #fff;
  padding: 0 10px;
}
.about-block .info-box .text p {
  color: #000;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
}
.about-block .about-travel {
  padding: 50px 0;
  background: #f2fdf6;
}

.about-block .about-travel .block {
  height: 250px;
  vertical-align: top;
}
.about-block .about-travel .text-block {
  padding-right: 10px;
}
.about-block .about-travel .text-block p {
  font-size: 14px;
  line-height: 22px;
}
.about-block .info-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  z-index: 9;
}
.about-block .slick-prev,
.about-block .slick-next {
  top: 25%;
  bottom: 10px;
  background: #25408f;
  width: 30px;
  height: 30px;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  left: -5px;
}
.about-block .slick-next {
  left: inherit;
  right: -5px;
}
.about-block .slick-prev:hover,
.about-block .slick-next:hover {
  background: #21ace3;
}
.about-block .slick-prev::before {
  content: "\f104";
  font-family: "FontAwesome";
}
.about-block .slick-next::before {
  content: "\f105";
  font-family: "FontAwesome";
}
.about-block .about-travel .slick-initialized .slick-slide {
  padding: 0;
}
.about-block .slick-arrow {
  top: 50%;
}
.awards-certifications .pic {
  display: block;
  width: 100%;
  height: 180px;
  box-shadow: 0px 5px 10px 0px rgba(9, 36, 157, 0.1);
}
.awards-certifications .inner-content {
  margin-top: 50px;
}
.about-block .special-blk {
  padding: 50px 20px;
  background: #21ace3;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}
.about-block .special-blk p {
  padding: 20px 0 0;
  color: #fff;
}

.about-travel .info-box:hover .show-text,
.about-travel .hide-text{display: none;}
.about-travel .info-box:hover .hide-text{display: block;color: #fff;
  padding: 0 10px;font-size: 16px; -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;}

 .about-block{padding-top: 100px;} 
 .about-block .inner-block.top-sec{padding-top: 50px;} 
 .about-block .spl-text{color:#25408f}
 .about-block .inner-block.top-sec .row{align-items: center;}