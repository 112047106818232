$color-primary: #25408f;
$color-secondary: #21ade4;

$color-secondary-light: #d4eff9;


$color-primary-light: #707070;

$color-primary-dark: #28b485;

$color-gray-dark: #777;
$color-white: #fff ;
$color-black: #000;
