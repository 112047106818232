.customViewer {
    border-color: #250b04;
    border-width: 1px;
    border-style: solid;
  }
  .customViewer > canvas {
    width: 100%;
  }
  .customPrevBtn {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    width: 48px;
    color: #fa5b35;
    background-color: #f3f3f3;
    border-color: #fa5b35;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
  }
  .customNextBtn {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    width: 48px;
    color: #fa5b35;
    background-color: #f3f3f3;
    border-color: #333333;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
  }
  .customPages {
    display: inline-block;
    width: calc(100% - 2 * 50px);
  }
  .customWrapper {
    height: 40px;
    font-size: 24px;
    padding: 12px;
    padding-bottom: 0;
    background-color: #fff;
  }
  .customButton {
    /* height: 40px;
    font-size: 24px;
    padding: 12px;
    padding-bottom: 0; */
    background-color: #1b1010;
  }