.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
}

.clientSignatureCanvas {
  display: block;
  border: 2px solid #b9c8df;
  /* width: 100%; */
  width: 240px;
  height: 100px;
}
