.housing-info .filter-blk .filter-fielld {
  margin-right: 100px;
}

.housing-info .house-list li {
  font-size: 16px;
  line-height: 26px;
  font-family: "MontserratRegular";
  color: #606060;
  width: 45%;
  display: inline-block;
  vertical-align: top;
  padding: 0 20px 25px 35px;
  position: relative;
}
.housing-info .house-list li.house {
  color: #000;
  font-family: "MontserratBold";
  font-size: 18px;
}
.house::before,
.housing-info .house-list li.phone::before,
.housing-info .house-list li.web-site::before,
.housing-info .house-list li.city::before {
  content: "\f015";
  position: absolute;
  left: 0;
  top: -1px;
  font-size: 26px;
  color: #21ace3;
  font-family: "FontAwesome";
  background: none;
}
.housing-info .house-list li.phone::before {
  content: "\f095";
  left: 5px;
}
.housing-info .house-list li.city::before {
  content: "\f041";
  left: 5px;
}
.housing-info .house-list li.web-site::before {
  content: "\f0ac";
  left: 5px;
}
.housing-info .detail-block .block-box {
  padding: 30px 30px 10px;
  margin-bottom: 20px;
}
.housing-info .housing-opt .head-title {
  background: #21ace3;
}
.housing-info .housing-opt .card .card-body {
  padding: 20px;
}
.housing-info .housing-opt .list-arrow li {
  padding: 0 0 10px;
}
.housing-info .housing-opt .list-arrow li::before {
  display: none;
}
.housing-info .housing-opt .list-arrow a {
  font-size: 16px;
  padding-left: 40px;
  display: block;
  min-height: 30px;
}
.housing-info .housing-opt .list-arrow span {
  background: #21ace3;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  line-height: 30px;
  font-size: 16px;
  position: absolute;
}

.housing-info .detail-block .house-list .popup-content {
  padding: 30px !important;
}
.housing-info .detail-block .popup-content img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.housing-info .detail-block .popup-content div {
  height: 100%;
}
.housing-info .detail-block .popup-content .close {
  opacity: 1;
  position: absolute;
  background: #25408f;
  color: #fff !important;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  top: 10px;
}
.event-sec.events .head-title {
  background: #21ace3;
}
.event-sec.events .card .card-title {
  margin-bottom: 0;
}
.event-sec.events .date {
  color: #21ace3;
  padding: 0;
  font-size: 14px;
  font-family: "MontserratRgular";
}
.event-sec.events .date::before {
  display: none;
}
.event-sec.events .card .card-text {
  padding-top: 10px !important;
}

.about-travel.event-sec .card {
  padding: 0;
}
.about-travel.event-sec .card .card-body {
  padding: 15px 15px 20px;
}
.housing-info .filter-value {
  margin: 0;
}
.housing-info .pagination-blk {
  float: right;
}
.filter-blk .filter-fielld select,
.filter-blk .filter-fielld input {
  margin: 0 !important;
}
