.registr-info {
  padding: 70px 0;
  background: #e7effc;
}
.registration-form {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 10px rgba(37, 64, 143, 0.1);
  padding: 50px 100px;
}
.registration-form .form-block {
  border-bottom: 2px solid #999;
  padding-bottom: 20px;
  margin-bottom: 50px;
}
.registration-form .inner-block {
  padding-top: 30px;
}
.main-page .form-block select {
  background: url("../images/arrow-down.png") no-repeat;
  background-position: 93% 18px;
}
.registration-form .button {
  padding: 0 30px 0 30px;
  min-width: 200px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px !important;
  background: #25408f !important;
  border-radius: 30px !important;
  color: #fff !important;
  border: 0;
}
.registration-form .button.btn-next {
  float: right;
}
.registration-form .button.btn-prev {
  background: none !important;
  border: 2px solid #21ace3;
  color: #25408f !important;
}
.registration-form .button.btn-prev:hover {
  color: #fff !important;
}
.registration-form .button::after {
  display: none;
}
.registration-form .form-group label {
  padding-left: 10px;
}
.registration-form .vendor-tab-list {
  padding-bottom: 35px;
  margin: 30px 0 35px;
  border-bottom: 2px solid #cbcedd;
  text-align: center;
}
.registration-form .vendor-tab-list .btn {
  background: none !important;
  color: #21ace3;
  border: 0;
  font-family: "MontserratRegular";
  font-size: 16px;
  line-height: 22px;
  opacity: 1;
  position: relative;
  padding: 0;
}
.registration-form .vendor-tab-list .btn.disabled {
  color: #000;
}
.registration-form .vendor-tab-list .btn::before {
  content: "";
  position: absolute;
  bottom: 40px;
  width: 25px;
  height: 25px;
  /* background: #21ace3; */
  background: url('../images/check-icon.png') no-repeat; background-size: cover;
  background-size: 60%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  background-color: #21ace3;
  padding: 17px;
  background-position: center;
}

.registration-form .vendor-tab-list .btn-secondary:focus,
.registration-form .vendor-tab-list .btn-secondary.focus {
  box-shadow: none !important;
}

.registration-form .form-action {
  margin-bottom: 30px !important;
}
.certificate-pic img {
  width: 100%;
  height: 100%;
}

.file-upload input {
  display: none;
}
.file-upload .file-label {
  border: 2px solid #b9c7e1;
  background: #eaeef7;
  padding: 0 35px 0 60px !important;
  height: 50px;
  font-size: 18px !important;
  line-height: 50px;
  font-family: "MontserratRegular" !important;
  border-radius: 30px;
  text-align: center;
  margin: 0;
  color: #25408f !important;
  cursor: pointer;
  position: relative;
}
.file-upload .file-label::before {
  content: "";
  position: absolute;
  left: auto;
  margin-left: -30px;
  top: 11px;
  background: url(../images/upload-icon.png) no-repeat 0 0;
  width: 20px;
  height: 20px;
}

.file-upload span {
  display: block;
}

.previewText,
.image-container {
  display: inline-block;
  border: 2px solid #b9c7e1;
  border-radius: 5px;
  width: 80px;
  height: 60px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  margin-top: 21px;
  vertical-align: middle;
  margin-left: 20px;
}
.image-container img {
  width: 100%;
  height: 100%;
}

.click-link {
  color: #25408f;
  position: relative;
  font-family: "MontserratBold";
  padding: 10px 0;
  display: inline-block;
}
.click-link:hover {
  color: #21ace3;
}
.click-link span {
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px 0 0;
}
.accolades-sec .row.first-row .form-group {
  margin: 0 !important;
}
.accolades-sec .row .form-group,
.accolades {
  margin: 30px 0 0;
}
.remove-btn {
  background: url(../images/delete.png) no-repeat center center;
  width: 35px;
  height: 40px;
  top: 30px;
  position: relative;
  padding: 0;
  font-size: 0;
}
.add-btn {
  background: url(../images/plus.png) no-repeat center center;
  width: 52px;
  height: 52px;
  display: inline-block;
}

.accolades-sec .row,
.accolades {
  position: relative;
}
.certification .remove-btn,
.license .remove-btn {
  top: 60px;
  position: absolute;
  right: 0;
}
.license .block.col-4 {
  flex: 0 0 31.333333%;
  max-width: 31.333333%;
}
.accolades .remove-btn,
.preferences .add-btn {
  position: absolute;
  right: -48px;
  top: 0;
}
.preferences .add-btn {
  top: 45px;
  right: -100px;
}
.note-grid {
  color: #000;
  font-family: "MontserratRegular";
  margin: 10px 0 0;
  display: inline-block;
  font-size: 14px;
}
.note-grid em {
  color: #838383;
}
.registration-form .form-block.preferences .first-row {
  margin-bottom: 50px;
}
.registration-form .form-block.preferencesform-control {
  padding: 10px 50px 10px 15px;
}

/*Apply Form Style Start*/
.apply-form .inner-block {
  /* border-top: 2px solid #cbcedd; */
  /* margin: 30px 0;
  padding-top: 50px; */
  /* border-bottom: 2px solid #cbcedd; */
}
.apply-form .inner-block .form-action {
  display: inline-block;
  margin-right: 50px !important;
}
.apply-form .form-action input[type="checkbox"] + label,
.apply-form .form-action input[type="radio"] + label {
  font-size: 14px;
  color: #777777;
  line-height: 24px;
}
.apply-form .small-block {
  flex: 0 0 16.666667% !important;
  max-width: 16.666667% !important;
}

.apply-form .small-block input {
  text-align: center;
}
.apply-form .registration-form .form-block {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 10px;
}
.registration-form .check-sec {
  margin-bottom: 30px !important;
}
.registration-form .check-sec .form-action {
  margin-bottom: 0 !important;
}

.profile-sec {
  margin: 0 auto;
  text-align: center;
}
.profile-sec .image-container {
  float: none;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 0px 20px 50px 10px rgba(9, 36, 157, 0.1);
  position: relative;
}
.profile-sec .file-label {
  width: 45px !important;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 0 !important;
  margin: 0;
  position: relative;
  bottom: 60px;
  right: -50px;
}
.profile-sec .file-label::before {
  left: 27px;
  top: -3px;
  background: url(../images/pic-upload.png) no-repeat 0 0;
  width: 44px;
  height: 44px;
}

.profile-view .edit-profile {
  border: 2px solid #b5cadd;
  padding: 40px;
  border-radius: 5px;
}

.border-pad {
  border: 1px solid #b5cadd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.registration-form .edit-profile .form-block {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.view-page label {
  font-size: 16px;
  color: #8c8c8c;
  font-family: "MontserratRegular";
  position: relative;
  top: 8px;
  margin: 0;
}
.view-page label span {
  color: #000;
}

.edit-btn,
.close-btn {
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 20px 50px 10px rgba(9, 36, 157, 0.1);
}

.view-info.hide .view-page,
.view-info .edit-page,
.view-info.show .edit-page {
  display: none;
}

.view-info.hide .edit-page,
.view-info.show .view-page {
  display: block;
}

.profile-info .view-page .profile-sec {
  text-align: left;
  display: inline-block;
  margin: 0;
}
.profile-info .view-page .profile-sec .image-container {
  margin: 0;
  display: inline-block;
}
.profile-info .view-page .profile-sec .file-label {
  top: -50px;
  right: -140px;
  cursor: default;
}
.profile-info .view-page .profile-sec .image-sec {
  display: inline-block;
  vertical-align: middle;
  width: 220px;
}
.profile-info .view-page .profile-sec .profile-show {
  display: inline-block;
  vertical-align: top;
  padding: 15px 0 0;
}
.profile-info .profile-sec .profile-show p {
  color: #000;
}
.profile-info .profile-sec .profile-show p:hover {
  color: #000;
}

.certification-info .file-upload .file-label {
  padding: 0 20px 0 50px !important;
  font-size: 14px !important;
}

.certification-info .image-container {
  margin-right: 30px;
}
.certification-info .block {
  padding-right: 10px;
  padding-left: 10px;
}
.certification-info .certification .remove-btn {
  right: 0px;
}

.license-info .remove-btn {
  top: 60px;
}

.registration-form .edit-profile .remove-btn {
  background: none;
  width: auto;
  height: auto;
  font-size: 16px;
  left: 25px;
  bottom: 0;
  position: absolute;
  top: inherit !important;
  color: #ff0000;
  font-weight: 700;
}

.registration-form .form-block .row {
  position: relative;
}

.view-page label {
  padding-right: 40px;
  font-size: 14px;
}
.speciality-field.edit-page .form-group,
.license-field.edit-page .form-group {
  margin: 30px 0 25px;
}

.registration-form .edit-profile .interests-field label,
.registration-form .edit-profile .recruiter-field label {
  padding-bottom: 20px;
}

.edit-profile .btn-block .close-btn.btn-prev {
  float: left;
}
.edit-profile .btn-block .close-btn.btn-next {
  float: right;
}
.file-upload .file-label {
  width: 180px;
  display: inline-block !important;
}
.file-upload .image-container {
  margin-top: 0;
}

.edit-profile .experience-info .form-check-input {
  width: 30px;
  margin: 10px 15px 0;
  height: 30px;
}
.edit-profile .socila-info .social-icons {
  vertical-align: top;
}

.contact-form .textarea {
  height: 120px !important;
  resize: none;
}
.certification h4 {
  padding-left: 35px;
}

.edit-profile .recruiter-field .experience-sec label {
  padding-bottom: 0;
}
.edit-profile .recruiter-field .experience-sec .form-check-input {
  position: relative;
}
.registration-form .certification {
  padding: 0 !important;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.registration-form .step3 .remove-btn {
  border: none;
}
.registration-form .step4 .add-btn {
  border: none;
  top: 48px;
}
/*======= RESPONSIVE ========*/
/*1599 to 1440 */
@media (max-width: 1599px) {
}

/*1439 to 1360 */
@media (max-width: 1439px) {
}

/*1359 to 1280 */
@media (max-width: 1359px) {
}

/*1279 to 1152 */
@media (max-width: 1279px) {
}

/*1151 to 1024 */
@media (max-width: 1151px) {
.banner-inner .item{height: 330px;}
}

/*1023 to 992 */
@media (max-width: 1023px) {
  .vendor-tab-list span {
    padding-left: 0;
  }
  .vendor-tab-list span:last-child{
    padding-right: 0;
  }
  .vendor-tab-list span button {
    font-size: 14px !important;
    width: 100%;
    height: 100%;
  }

}

/*1023 to 970 */
@media (max-width: 991px) {
  .registration-form .step4 .add-btn {
    top: 60px;
  }
}

/* 992 */
@media only screen and (min-width: 992px) {
}

/*969 to 900 */
@media (max-width: 969px) {
}

/*899 to 800 */
@media (max-width: 899px) {
}

/*799 to 768 */
@media (max-width: 799px) {
  .form-block.personal-info {
    padding: 0 15px;
  }
  .form-block.contact-info{
    padding: 0 15px;
  }
  .form-block.emergency-continfo{
    padding: 0 15px;
  }
}
/*767 to 736 */
@media (max-width: 767px) {
  .vendor-tab-list span {
    padding: 0 5px 10px !important;
  }
  .vendor-tab-list span:last-child{
    padding-right:5px;
  }
  .registration-form .step2 {
    padding: 0 15px;
  }
  .registration-form .step3 {
    padding: 0 15px;
  }
  .registration-form .step4 {
    padding: 0 15px;
  }
  .registration-form .check-field h4 {
    position: relative;
    top: -2px;
    padding-left: 5px;
  }
  .form-block.accolades-sec.certification .remove-btn {
    position: relative;
    left: 19px;
  }
  .registration-form .inner-block {
    padding-top: 0;
  }
  .registration-form .step4 .add-btn {
    border: none;
    top: 44px;
  }
}

/*735 to 667 */
@media (max-width: 735px) {

}

/*666 to 600 */
@media (max-width: 666px) {
}

/*599 to 568 */
@media (max-width: 599px) {
}


/*567 to 480  */
@media (max-width : 567px) {
  .registration-form .step4 .add-btn {
    top: 50px;
    right: 7px;
    
  }
  .registration-form .step3 .image-container.image-fill {
    margin: 10px;
}
}

/*479 to 414  */
@media (max-width : 479px) {
  .vendor-tab-list span {
    width: 100% !important;
  }
}

/*413 to 375*/
@media (max-width : 413px) {
}

/*374 to 320*/
@media (max-width : 374px) {
}

@media (min-width: 992px) and (min-height: 900px) {
}
