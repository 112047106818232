.btn-text {
    &:link,
    &:visited {
        // font-size: $default-font-size;
        color: $color-primary-light;
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px solid $color-primary-light;
        padding: 3px;
        transition: all .2s;
    }

    &:hover {
        // background-color: $color-primary;
        color: $color-black !important;
        box-shadow: 0 1rem 2rem rgba($color-black, .15);
        transform: translateY(-2px);
    }

    &:active {
        box-shadow: 0 .5rem 1rem rgba($color-black, .15);
        transform: translateY(0);
    }
    &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba($color-black,.2) !important;
    }
}