.profile-block.latestjobs .inner-content .job-info li {
  min-width: auto !important;
  margin: 0 20px 0 20px;
}
.profile-block.latestjobs .inner-content .job-info li h4 {
  font-size: 18px !important;
  line-height: 24px !important;
}
.profile-block.latestjobs .inner-content li.job-position {
  margin: 0 20px 0 20px !important;
  width: 180px !important;
}
.profile-block.latestjobs .inner-content li.job-time {
  width: 220px !important;
}
.profile-block.latestjobs .inner-content .job-location {
  margin: 0 0 0 -50px !important;
}

.profile-block {
  background: none !important;
}
.profile-block .job-list-blk {
  padding: 50px 0;
}
.profile-block .btn-block {
  text-align: center;
  margin: 70px 0 0;
}
.profile-block .btn-block .read-more {
  font-size: 20px;
  padding: 0 25px 0 0px;
  font-family: "MontserratBold";
}

.profile-block .item {
  padding: 0 0 50px;
  text-align: left;
}

.profile-block .block-box .text {
  padding: 0 10px 20px !important;
}
.profile-block .block-box .text h4 {
  font-size: 18px !important;
  padding: 0 0 10px;
}
.profile-block .news-block .block-box {
  padding: 5px 5px;
  box-shadow: 0px 5px 10px 0px rgba(9, 36, 157, 0.1);
  margin: 0 0 20px;
  min-height: 500px;
}
.profile-block .inner-content {
  margin: 0 !important;
}
.profile-block h4.sub-title {
  color: #25408f;
}

.profile-block .right-block {
  padding-left: 30px;
}
.profile-block .block-box {
  padding: 20px 5px 5px;
}
.profile-pic {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #25408f;
  overflow: hidden;
}
.profile-pic img {
  width: 100%;
  height: 100%;
}
.profile-block .profile-edit li {
  border-bottom: 1px solid #eeeeee;
}
.profile-block .profile-edit .block {
  padding: 20px 25px 30px;
}
.profile-block .profile-edit li:last-child {
  border: 0;
}
.profile-block .profile-edit h6 {
  font-family: "MontserratRegular";
  line-height: 20px !important;
  padding: 0 0 15px;
}
.profile-block .profile-edit .profile-text {
  text-align: center;
}
.profile-block .profile-edit .profile-text p:last-child {
  padding-bottom: 0;
}

.profile-block .profile-edit .find-job .button {
  margin: 20px 0;
}
.profile-block .profile-edit .button {
  width: 200px;
  margin: 5px 0;
  padding: 0 20px 0 20px;
  font-size: 14px;
  text-align: left;
}

.profile-block .share-fields {
  background: #e3ebf8;
  padding: 25px 25px;
  text-align: center;
}
.profile-block.latestjobs .inner-content .job-info {
  margin-bottom: 20px;
}

.profile-block .team-block .card {
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: 0px 5px 10px 0px rgba(9, 36, 157, 0.1);
  margin: 0 0 10px;
  padding: 20px 0 0;
}
.profile-block .team-block .card .pic {
  padding: 5px;
  border: 0;
  width: 100%;
}
.profile-block .team-block .card .card-title {
  min-height: 0;
}
.head-title {
  background: #21ace3;
  padding: 15px 30px;
  font-size: 22px;
  line-height: 28px;
  color: #fff;
  font-family: "MontserratRegular";
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.profile-block .news-block .date {
  padding: 0 0 10px 25px !important;
  font-size: 14px;
  display: inline-block;
}
.profile-block .news-block .date::before {
  background: url("../images/calander-small.png");
  width: 15px;
  height: 15px;
  top: 1px !important;
}
.profile-block .news-block .slick-prev,
.profile-block .news-block .slick-next {
  bottom: inherit !important;
  top: -35px !important;
  left: inherit !important;
  right: 0;
}
.profile-block .news-block .slick-prev {
  right: 55px;
}
.profile-block .social-icons a {
  width: 35px;
  height: 35px;
  background: #25408f;
  color: #fff;
  line-height: 35px;
  overflow: hidden;
}
.profile-block .social-icons a:hover {
  color: #fff;
  background: #20ade3 !important;
}
.profile-block .social-icons a::before {
  line-height: 35px;
  font-size: 16px;
}

.profile-block .team-block .social-icons a.linkedin {
  background: #0071ad;
}

.profile-block .team-block .social-icons a.linkedin:before,
.profile-block .team-block .social-icons a.comment:before {
  line-height: 30px !important;
}

.profile-block .team-block .social-icons a.comment {
  background: #636363;
}
.profile-block .designation,
.profile-block .city {
  padding: 0 0 10px;
  text-align: center;
}
.profile-block .designation::before,
.profile-block .city::before {
  left: auto;
  margin-left: -30px;
}
.profile-block .list-job {
  padding: 30px 0 100px;
}
.profile-block .team-block .card p.card-text {
  line-height: 22px;
  min-height: auto;
}
.profile-block .profile-edit {
  margin-bottom: 20px !important;
}

.profile-block .find-job .file-label {
  background: none;
  border: 0;
  position: relative;
  padding: 0 0 0 15px !important;
  font-size: 14px !important;
  line-height: 20px;
  height: auto;
  margin: 0;
}

.profile-block .find-job .file-label::before {
  background: url("../images/add-icon.png");
  height: 20px;
  width: 18px;
  left: auto;
  top: 0;
}
.profile-block .news-block .sub-title {
  padding-left: 15px;
  padding: 0 0 25px;
}
.profile-block .news-block .slick-next {
  right: 15px;
}
.profile-block .news-block .block-box .pic {
  height: 200px;
}
