/* resume-view */
.resume-view .header-block{background: #3c52a3; padding: 50px 0; height: auto;}
.resume-view .header-block h1{color: #fff;padding: 0;font-size: 50px !important;}
.resume .header,.resume footer{display: none;}
.resume-view  .contact{float: right; background: #37b0e5; width:550px;position: relative;padding: 20px 0 20px 80px;}
.resume-view  .contact span{color: #fff;font-size: 22px;}
.resume-view  .contact span:last-child{padding-bottom: 0;}
.resume-view  .contact .phone:before, .resume-view  .contact .mail:before {font-size: 26px;color: #fff;}
.resume-view .contact .mail:before {font-size: 22px;}
.resume-view  .contact::before{content: ""; position: absolute;width:100%;height: 100%;background: url("../images/resume-shape.png") no-repeat 0 0;left:0;top: 0;}
.resume-view  .container{width: 90%;max-width: initial !important;}
.resume-view  .experience-blk h2{color: #1eabe3;font-size: 40px !important;text-transform: uppercase;}
.resume-view  .experience-blk .left-block{float: left;width: 250px;}
.resume-view  .experience-blk .right-block{float: left;width: 70%;padding-left: 30px;}
.resume-view  .date{font-size: 22px !important; font-weight: 500 !important; color: #3c52a3 !important;padding: 0;}
.resume-view  .date::before{display: none;}
.resume-view  .experience-blk .details-sec{display: flex; align-items: center;padding-bottom: 50px;position: relative;padding-left: 30px;border-left:5px solid #dcdbdb;}
.resume-view  .experience-blk .details-sec:last-child{padding-bottom: 0;}
.resume-view  .info b{color: #333;font-size: 26px;padding: 0;line-height: 34px; }
.resume-view  .info p,.resume-view  .info span{color: #333;font-size: 20px;padding: 0;line-height:30px; font-family: "MontserratRegular";}
.resume-view  .info span{color: #787878;}
.resume-view  .experience-blk .content{padding: 50px 0 80px;}
.resume-view  .experience-blk .details-sec::before,
.resume-view  .block.education .info b:before{content: "";position: absolute;left:-10px;width: 15px;height: 15px;border-radius: 50%;background: #37b0e5;}
.resume-view  h3{color: #1eabe3;font-size: 34px !important;text-transform: uppercase;}
.resume-view  .block.education .info b{padding: 0 30px 0 50px;position: relative;}
.resume-view  .block.education .info b:before{left: 25px;top: 13px;}
.resume-view  .block{padding-bottom: 80px;}
.resume-view  .block h3 span{color:#333333;font-size: 30px;}
.bottom-sec{background: #3c52a3;padding: 20px 0;border-top: 10px solid #37b0e5;}


@media only screen and (max-width: 991px) {
.resume-view .header-block h1  {font-size: 40px !important;}  
.resume-view .contact{width: 450px;}    
.resume-view .experience-blk h2 {font-size: 36px !important;}
.resume-view h3{font-size: 30px !important;}
.resume-view .block h3 span {font-size: 26px;}
.resume-view .experience-blk .right-block {width: 82%;}
.resume-view .info b{font-size: 22px;line-height: 28px;}
.resume-view .info p, .resume-view .info span {font-size: 18px;line-height: 28px;} 
.resume-view .block.education .info b:before {top: 10px;}
.resume-view .date {font-size: 20px !important;}
.resume-view .block { padding-bottom: 50px;}
}

@media only screen and (max-width: 767px) {
.resume-view .experience-blk .details-sec {display: block;}
.resume-view .experience-blk .left-block,.resume-view .experience-blk .right-block {float: none; width: 100%;padding-left: 0;}
.resume-view .experience-blk .date{display: block;padding-bottom: 10px;}
.resume-view .info b {line-height: 34px;}
.resume-view .experience-blk .content {padding: 30px 0 50px;}
.resume-view .contact {width: 100%;padding: 20px 30px;}
.resume-view .contact::before,.resume-view .block.education .info b::before{display: none;}
.resume-view .header-block {padding: 30px 0;}
.resume-view .contact span,.resume-view .contact .mail:before {font-size: 18px;}
.resume-view .contact .phone:before{font-size: 22px;}
.content-area{padding: 50px 0;}
.resume-view .block:last-child{padding: 0;}
.resume-view .experience-blk .details-sec::before{top: 40%;}
.resume-view .block h3 span {font-size: 20px;}
.resume-view h3 {font-size: 24px !important;}
.resume-view .block { padding-bottom: 30px;}
.resume-view .block.education .info b,.resume-view .block.education .info .date{display: block;padding: 0;}

}

@media only screen and (max-width: 480px) {
.resume-view .header-block h1 {font-size: 24px !important;}
.resume-view .contact {width: 100%;padding: 15px 20px;}
}
