.slick-prev:before,
.slick-next:before {
  color: $color-primary-light !important;
}
.slick-slider {
  -webkit-tap-highlight-color: none !important;
}

.slick-initialized .slick-slide {
  // padding: 0px !important;
  padding: 0px;
}

.section--home__benefits {
  .slick-prev:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid $color-primary-light;
    display: inline-block;
  }
  .slick-next:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid $color-primary-light;
    border-bottom: 15px solid transparent;
    display: inline-block;
  }
  .slick-prev,
  .slick-next {
    top: 32%;
  }
}
