iframe[name="Trade Desk Tracking - Travel Nurses - All Pages"] {
  position: absolute;
}
@media only screen and (min-width: 1200px) {
  .container {
    /* removed imp J */
    /* max-width: 1170px; */
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1280px) {
  .team-block .slick-prev {
    left: -5px;
  }
  .team-block .slick-next {
    right: -5px;
  }
}

/*Large devices (desktops, less than 1200px)*/

@media only screen and (max-width: 1199px) {
  h2 {
    font-size: 28px !important;
    line-height: 34px !important;
  }
  .container {
    /* NEW UI */
    max-width: 95% !important;
    /* padding: 0 !important; */
  }
  .logo {
    width: 130px;
    line-height: 56px;
  }
  /* .sticky .header .logo {
    width: 120px;
  } */
  .header {
    height: 80px;
  }
  .header .right-block {
    /* padding: 15px 0; */
    /* padding: 25px 0; */
  }
  /* .nav .nav-item {
    margin: 0 5px;
  }
  .nav .nav-item a {
    font-size: 16px;
  }
  .button {
    font-size: 14px;
  } */

  .home-serach .search-block .form-filed {
    width: 260px;
    margin-bottom: 20px;
  }
  .search-block .form-block1 .select-box,
  select {
    width: 160px !important;
  }

  /*latestjobs-block*/

  .latestjobs .inner-content .job-info li {
    margin: 0 10px 0 10px !important;
    min-width: 245px;
  }
  .latestjobs .inner-content li.job-location {
    min-width: 140px !important;
    height: 120px !important;
    /* padding: 10px 10px !important; */
    margin-left: -60px !important;
  }
  /* .latestjobs .inner-content .job-info li:last-child {
    float: right;
  } */

  .latestjobs .inner-content .job-position h4 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .latestjobs .inner-content .job-position {
    max-width: 270px;
  }

  /* Recruiters*/
  /* .team-block .card .card-title {
    min-height: 52px;
  } */

  .social-icons a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .social-icons a::before {
    line-height: 35px;
    font-size: 16px;
  }
  .social-icons a.linkedin::before,
  .social-icons a.comment::before,
  .social-icons a.insta::before {
    line-height: 30px;
  }
  /*search-block jhani main*/
  .search-block .form-block .form-filed,
  select,
  input {
    /* width: 275px; */
  }
  .search-block .form-block .form-filed {
    /* width: 210px; */
    margin-bottom: 20px;
  }
  /*jhani main*/
  /* Fotter Style */
  .logo-block.col-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .sitemap-block.col-5 {
    flex: 0 0 46%;
    max-width: 46%;
  }
  .contact-block.col-3 {
    flex: 0 0 29%;
    max-width: 29%;
  }
  footer .footer-inner .block {
    padding: 0 10px;
  }
  footer .nav .nav-item {
    min-width: 35%;
  }
  footer .address,
  footer .phone,
  footer .mail {
    font-size: 14px;
  }

  /* filter-blk */
  .filter-blk .filter-fielld {
    margin-right: 5px;
    width: 50%;
  }
  .filter-blk .filter-fielld.show-num {
    width: 20%;
  }
  /* jhani */
  /* .filter-blk .filter-fielld:last-child {
    width: 28%;
  } */
  .filter-blk .filter-fielld:last-child {
    width: 100%;
  }
  .filter-blk .filter-fielld .select-box {
    width: 200px !important;
  }
  .bottom-filter .filter-fielld.show-num {
    width: 25%;
  }

  /*job-details-block*/
  .job-details-block .inner-content .detail-info li:first-child {
    width: 75%;
  }
  .job-details-block .block-box .job-post-info span {
    width: 260px;
  }
  .job-details-block .inner-content p,
  .job-details-block .inner-content li,
  .job-details-block .inner-content span {
    font-size: 14px;
  }

  .job-details-block .comparison-blk .inner-text {
    padding: 30px 20px;
  }
  .job-details-block .block-box .inner-text {
    padding: 30px 20px;
  }
  .event-sec .card .card-title {
    font-size: 16px;
    line-height: 22px;
  }
  .related-jobs .jobs-blk {
    padding: 0 10px 0 5px;
  }
  .related-jobs .jobs-blk h4 {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .related-jobs .slick-prev {
    left: -40px;
  }
  .related-jobs .slick-next {
    right: -40px;
  }

  /* profile-block */
  .profile-block.latestjobs .inner-content li.job-position {
    margin: 0 0 15px !important;
    width: auto !important;
  }
  .profile-block .list-job {
    padding: 30px 0 50px;
  }
  .profile-block.latestjobs .job-position p {
    padding: 0;
  }
  .profile-block .right-block {
    padding-left: 15px;
  }
  .sub-title {
    font-size: 20px !important;
    line-height: 26px;
  }
  .profile-block .profile-edit .block {
    padding: 20px 10px 30px;
  }
  .profile-block .profile-edit .button {
    width: 100%;
    font-size: 14px;
  }
  .profile-block .share-fields {
    padding: 25px 10px;
  }
  .head-title {
    padding: 15px 15px;
  }
  .profile-block.latestjobs .inner-content .job-info li {
    width: 100% !important;
    display: block;
    margin: 0 0 15px !important;
    min-width: 100%;
    padding: 0 15px;
    min-width: 100% !important;
  }
  .profile-block .btn-block {
    margin: 30px 0 0;
  }
  .profile-block.latestjobs .inner-content .job-info li.job-location {
    margin: 0 auto 15px !important;
    width: 100%;
    height: 100px !important;
    padding: 10px 20px !important;
  }
  .profile-block.latestjobs .inner-content .job-info li.btn-section {
    border-top: 1px solid #ccc;
    padding: 10px 15px 0;
  }
  .profile-block.latestjobs .inner-content .job-info li.btn-section .button {
    width: 130px;
    float: right;
    margin: 0;
  }
  .profile-block.latestjobs .inner-content .job-info li:last-child {
    float: none;
  }
  .profile-block.latestjobs .inner-content .form-check {
    text-align: left;
    display: inline-block;
  }

  .profile-block.latestjobs .inner-content .forward-link {
    padding: 0 !important;
  }
  .profile-block.latestjobs .inner-content .forward-link::before {
    display: none;
  }
  .profile-block.latestjobs .inner-content .job-info {
    width: 45%;
    display: inline-block;
    margin: 0 15px 30px;
    padding: 0;
  }

  /* Blog Details */
  .related-news .news-text h4 {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .newsdetails-block .most-viewed .pic {
    width: 85px;
  }
  .most-viewed {
    padding: 15px 10px 20px;
  }
  .newsdetails-block .event-sec .pic {
    height: 200px;
    width: 100%;
  }

  /* Housing Info */

  .housing-info .filter-blk .filter-fielld {
    margin-right: 20px;
    width: 30%;
  }
  .housing-info .filter-blk .filter-fielld .form-block {
    width: 230px;
  }
  .housing-info .form-block input[type="text"] {
    width: 100%;
  }
  .housing-info .filter-blk .filter-fielld .form-block button {
    right: 0;
  }

  .registration-form {
    padding: 50px 50px;
  }

  .approval-block .sealpic-blk {
    width: 200px;
    height: 200px;
  }
  .approval-block .text-sec {
    padding: 20px 0 20px 40px;
  }
  .author-blk .text {
    width: 74%;
  }

  .job-details-block .properties-blk .content .right-block {
    width: 62%;
    padding-left: 20px;
  }
  .copyrights p,
  .copyrights p:last-child {
    float: none;
    text-align: center;
  }
}

@media only screen and (max-width: 1024px) {
  .job-detail-apply {
    width: 170px !important;
  }
}

/*Medium devices (tablets, less than 992px)*/
@media only screen and (max-width: 991px) {
  .job-info .icon-share {
    padding: 0px 9px !important;
  }
  .job-info .jod-detail_apply-top {
    position: absolute;
    right: 25px;
    top: 25px;
  }
  .job-info .job-detail .share-btn {
    top: initial !important;
    right: 20px !important;
    bottom: 10px !important;
    z-index: 999;
  }
  .dollar-sign {
    padding: 6px 4px -12px -1px;
    position: absolute;
    left: 21px;
    width: 23px;
    /* height: 22px; */
    font-weight: bold;
    font-size: 17px !important;
  }

  .job-info.block-box ul li.btn-section {
    margin-top: 0px !important;
  }

  .est-pay-res {
    display: flex !important;
    max-width: 100% !important;
    padding-left: 29px !important;
    padding-bottom: 25px !important;
  }

  .form-block input[type="text"] {
    width: 75%;
  }
  p,
  .descrop,
  .descrop p {
    font-size: 14px;
    line-height: 26px;
  }
  .inner-banner {
    height: 250px !important;
  }

  .menu-block {
    display: block;
    position: fixed;
    right: -320px;
    height: 100%;
    width: 320px !important;
    top: 0;
    overflow-x: auto;
    background: rgb(37, 64, 143);
    /* background: -moz-linear-gradient(
      top,
      rgba(37, 64, 143, 1) 0%,
      rgba(41, 137, 216, 1) 56%,
      rgba(41, 137, 216, 1) 56%,
      rgba(33, 172, 227, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(37, 64, 143, 1) 0%,
      rgba(41, 137, 216, 1) 56%,
      rgba(41, 137, 216, 1) 56%,
      rgba(33, 172, 227, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(37, 64, 143, 1) 0%,
      rgba(41, 137, 216, 1) 56%,
      rgba(41, 137, 216, 1) 56%,
      rgba(33, 172, 227, 1) 100%
    ); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#25408f', endColorstr='#21ace3',GradientType=0 );
  }
  .menu-open .menu-block {
    right: 0;
  }
  .menu-open {
    overflow: hidden;
  }

  .menu-block .nav li {
    width: 100%;
    margin: 0;
    /* border-bottom: 1px solid #21ace3; */
    border-bottom: 1px solid #788765;
  }
  .menu-block .nav li a {
    padding: 5px 20px 5px 20px;
    display: block;
    color: #fff;
  }
  .menu-block .nav a:hover,
  .menu-block .nav a.active,
  .header .button:hover {
    background-color: rgb(33, 172, 227) !important;
    color: #fff;
    /* background: -moz-linear-gradient(
      left,
      rgba(33, 172, 227, 0.8) 0%,
      rgba(35, 117, 184, 0.8) 51%,
      rgba(37, 64, 143, 0.8) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(33, 172, 227, 0.8) 0%,
      rgba(35, 117, 184, 0.8) 51%,
      rgba(37, 64, 143, 0.8) 100%
    );
    background: linear-gradient(
      to right,
      rgba(33, 172, 227, 0.8) 0%,
      rgba(35, 117, 184, 0.8) 51%,
      rgba(37, 64, 143, 0.8) 100%
    ) !important; */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc21ace3', endColorstr='#cc25408f',GradientType=1 );
  }
  .menu-icon {
    display: block;
  }
  .menu-open .menu-icon {
    display: none;
  }
  .nav-tabs .nav-link {
    padding: 0 25px 10px 25px;
  }
  .nav-tabs .nav-link.active::before,
  .nav-tabs .nav-link:hover::before {
    top: 43px;
  }

  .menu-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    -webkit-transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
    transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
    display: none;
  }
  .menu-mask.active {
    width: 100%;
    height: 100%;
    /* opacity: 0.7; */
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    z-index: 999;
    display: block;
  }

  .mn-open.menu-icon {
    right: 300px;
  }
  .menu-close {
    display: block;
  }
  .header .btn-sec {
    float: none !important;
  }

  .header .button {
    display: inline-block !important;
    margin-top: 15px;
    height: auto;
    line-height: 20px;
    padding: 6px 30px !important;
    font-size: 16px;
  }

  /* .header .login-btn,
  .header .button {
    background: none !important;
    border: 0;
    padding: 5px 20px 5px 20px !important;
    margin: 0 !important;
    border-bottom: 1px solid #788765;
    border-radius: 0;
    font-size: 16px;
    color: #fff;
    line-height: 28px !important;
    height: auto;
  } */
  .header .right-block {
    padding: 0;
  }

  .dropdown-menu.show {
    transform: translate3d(0, 38px, 0px) !important;
  }
  .menu-block .nav .dropdown-menu {
    background-color: #25408f;
    width: 100%;
    border-radius: 0;
    left: 0 !important;
  }
  .menu-block .nav .dropdown-item {
    padding: 0;
    border-bottom: 0;
  }
  .menu-block .nav .dropdown-menu .nav-item a {
    line-height: 28px;
  }

  .pad-tb {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .title {
    font-size: 32px !important;
    line-height: 42px !important;
    margin: 0 0 30px !important;
    padding: 0 0 10px !important;
  }

  .subscribe-block .form-block input[type="text"] {
    width: 70%;
  }
  .subscribe-block .button {
    width: 30%;
  }

  .info-block .block.col-4 {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 0 30px;
  }
  .search-block {
    padding: 30px 10px;
  }

  /*testimonial-block*/
  .testimonial-block .right-block {
    float: right;
    width: 50%;
  }
  .testimonial-block .slick-prev,
  .testimonial-block .slick-next {
    bottom: 0;
  }

  /*team-block*/
  .team-block .slick-prev {
    left: -35px;
  }
  .team-block .slick-next {
    right: -35px;
  }
  .team-block .card .card-title {
    margin-bottom: 5px;
    font-size: 16px;
  }

  /*resources-block*/
  .resources-block .block.col-4 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
  }

  /*About Page*/
  .about-block .inner-block {
    padding: 50px 0;
  }
  .about-block .inner-block .left-block,
  .about-block .inner-block .right-block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .about-block .inner-block .left-block {
    margin-bottom: 30px;
  }
  .about-block .inner-block .about-travel .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
  }
  .about-block .inner-block .about-travel .col-4:last-child {
    margin-bottom: 0;
  }
  .about-block .inner-content {
    margin: 0;
  }
  /* .about-block .right-block .pic {
    margin: 0 auto;
    max-width: 400px;
  } */

  /* Recruiters Page*/
  .team-block .block {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .team-block .card {
    margin: 0 0 30px;
  }
  .team-block .inner-block {
    padding: 50px 0 0;
  }
  .team-block .card .card-text {
    min-height: 70px;
  }

  .home .team-block .slick-arrow {
    display: none !important;
  }

  .slick-dots li button {
    background: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #25408f;
    border: 2px solid #25408f !important;
  }

  .slick-dots li.slick-active button {
    background: #21ace3;
    color: #21ace3;
  }
  .slick-dots li button::before {
    display: none !important;
  }

  /* Recruiters Details Page*/

  .team-detail-blk .inner-block .left-block .pic {
    width: 100%;
    margin: 0 auto;
    height: 350px;
  }
  .team-detail-blk h1 {
    font-size: 32px !important;
    line-height: 42px !important;
    padding: 0 0 10px;
  }
  .team-detail-blk .designation {
    font-size: 16px;
    padding: 0 0 20px;
  }
  .team-detail-blk .phone,
  .team-detail-blk .mail {
    font-size: 16px;
    padding: 0 40px 15px 30px;
  }
  .team-detail-blk .phone::before {
    font-size: 20px;
  }
  .team-detail-blk .mail::before {
    font-size: 18px;
    top: -1px;
  }
  .team-detail-blk .inner-block .right-block {
    padding-left: 15px;
  }
  .team-detail-blk .inner-block .inner-content {
    padding: 30px 0 30px;
  }
  .team-block .card figure {
    /* height: 200px; */
    height: 100%;
  }

  /*search-block */
  /* .search-block.pic-destiny .form-block .form-filed,
  select,
  input {
    width: 220px;
  } */

  /* Jobs page style*/
  .latestjobs .inner-content .job-info li {
    width: 100%;
    display: block;
    margin: 40px 0 15px !important;
    min-width: 100%;
  }

  /* .latestjobs .inner-content .job-info li.btn-section {
    border-top: 1px solid #ccc;
    padding: 10px 15px 0;
  } */
  .latestjobs .inner-content .job-info li.btn-section .button {
    width: 190px;
    margin: 0 auto;
    padding: 0 60px 0 20px;
  }
  .latestjobs .inner-content .job-info li:last-child {
    float: none;
    margin-bottom: 0 !important;
  }
  .latestjobs .inner-content .form-check {
    text-align: left;
    display: inline-block;
  }
  .latestjobs .inner-content .form-check label {
    font-size: 14px;
  }

  .latestjobs .inner-content .forward-link {
    padding: 0 !important;
  }
  .latestjobs .inner-content .forward-link::before {
    display: none;
  }

  .latestjobs .inner-content .job-info {
    width: 45%;
    display: inline-block;
    margin: 0 15px 30px;
    padding: 20px;
  }

  .latestjobs .inner-content .job-location h5 {
    line-height: 20px !important;
    font-size: 16px !important;
    padding: 0;
  }
  .latestjobs .inner-content .job-location img {
    margin-bottom: 5px;
  }
  .latestjobs .inner-content {
    margin: 30px 0 30px;
  }

  .latestjobs .inner-content p {
    font-size: 14px;
  }

  .latestjobs .inner-content .job-position h4 {
    padding: 0 0 5px;
  }

  .social-block .com-block {
    padding: 0 5px;
  }

  /* Fotter Style */
  .social-block .row,
  footer .row {
    margin: 0;
  }
  footer .footer-inner {
    padding: 50px 0;
  }
  .logo-block.col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  footer .logo-block .button {
    display: block;
    width: 190px;
    margin: 0 auto;
  }
  .sitemap-block.col-5 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .contact-block.col-3 {
    flex: 0 0 35%;
    max-width: 35%;
  }

  footer .social-icons {
    margin: 30px auto;
    width: auto;
  }
  footer .footer-logo {
    margin: 0 auto;
  }
  .logo-block .content-area {
    margin-bottom: 30px;
  }
  .copyrights p {
    font-size: 14px;
  }

  /* filter-blk */
  .filter-blk .filter-fielld .select-box,
  .filter-blk .filter-fielld input {
    font-size: 14px;
    padding: 0 15px;
  }
  .form-block input[type="text"] {
    width: 75%;
  }
  .filter-blk .filter-fielld .form-block {
    width: 100%;
  }
  .filter-blk .filter-fielld .select-box {
    width: 155px !important;
  }
  .filter-blk .filter-fielld label {
    font-size: 14px;
  }
  .filter-value {
    margin: 0 10px 0 0;
  }
  .filter-blk .filter-fielld .form-block button {
    right: 0;
  }

  /*job-details-block*/

  .job-details-block .left-block.col-9 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .job-details-block .right-block.col-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .job-details-block .inner-content .detail-info li:first-child {
    width: 100%;
  }
  .job-details-block .inner-content .detail-info .form-check {
    text-align: left;
    display: inline-block;
    padding-bottom: 5px !important;
  }
  .job-details-block .block-box .inner-text .btn-section .button {
    width: 130px;
    float: right;
    margin: 0;
  }
  .job-details-block .job-info .job-status li,
  .benefits-blk .sub-list li {
    width: 100%;
  }
  .properties-blk .content-area {
    padding-left: 0;
    float: none;
  }
  .properties-blk {
    text-align: center;
  }
  .properties-blk figure.pic {
    float: none;
    margin: 0 auto 20px;
  }

  .benefits-blk .team-block .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
  .benefits-blk .team-block .card {
    padding: 0;
  }

  .benefits-blk .team-block .card .card-body {
    padding: 10px 10px 20px;
  }
  .job-details-block .inner-content .job-info li {
    display: block;
    padding: 0 0 10px;
  }
  .benefits-blk .team-block .card .card-title {
    font-size: 14px;
    min-height: 0;
  }
  .event-sec .slick-prev,
  .event-sec .slick-next {
    top: 45%;
  }
  .related-jobs {
    padding: 50px 0 0;
  }
  .properties-blk .block {
    margin-bottom: 30px;
  }
  .properties-blk figure.pic {
    width: 90px;
    height: 80px;
  }
  .job-details-block {
    padding: 0 0 50px;
  }

  /*Blog Page*/
  .news-page .inner-block .block.col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .news-page .inner-content {
    margin-bottom: 0;
  }
  .news-page .inner-block {
    padding: 50px 0 50px;
  }
  .news-page .pagination-blk {
    margin-top: 30px;
  }
  .related-news .news-text {
    padding: 15px 10px;
  }
  .newsdetails-block .related-news .date {
    font-size: 14px;
  }
  .newsdetails-block .inner-content .left-block .pic {
    margin: 0 0 20px;
  }
  .newsdetails-block .inner-content h2 {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  .newsdetails-block .inner-content {
    margin: 30px 0 0;
  }
  .newsdetails-block .inner-block {
    padding: 0 0 50px;
  }
  .newsdetails-block .image-gallery-thumbnail {
    width: 23%;
    height: 105px;
  }
  .newsdetails-block .image-gallery-left-nav .image-gallery-svg,
  .newsdetails-block .image-gallery-right-nav .image-gallery-svg {
    height: 25px;
    width: 25px;
  }
  .newsdetails-block .inner-content .category {
    bottom: 150px;
  }
  .news-block .block-box .pic {
    height: 250px;
  }

  /*profile-block*/
  .profile-block .left-block,
  .newsdetails-block .right-block.col-3 {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .profile-block .right-block,
  .newsdetails-block .left-block.col-9 {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .profile-block.latestjobs .inner-content .job-info {
    width: 100%;
    margin: 0 0 30px;
  }
  .profile-block .list-job {
    padding: 10px 0 30px;
  }
  .profile-block .btn-block {
    margin: 0;
  }
  .profile-block .btn-block .read-more {
    font-size: 18px;
  }

  /*housing-info*/
  .housing-info .left-block.col-9 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .housing-info .right-block.col-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .housing-info .event-sec .slick-prev,
  .housing-info .event-sec .slick-next {
    top: 35%;
  }
  .housing-info .detail-block .block-box {
    padding: 20px 30px 10px;
  }
  .housing-info .house-list li.house {
    font-size: 16px;
  }
  .housing-info .house-list li {
    padding: 0 10px 25px 40px;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
  }
  .housing-info .filter-blk .filter-fielld {
    margin-right: 10px;
    width: auto;
    min-width: 20%;
  }
  .housing-info .filter-blk .filter-fielld .select-box {
    min-width: 100px !important;
  }
  .housing-info .housing-opt .list-arrow a {
    font-size: 14px;
    line-height: 20px;
  }
  .housing-info .housing-opt .list-arrow span {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
  }
  .housing-info .housing-opt .list-arrow a {
    padding-left: 35px;
  }
  .housing-info .filter-blk .filter-fielld .form-block {
    width: 180px;
  }
  .housing-info .left-block .form-block input[type="text"] {
    padding: 0 50px 0 15px;
  }
  .blog-search .categary-select select,
  .blog-search .seach-box input {
    width: 200px !important;
  }

  .newsdetails-block .image-gallery-slide-wrapper {
    height: 350px;
  }

  .related-jobs .slick-prev {
    left: -30px;
  }
  .related-jobs .slick-next {
    right: -30px;
  }

  .event-sec .head-title {
    padding: 15px 10px;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }

  .bottom-filter .filter-fielld.show-num {
    display: none;
  }
  .pagination-blk.float-right {
    float: none !important;
  }
  .housing-info .detail-block.pb-50 {
    padding-bottom: 30px;
  }

  .related-jobs .slick-dots li.slick-active button {
    background: #21ace3;
    color: #21ace3;
  }
  .related-jobs .slick-dots li button::before {
    display: none !important;
  }
  .related-jobs {
    padding: 30px 0 0;
  }

  /*Registration Form Style*/
  .registr-info {
    padding: 30px 0;
  }
  .registration-form {
    padding: 30px 30px;
  }
  .registration-form .form-block {
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  .registration-form .button {
    padding: 0 20px 0 20px;
    min-width: 150px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 16px !important;
  }

  .registration-form .vendor-tab-list .btn::before {
    display: none;
  }
  .main-page .form-control {
    font-size: 14px !important;
  }

  .main-page select.form-control {
    padding: 10px 10px;
  }
  .main-page select.form-control {
    padding: 10px 30px 10px 20px;
  }

  .previewText,
  .image-container {
    width: 70px;
    height: 50px;
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 10px;
  }
  .remove-btn {
    background: url(../images/delete-small.png) no-repeat center center;
    width: 30px;
    height: 30px;
    top: 40px;
  }
  .add-btn {
    background: url(../images/plus-small.png) no-repeat center center;
    width: 30px;
    height: 30px;
  }

  .searchjobs-block .list-job {
    text-align: center;
  }
  .searchjobs-block .bottom-filter {
    text-align: center;
  }

  .newsdetails-block .breadcrumb {
    padding: 10px 0 !important;
    border-bottom: 1px solid #d6e2f0;
    margin-bottom: 0 !important;
  }
  .newsdetails-block .inner-content {
    margin: 30px 0 0;
  }
  .bottom-filter {
    text-align: center;
  }
  .housing-info .pagination-blk {
    float: none;
  }
  .approval-block .text-sec {
    width: 70%;
    padding: 20px 0 20px 30px;
  }
  .policy-content h4 {
    padding: 0 0 10px 0;
  }
  .policy-content h5 {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .privacy-policy .policy-content .list-arrow {
    padding: 20px 0 0;
  }
  .privacy-policy .policy-content .list-arrow li {
    padding: 0 10px 20px 25px;
    font-size: 14px;
  }

  /* makeiteasy-page */
  .makeiteasy-page .main-block .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .makeiteasy-page .right-block {
    padding-right: 15px;
  }
  .makeiteasy-page .left-block {
    left: 0;
    padding-left: 15px;
    margin-bottom: 30px;
  }
  .makeiteasy-page .main-block:nth-child(2n) .left-block {
    order: 0;
    left: inherit;
    right: 0;
    padding-right: 15px;
    padding-left: 15px;
  }
  .makeiteasy-page .main-block {
    margin-left: 0;
    margin-bottom: 30px;
    padding: 30px 0;
    text-align: center;
  }
  .makeiteasy-page .main-block:nth-child(2n) {
    margin-right: 0;
  }
  .makeiteasy-page .main-block:nth-child(2n) .right-block {
    padding-right: 15px;
    padding-left: 15px;
  }
  .makeiteasy-page .block .pic {
    width: 400px;
    height: 300px;
    margin: 0 auto;
  }

  /* Banner */
  .banner {
    height: 360px;
  }
  .banner-text .banner-title {
    font-size: 46px;
    line-height: 56px;
  }
  .banner .pic-box {
    width: 100%;
    height: 180px !important;
    padding: 0;
  }
  /* jhani main */
  /* .search-block .form-block .form-filed {
    width: 180px;
  } */

  /* jhani main */

  .searchjobs-block .pagination-blk {
    float: none;
    justify-content: center;
  }

  .latestjobs .inner-content .job-position h4 {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .home .team-block .button {
    margin-top: 50px;
  }
  .benefits-blk .team-block .slick-dots {
    display: none !important;
  }

  .job-details-block .team-block .slick-dots {
    display: none !important;
  }

  /* jhani main */
  /* .search-block .form-block .form-filed,
  select,
  input {
    width: 275px;
  } */

  /* jhani main */

  .jobdemonad .job-section .inner-content {
    display: none;
  }

  .jobdemonad .job-section .responsive-sec {
    display: block;
    padding: 30px 0 0;
  }
  .jobdemonad .job-list {
    box-shadow: 0px 5px 10px 0px rgba(9, 36, 157, 0.1);
    width: 100%;
    padding: 20px;
  }
  .jobdemonad .responsive-sec .slick-arrow {
    background: #25408f;
    width: 30px;
    height: 30px;
    z-index: 9;
    transition: all 0.3s ease-in-out;
  }
  .jobdemonad .responsive-sec .slick-next {
    right: -5px;
  }
  .jobdemonad .responsive-sec .slick-prev {
    left: -5px;
  }
  .slick-prev:before {
    content: "\f104";
    font-family: "FontAwesome";
  }
  .slick-next:before {
    content: "\f105";
    font-family: "FontAwesome";
  }
  .registration-form .block {
    padding-right: 10px;
    padding-left: 10px;
  }
  .certification .remove-btn,
  .license .remove-btn {
    top: 65px;
  }
  .edit-profile .profile-sec .image-container {
    display: block;
    margin: 0 auto;
  }
  .registration-form .vendor-tab-list .btn {
    background: #25408f !important;
    color: #fff;
    padding: 10px 15px;
    min-height: 60px;
  }
  .registration-form .vendor-tab-list .btn.disabled {
    background: #ddd !important;
  }
  .registration-form .vendor-tab-list {
    margin: 0 0 35px;
  }
  .about-block .about-travel .text-block {
    height: auto;
    text-align: center;
    margin-bottom: 20px;
  }
  .about-block .about-travel .text-block,
  .about-block .about-travel .col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .awards-certifications .inner-content {
    margin: 30px 0 0;
  }

  .about-block {
    padding-top: 50px;
  }
  .about-block .inner-block.top-sec {
    padding-top: 30px;
  }
  .search-block .form-block .form-filed {
    margin-bottom: 20px;
  }

  .properties .main-content .properties-blk {
    width: 100%;
  }
  .properties-blk .content .left-block {
    width: 250px;
  }
  .properties-blk .content .pic {
    height: 200px;
  }
  .properties .main-content .brand-pic {
    margin-bottom: 30px;
  }
  .properties .filter-blk {
    margin-bottom: 0;
  }

  .job-details-block .properties-blk .content .left-block,
  .job-details-block .properties-blk .content .right-block {
    width: 100%;
    padding-left: 0;
  }
  .job-details-block .properties-blk .content .left-block {
    margin-bottom: 20px;
  }
  .edit-profile .file-upload .file-label {
    width: 145px;
  }

  .preferences .add-btn {
    top: 60px;
    right: -90px;
  }
  .preferences .remove-btn {
    right: -45px;
    top: 9px;
  }

  .destination-block {
    margin-top: 50px;
  }

  .search-block .block {
    margin-bottom: 20px;
  }
  .latestjobs .inner-content .job-location img {
    width: 120px;
    height: 110px;
  }

  .latestjobs .inner-content .job-info li.job-location {
    width: 120px;
    display: inline-block;
    margin: 0 20px 0 0 !important;
    min-width: 120px !important;
    height: 110px !important;
    /* padding: 10px !important; */
  }
  /*  */

  .latestjobs .inner-content .job-info li.job-position {
    /* min-width: 50%; */
    display: inline-block;
    width: 50%;
    /* padding: 0; */
    margin: 0 !important;
  }
  .news-block.news-page .pagination.pagination-blk {
    float: none;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1106px) {
  .keyword-search-responsive {
    margin-top: -25px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .profile-block.latestjobs .inner-content .job-info:nth-child(4),
  .profile-block.latestjobs .inner-content .job-info:nth-child(5) {
    display: none;
  }
  .benefits-page .block {
    margin-bottom: 0;
  }
  .team-block .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .social-icons a::before {
    line-height: 30px;
  }
  .social-icons a.instagram::before {
    line-height: 28px;
  }
  .social-icons a.linkedin::before,
  .social-icons a.comment::before {
    line-height: 26px;
  }
  .registration-form .certification .block {
    padding: 0 5px;
  }

  .btn-section .button {
    margin: 30px auto 0;
  }
}

@media only screen and (max-width: 800px) {
  ul.indices-list {
    padding-bottom: 15px;
    display: block;
    width: 100% !important;
  }
  .comparison-blk .indices-list li {
    width: auto;
  }
  li.price-list h6 {
    padding-bottom: 0px;
    display: inline-block;
  }
}

/*Small devices (landscape phones, less than 768px)*/
@media only screen and (max-width: 767px) {
  .search-jobs_copy-btn {
    width: 100%;
    padding-top: 25px;
  }

  .latestjobs .jobs-show-more {
    width: 100% !important;
  }

  .css-1vr111p-option,
  .css-1qprcsu-option {
    display: -webkit-inline-box !important;
  }
  .sync.refresh-btn {
    padding: initial !important;
    top: 0;
  }
  .refresh-btn.sync::before {
    z-index: 8 !important;
  }
  .custum-jobs-search .button,
  .home .button,
  .footer-inner .button {
    padding: 0 60px 0 30px !important;
  }

  .button {
    padding: 0 30px 0 30px;
  }
  .button.meet-team-btn {
    padding: 0 60px 0 35px !important;
  }

  /* Jhani main */
  /* .search-block .form-block {
    left: 125px;
  } */
  /* jhani main */

  .button.reset-btn::after {
    left: 125px;
  }

  /* home page changes
   */
  .home .search-block .form-block1 .button.reset-btn::after {
    left: 117px;
  }
  .descrop,
  .descrop p {
    font-size: 16px;
    padding: 0 0 20px;
    line-height: 26px;
  }
  h2 {
    font-size: 24px !important;
    line-height: 32px !important;
    padding: 0 0 10px;
  }
  h4 {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .info-block .block.col-4 {
    flex: 100%;
    max-width: 100%;
  }

  .info-block .block.col-4:last-child {
    margin: 0;
  }
  .subscribe-block .form-block input[type="text"] {
    width: 65%;
  }
  .subscribe-block .button {
    width: 35%;
  }

  /*testimonial-block*/
  .testimonial-block .inner-content {
    text-align: center;
  }

  .home .team-block a.button {
    margin-top: 50px;
  }
  .testimonial-block .slick-arrow {
    display: none !important;
  }

  .testimonial-block .right-block,
  .testimonial-block .left-block {
    float: none;
    width: 100%;
    margin-bottom: 30px;
  }
  .testimonial-block .content-area .button {
    margin-top: 20px;
  }
  .testimonial-block .slick-prev,
  .testimonial-block .slick-next {
    bottom: 0;
    left: 44%;
  }
  .testimonial-block .slick-next {
    right: 44%;
    left: inherit;
  }

  /*team-block*/
  .team-block .card .card-title {
    min-height: 0;
    font-size: 16px;
  }

  /*resources-block*/
  .resources-block .block.col-4 {
    flex: 100%;
    max-width: 100%;
  }
  .resources-block .calculator-blk {
    text-align: center;
  }
  .resources-block .calculator-blk h3 {
    font-size: 20px !important;
    line-height: 26px !important;
    padding: 0;
    width: 100%;
  }
  .resources-block .calculator-blk figure {
    display: none;
  }

  .resources-block .inner-content h4,
  .resources-block .calculator-blk .top-sec {
    padding: 0 0 10px;
  }
  h4 {
    padding: 0 0 10px;
  }
  .resources-block .block.col-4:last-child {
    margin-bottom: 0;
  }

  .resources-block .text p {
    padding-bottom: 15px;
    font-size: 14px;
    line-height: 22px;
  }

  /*jobdemonad-block*/

  .jobdemonad .inner-content {
    margin: 30px 0 30px;
  }

  /*news-block*/
  .news-block .block.col-6 {
    flex: 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
  .news-block .block.col-6:last-child {
    margin: 0;
  }
  .news-block .block-box .text {
    padding: 0 10px 20px;
  }

  /*About Page*/
  .about-block .inner-block .about-travel .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
  .about-block .info-box .text h5 {
    font-size: 16px !important;
  }

  /* Recruiters Page*/
  .team-block .block {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* Recruiters Details Page*/
  .team-detail-blk .video-block {
    padding: 10px;
  }

  /*search-block*/
  .search-block.pic-destiny .form-block .form-filed,
  .search-block.pic-destiny .form-block select,
  .search-block.pic-destiny .form-block .button {
    width: 260px;
    display: block;
    margin-bottom: 20px;
  }
  .search-block.pic-destiny {
    padding: 0 15px;
  }

  .search-block .form-block {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .destination-block .subscribe-block .form-block input[type="text"] {
    width: 100%;
    margin-bottom: 20px;
  }

  /* Jobs page style*/
  .latestjobs .inner-content .job-info {
    width: 400px;
    margin: 0 0 30px;
  }
  .latestjobs .inner-content,
  .searchjobs-block .latestjobs .inner-content {
    margin: 10px 0;
  }
  .latestjobs .inner-content .job-info:last-child {
    margin: 0;
  }
  .home .latestjobs .inner-content {
    margin: 10px 0 30px;
  }

  /* social-block */
  .social-block .com-block {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
    height: 420px;
  }
  .social-block .com-block .block {
    height: 100%;
  }
  .social-block .block iframe {
    vertical-align: bottom;
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
  }

  /* Fotter Style */
  footer .footer-inner .block {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  footer .footer-inner .block:last-child {
    margin-bottom: 0;
  }
  footer .nav .nav-item {
    padding: 0 5px 5px 0;
  }
  footer .nav .nav-item a {
    font-size: 14px;
    line-height: 22px;
  }
  .logo-block .content-area {
    margin-bottom: 0;
  }
  .copyrights p {
    float: none !important;
    text-align: center;
  }

  .search-block .form-block1 {
    display: inline-block;
  }
  .search-block {
    margin-top: 0;
    border-radius: 0;
    text-align: center;
  }
  .home-serach .container {
    max-width: 100% !important;
    padding: 0;
  }
  .home-serach .search-block .form-filed,
  .search-block input.form-filed,
  .search-block .form-block1 .select-box,
  select {
    width: 230px;
    margin: 0 0 20px !important;
    display: block;
  }
  /* .search-block .form-block .button,
  .search-block .block .css-1r4vtzz,
  .search-block .block .css-48ayfv {
    width: 230px !important;
    margin: 0;
  } */
  .search-block .form-block .button,
  .search-block .block .css-48ayfv {
    width: 100% !important;
    margin: 0;
  }
  .search-block .form-block .block {
    display: block !important;
    max-width: 100%;
  }
  .destination-block {
    height: 550px;
  }
  .destination-block .title {
    padding: 0;
  }
  .search-block.pic-destiny .form-block .button {
    margin: 0;
    width: 230px;
  }

  /* pagination-blk */
  .pagination-blk {
    display: inline-block;
    text-align: center;
  }
  .pagination-blk.float-right {
    float: none !important;
  }
  .bottom-filter .filter-fielld.show-num {
    display: none;
  }

  /* filter-blk */
  .filter-blk .filter-fielld,
  .filter-value {
    width: auto !important;
    margin: 0;
    display: block;
  }

  .filter-blk .filter-fielld .select-box {
    display: block;
  }
  /* .filter-blk .filter-fielld.show-num .select-box,
  .filter-blk .filter-fielld .select-box,
  .filter-blk .filter-fielld .form-block {
    width: 230px !important;
  } */
  .filter-blk .filter-fielld.show-num .select-box,
  .filter-blk .filter-fielld .select-box {
    width: 230px !important;
  }
  .filter-blk {
    text-align: center;
  }

  .find-area {
    display: block;
  }
  .filter-blk .find-area::after {
    content: "\f0b0";
    font-family: "FontAwesome";
    font-size: 16px;
    padding-left: 5px;
  }
  .filter-blk .filter-fielld.show-num {
    display: none !important;
  }
  .filter-blk .filter-fielld .select-box,
  .filter-blk .filter-fielld input {
    font-size: 14px;
  }

  .search-bar {
    display: none;
    padding: 20px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 20px 50px 10px rgba(9, 36, 157, 0.1);
  }
  .search-bar.bar-open {
    display: block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .filter-blk {
    padding: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .filter-blk .content {
    display: inline-block;
  }

  /*job-details-block*/
  .job-details-block .side-bar .block {
    width: 100%;
    margin: 0 0 20px;
    min-height: auto;
  }
  .event-sec.events .card .pic,
  .event-sec .card .pic {
    height: 200px;
  }
  .properties-blk .block {
    padding: 0 10px;
  }
  .job-details-block .block-box .date-sec .date,
  .job-details-block .block-box .date-sec .duration {
    width: 100%;
    display: block;
  }
  .job-details-block .main-content .col-3.right-block {
    padding: 30px 10px 0;
  }

  .searchjobs-block .breadcrumb {
    /* padding: 10px 0 !important; */
    margin: 0;
  }

  .job-details-block .block-box.properties-blk h3 {
    padding-bottom: 20px !important;
  }
  .job-details-block .block-box.properties-blk .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .comparison-blk .indices-list {
    margin-bottom: 15px !important;
  }
  .indices-list li h6 {
    padding: 0 0 5px;
  }
  .comparison-blk .indices-list li {
    width: 100%;
  }

  .job-details-block .block-box.properties-blk p {
    padding-bottom: 5px;
  }

  .job-details-block .related-jobs .inner-content {
    padding-top: 20px;
    margin: 0;
  }

  .job-details-block .block-box .inner-text {
    padding: 20px 20px;
  }
  .job-details-block .block-box .inner-text h3 {
    font-size: 18px !important;
    line-height: 24px !important;
    padding-bottom: 10px;
  }
  .job-details-block .block-box .job-post-info span {
    width: auto;
    margin-right: 20px;
  }

  .benefits-blk .team-block {
    padding: 20px 20px;
  }

  .grey-block {
    padding: 10px 20px;
  }
  .container {
    max-width: 95% !important;
  }
  .date::before {
    top: -2px;
  }
  .job-details-block .city::before {
    top: -5px;
  }
  .job-details-block .job-post-info .time::before {
    top: -10px;
  }
  .job-details-block .right-block.col-3,
  .job-details-block .left-block.col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .job-details-block .side-bar {
    text-align: center;
  }
  .job-details-block .side-bar .block {
    width: 45%;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 20px;
    height: 400px;
  }
  .event-sec.events .card .pic,
  .event-sec .card .pic {
    height: 150px;
  }
  .weather-sec figure {
    height: 400px;
  }
  .weather-sec figure img {
    height: 100%;
  }
  .benefits-blk .team-block h3 {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .related-jobs .jobs-blk {
    padding: 0;
  }
  /* .logo {
    width: 110px;
  } */
  .header {
    height: 75px;
  }
  .sticky .header {
    height: 70px;
  }
  /* .sticky .header .logo {
    width: 100px;
  } */
  .menu-icon {
    padding: 5px;
  }
  .team-detail-blk .phone,
  .team-detail-blk .mail {
    padding: 0 50px 15px 30px;
  }
  .related-news-blk .related-news,
  .mostview-blk .most-viewed {
    width: 48%;
    float: left;
    border: 1px solid #cccfde;
    margin: 0 5px 10px;
    padding: 0;
  }
  .newsdetails-block .event-sec .head-title {
    margin-bottom: 10px;
  }
  .newsdetails-block .event-sec .pic {
    height: 200px;
    width: 100%;
    border: 0;
    float: none;
  }

  .newsdetails-block .most-viewed .text {
    padding: 15px 10px;
    float: none;
    width: 100%;
  }
  .newsdetails-block .share-blk {
    padding: 0;
  }
  .newsdetails-block .left-block {
    margin-bottom: 20px;
  }

  .newsdetails-block .image-gallery-thumbnail {
    height: 105px;
  }

  .profile-block .left-block,
  .newsdetails-block .right-block.col-3,
  .profile-block .right-block,
  .newsdetails-block .left-block.col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .profile-block .left-block {
    margin-bottom: 50px;
  }
  .profile-block .designation::before {
    top: 0;
  }
  .profile-block .city::before {
    top: -3px;
  }
  .newsdetails-block .date,
  .author {
    padding: 0 60px 10px 30px;
    font-size: 16px;
  }
  .newsdetails-block .date::before {
    top: 0px;
  }
  .newsdetails-block .author::before {
    top: -5px;
  }

  /*housing-info*/
  .housing-info .left-block.col-9,
  .housing-info .right-block.col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .housing-info .left-block {
    margin-bottom: 50px;
  }
  .housing-info .left-block .detail-block {
    padding-bottom: 30px;
  }

  .housing-info .filter-blk {
    background: #243f8f;
    padding: 20px 0 !important;
  }
  .housing-info .bottom-filter {
    background: none !important;
    padding: 0 !important;
  }
  .housing-info .filter-blk .filter-fielld .select-box {
    width: 230px !important;
    margin: 0 !important;
  }

  .housing-info .filter-blk .filter-fielld {
    display: inline-block;
  }
  .housing-info .filter-blk .filter-fielld label {
    text-align: left;
    color: #fff;
  }
  .housing-info .house-list li {
    padding: 0 10px 20px 35px;
  }
  .house::before,
  .housing-info .house-list li.phone::before,
  .housing-info .house-list li.web-site::before,
  .housing-info .house-list li.city::before {
    font-size: 22px;
  }
  .housing-info .event-sec {
    width: 300px;
    margin: 0 auto 20px;
    text-align: center;
  }
  .housing-info .main-content.pad-tb {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  /*profile-block*/
  .profile-block .profile-edit {
    text-align: center;
  }
  .profile-block .profile-edit .find-job {
    margin: 0 auto;
    border-bottom: 0;
  }
  .profile-block .profile-edit .share-blk {
    border-top: 1px solid #eeeeee !important;
  }
  .profile-block .profile-edit .button {
    width: 230px;
    display: block;
    margin: 0 auto 20px;
  }

  .profile-block .profile-edit .find-job .button {
    display: inline-block;
  }
  .profile-block .profile-edit .progress {
    width: 200px;
    margin: 0 auto;
  }
  .profile-block .profile-edit .button:last-child,
  .profile-block .profile-edit .form-group {
    margin-bottom: 0;
  }
  .profile-block .profile-edit .share-blk .block {
    padding: 30px 10px 30px;
  }
  .profile-block h4.sub-title {
    text-align: center;
  }

  .profile-block .team-block .card .pic {
    width: 400px;
  }

  .pagination .page-link {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .pagination .prve-next {
    padding: 0;
    position: relative;
    text-align: center;
    font-size: 0;
  }
  .pagination .prve-next::before {
    content: "\f100";
    font-family: "FontAwesome";
    font-size: 20px;
    line-height: 40px;
  }
  .pagination .next .prve-next::before {
    content: "\f101";
  }

  .pagination .prve-next.disabled::before {
    content: "\f100";
  }
  .pagination .next .prve-next.disabled::before {
    content: "\f101";
  }

  /* testimonial Page Style */
  .testimonial-page .inner-content h3 {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .testimonial-page .figure-sec .pic {
    width: 300px;
    margin: 20px auto 15px;
  }
  .testimonial-page .inner-content .right-block,
  .testimonial-page .inner-content .left-block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .testimonial-page .inner-content .right-block {
    margin-top: 0;
  }
  .testimonial-page .inner-content .testi-block:nth-child(even) .left-block {
    order: inherit;
  }
  .testimonial-page .figure-sec .pic,
  .testimonial-page .content-box {
    box-shadow: none;
  }
  .testimonial-page .inner-content .row.testi-block {
    box-shadow: 0px 20px 50px 10px rgba(9, 36, 157, 0.1);
    text-align: center !important;
    margin: 0 0 30px;
  }
  .testimonial-page .content-box {
    padding: 10px 20px 20px;
  }
  .testimonial-page .content-box::before,
  .testimonial-page .content-box::after {
    display: none;
  }
  .inner-block.pad-tb {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  /*benefits-page style*/
  .benefits-page .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .add-benefits {
    padding: 50px 0;
  }
  .benefits-page .media .media-body h3 {
    font-size: 18px !important;
    line-height: 24px !important;
    padding: 0 0 5px;
  }

  .benefits-page .media .media-body {
    margin-left: 10px;
  }
  /* .benefits-page .media .pic {
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;
  } */
  /* .benefits-page .media {
    display: block;
    text-align: center;
  } */
  .add-benefits .inner-content .pic {
    height: 300px;
    margin: 0;
  }
  .add-benefits .inner-content .pic::before {
    display: none;
  }
  .benefits-page .add-benefits .inner-content {
    margin: 10px 0 0;
  }
  .benefits-page .add-benefits .list-arrow {
    padding: 0;
  }
  .list-arrow li::before {
    font-size: 14px;
  }
  .title::before {
    width: 150px;
  }

  .search-block .form-block .form-filed,
  select,
  input {
    font-size: 14px;
  }

  .profile-block .item {
    padding: 0;
  }
  .sub-list {
    margin: 10px 0 0 !important;
  }

  /*Registration Form Style*/
  .vendor-tab-list span {
    width: 25%;
    padding: 0 10px;
    display: inline-block;
  }
  .registration-form .vendor-tab-list .btn {
    font-size: 14px;
    line-height: 20px;
  }

  .accolades-sec .row .file-upload .file-label {
    margin-top: 0;
  }

  .file-upload .file-label {
    padding: 0 35px 0 60px !important;
  }

  .accolades-sec select.form-control {
    margin: 0 !important;
  }

  .certification-fields {
    margin-top: 20px !important;
  }
  .news-page .inner-block {
    padding: 30px 0 30px;
  }
  .home .news-block .inner-content {
    margin-bottom: 30px;
  }
  .news-page .pagination-blk {
    margin-top: 0;
  }

  .newsdetails-block .mostview-blk .button {
    margin: 20px 0;
  }

  .click-link {
    font-size: 14px;
    line-height: 20px;
  }
  .click-link span {
    font-size: 20px;
  }

  .main-page .form-group {
    margin-bottom: 20px;
  }

  .accolades-sec .row .form-group,
  .accolades {
    margin: 20px 0 0;
  }
  .first-row .block:first-child .form-group,
  .certification .block:first-child .form-group {
    margin: 0 !important;
  }
  .note-grid {
    font-size: 12px;
  }
  .preferences .add-btn {
    top: 45px;
    right: 0;
  }
  .preferences .remove-btn {
    right: 5px;
    bottom: -30px;
  }
  .registration-form .form-block.preferences .first-row {
    margin-bottom: 30px;
  }
  .registration-form .preferences .block {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .approval-block .sealpic-blk {
    margin: 0 auto;
    float: none;
  }
  .approval-block .text-sec {
    width: 100%;
    padding: 30px 10px 0;
    float: none;
  }
  .approval-block {
    padding: 20px 10px;
    margin: 20px 0 30px;
  }
  .privacy-policy .policy-content .list-arrow {
    padding: 10px 0 20px;
  }
  .privacy-policy .policy-content .list-arrow li {
    width: 100%;
    padding: 0 10px 15px 25px;
  }
  .policy-content {
    padding: 0 10px;
  }
  .makeiteasy-page .small-title span {
    margin-right: 0;
    display: block;
    margin-bottom: 10px;
  }

  /* login-page */
  .login-page .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .login-page .inner-block .pic {
    height: 300px;
  }
  .login-page .form-sec {
    padding: 30px 20px 30px 20px;
  }
  .login-page .form-block {
    padding: 20px 0 0;
  }

  .filter-blk .filter-fielld input {
    width: 100%;
    padding: 0 50px 0 15px;
  }

  .blog-search .categary-select select,
  .blog-search .seach-box input {
    width: 170px !important;
    padding-left: 20px;
    display: inline-block;
  }
  .blog-search label {
    font-size: 14px;
    margin-right: 10px;
  }

  .apply-form .form-action input[type="checkbox"] + label,
  .apply-form .form-action input[type="radio"] + label {
    line-height: 20px;
  }

  .form-action input[type="checkbox"],
  .form-action input[type="radio"] {
    height: 20px;
    width: 20px;
  }
  .form-action input[type="checkbox"] + label:before,
  .form-action input[type="checkbox"] + label:after,
  .form-action input[type="radio"] + label:before,
  .form-action input[type="radio"] + label:after {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .form-action input[type="checkbox"] + label,
  .form-action input[type="radio"] + label {
    display: inline-block;
    padding: 0 0 0 25px;
  }
  .apply-form .inner-block .form-action {
    margin: 0 20px 15px 0 !important;
  }

  ._2ngXYtlcHXA-EjBwuz_RKI {
    display: none !important;
  }
  .home .jobdemonad .inner-content {
    margin: 30px 0 0;
  }
  .registration-form .codephone-blk .block {
    flex: 0 0 41%;
    max-width: 41%;
  }
  .apply-form .registration-form .check-sec,
  .apply-form .registration-form .check-sec .form-action {
    margin-bottom: 10px !important;
  }
  .apply-form .inner-block {
    margin-top: 0;
    padding-top: 20px;
  }

  .related-jobs .slick-prev {
    left: -20px;
  }
  .related-jobs .slick-next {
    right: -20px;
  }
  .remove-btn {
    background: none;
    width: auto;
    height: auto;
    font-size: 16px;
    left: 25px;
    bottom: 0;
    position: absolute;
    top: inherit !important;
    color: #ff0000;
    font-weight: 700;
  }
  .registration-form .form-block .row,
  .edit-profile .view-info .view-page,
  .view-info.hide .edit-page {
    position: relative;
  }
  .edit-profile .view-info .view-page .edit-btn,
  .edit-profile .edit-page .close {
    position: absolute;
    right: 0;
    bottom: -7px;
  }
  .edit-profile .edit-page .close {
    top: 0;
    z-index: 9;
  }
  .profile-view .edit-profile .block,
  .profile-view .edit-profile .license .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .registration-form .edit-profile .remove-btn,
  .registration-form .license-field .remove-btn {
    bottom: -20px;
  }
  .edit-profile .profile-info .edit-btn,
  .view-page label {
    top: 0;
  }

  .file-upload.profile-sec .file-label {
    padding: 0 !important;
    bottom: 50px;
  }
  .profile-info .view-page .profile-sec,
  .profile-info .profile-sec .profile-show p {
    text-align: center;
  }
  .profile-info .profile-sec .profile-show p:before {
    left: auto;
    margin-left: -25px;
  }
  .form-action label h4 {
    font-size: 16px;
  }
  .edit-profile .form-group .empty {
    display: none;
  }
  .edit-profile .block {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .registration-form .license-field .row {
    margin-bottom: 20px;
  }

  .team-detail-blk .inner-content .left-block,
  .team-detail-blk .inner-content .right-block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .team-detail-blk .inner-content .left-block {
    margin-bottom: 30px;
  }

  .team-detail-blk .inner-content .right-block,
  .team-detail-blk .designation {
    text-align: center;
  }
  .team-detail-blk .phone,
  .team-detail-blk .mail {
    padding: 0 20px 15px 30px;
  }
  .team-detail-blk .mail {
    padding-right: 0;
  }
  .filter-blk .filter-fielld {
    margin: 0 0 20px;
  }
  .awards-certifications .slick-arrow {
    display: none !important;
  }
  .awards-certifications .slick-dots {
    bottom: -50px;
  }
  .awards-certifications {
    margin-bottom: 30px;
  }

  /* jhani main */
  .properties .search-block .form-block1 .form-filed {
    display: block;
    /* width: 230px !important; */
    margin: 0 0 20px !important;
  }
  /* jhani main */
  .properties-blk .content .right-block {
    float: none;
    width: 100%;
    padding-left: 0;
  }
  .properties-blk .content .left-block {
    float: none;
    margin-bottom: 20px;
    width: 100%;
  }
  .properties-blk .content .pic {
    height: 300px;
  }
  .job-details-block .properties-blk .content .pic {
    height: 350px;
  }
  .job-details-block .properties-blk .brand-pic {
    float: none;
  }

  .testimonial-page .inner-content .right-block {
    padding-left: 0;
  }
  .testimonial-page .content-box {
    padding: 10px 0 20px;
  }
  .testimonial-page .content-box .text {
    margin-top: 20px;
    padding: 0;
  }
  .testimonial-page .figure-sec {
    box-shadow: none;
  }
  .testimonial-page .content-box .text::after,
  .testimonial-page .content-box .text::before {
    display: none;
  }
  .testimonial-page .figure-sec .pic {
    width: 100%;
    height: 350px;
  }

  .banner .pic-box.col-2 {
    flex: 0 0 32.666667% !important;
    max-width: 32.666667% !important;
  }

  .registration-form .vendor-tab-list .btn {
    margin-bottom: 20px;
    width: 100%;
  }
  .vendor-tab-list span {
    width: 50%;
  }

  .file-upload .file-label {
    width: 160px;
  }
  .edit-profile .file-upload .file-label {
    width: 77%;
  }

  .preferences .accolades {
    margin: 0 0 40px;
  }
  .search-block .block label {
    display: none;
  }

  .job-details-block .job-info .job-status li:before {
    display: block;
  }

  .content-area {
    padding: 0px 0;
  }

  .search-block .form-block .button.reset-btn::after {
    margin-left: 5px;
  }

  .search-block .form-block .button.reset-btn {
    display: block;
  }

  .properties .search-block .form-block .form-filed {
    width: 100% !important;
  }
  .form-filed.icon.furnitured-icon.form-action,
  .form-filed.icon.pet-icon.form-action {
    margin-bottom: 15px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .block-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .related-news-blk .related-news,
  .mostview-blk .most-viewed {
    width: 47%;
    flex: 0 0 47%;
    max-width: 47%;
  }
  .registration-form .block,
  .license .block.col-4 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 5px;
  }
  .registration-form .phone-fileds .block {
    flex: 0 0 41%;
    max-width: 41%;
  }
  .registration-form {
    padding: 30px 30px;
  }
  .destination-block .title {
    margin-bottom: 10px !important;
  }
  .certification .row.first-row .form-group {
    margin: 0 0 20px !important;
  }
  .certification .row.first-row .form-group.file-upload {
    margin: 0 !important;
  }
  .certification .row .form-group {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  #circuleExtern {
    animation: unset !important;
  }
  .search-block .form-block .copy-btn:after {
    left: 50%;
  }
  .TN-search_reset .button:hover,
  .search-block_new,
  .block_new .css-1r4vtzz:hover {
    transform: unset !important;
  }
  .TN-search_reset .button,
  .search-block_new,
  .block_new .css-1r4vtzz,
  .TN-filter {
    transition: unset !important;
  }
  .testimonial-block .slick-prev {
    left: 43%;
  }
  .testimonial-block .slick-next {
    right: 43%;
  }

  .subscribe-block .form-block input[type="text"] {
    font-size: 14px;
  }
}
/*Extra small devices (portrait phones, less than 576px)*/
@media only screen and (max-width: 575px) {
  .estimated.estimated-calc span {
    padding-left: 9px !important;
  }

  /* .search-block .custum-jobs-search .block .css-1r4vtzz,
  .custum-jobs-search .custum-margin,
  .search-block .custum-jobs-search .block .css-48ayfv {
    width: 100% !important;
    margin-bottom: 25px;
  } */
  .banner-text .banner-title {
    font-size: 36px;
    line-height: 40px;
  }
  .title {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  .team-block .slick-prev {
    left: -5px;
  }
  .search-block h1 {
    font-size: 26px !important;
    line-height: 32px !important;
  }
  .team-block .slick-next {
    right: -5px;
  }

  .inner-content {
    margin: 10px 0 0;
  }

  .subscribe-block .form-block {
    width: 100%;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 3px 0 3px;
  }

  .social-icons a::before {
    line-height: 30px;
    font-size: 14px;
  }
  .social-icons a.linkedin::before,
  .social-icons a.comment::before {
    line-height: 25px;
  }
  .social-icons a.instagram::before {
    line-height: 28px;
  }

  /*Resources-block*/
  .resources-block .inner-content {
    margin: 0;
  }
  .block-box {
    padding: 20px 10px;
  }

  /* Recruiters Page*/
  .team-detail-blk .inner-block .left-block .pic {
    height: 300px;
  }

  .team-detail-blk .inner-block .social-icons {
    padding: 20px 20px;
  }
  .team-detail-blk h1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  .breadcrumb a,
  .breadcrumb-item.active {
    font-size: 12px;
    line-height: 22px;
  }
  .team-detail-blk .breadcrumb {
    padding: 10px 0 !important;
  }
  /* .team-block .card .card-title {
    min-height: 52px;
  } */

  /* latestjobs style*/
  .latestjobs .inner-content .job-position h4 {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }

  .jobdemonad .job-list {
    height: 165px;
  }
  .inner-banner {
    height: 200px !important;
  }

  .team-detail-blk .phone,
  .team-detail-blk .mail {
    display: block;
    padding: 0 0 5px 30px;
  }
  .team-detail-blk .content {
    margin-top: 20px;
  }
  .team-detail-blk .content p {
    padding: 0 0 10px;
  }

  /*News Page*/
  .news-page .inner-block .block.col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .related-news-blk .related-news,
  .mostview-blk .most-viewed {
    width: 46%;
  }
  .newsdetails-block .inner-content h2 {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .newsdetails-block .date::before {
    top: -2px;
  }

  /* job-details-block */
  .job-details-block .side-bar .block {
    width: 300px;
    margin: 0 0 20px;
    min-height: auto;
  }
  .event-sec.events .card .pic,
  .event-sec .card .pic {
    height: 200px;
  }

  .profile-block .team-block .slick-initialized .slick-slide {
    padding: 0;
  }
  .add-benefits .inner-content .pic {
    height: 250px;
  }
  .benefits-page .add-benefits .list-arrow li {
    padding: 0 0 20px 25px;
    font-size: 14px;
  }
  .benefits-page .add-benefits .list-arrow li .sub-list li {
    padding: 0 0 10px 15px;
  }
  .profile-block .news-block .slick-initialized .slick-slide {
    padding: 0;
  }

  .housing-info .filter-blk .filter-fielld .form-block,
  .housing-info .filter-blk .filter-fielld .select-box {
    width: 200px !important;
  }

  .about-block .inner-block .about-travel .col-4 {
    margin-bottom: 50px;
    text-align: center;
  }
  .about-block .info-box .text {
    margin-left: 0;
  }
  .menu-icon {
    margin: 10px 0;
  }
  .team-detail-blk .inner-block .inner-content {
    padding: 20px 0 10px;
  }
  .testimonial-block .pic {
    height: 250px;
  }

  .registration-form .button {
    min-width: 120px;
  }
  .main-page select.form-control {
    padding: 10px 35px 10px 10px;
  }
  .main-page .form-control {
    padding: 10px 15px;
  }
  .file-upload .file-label {
    padding: 0 20px 0 45px !important;
  }

  .certification .remove-btn {
    right: inherit;
    left: 15px;
  }
  .vendor-tab-list span {
    width: 50%;
  }
  .registration-form .vendor-tab-list .btn::before {
    bottom: 50px;
    width: 20px;
    height: 20px;
  }
  .main-page .form-control {
    /* font-size: 12px !important; */
    line-height: 26px;
  }
  .registration-form .vendor-tab-list .btn {
    margin-bottom: 20px;
    width: 100%;
  }
  .registration-form .vendor-tab-list {
    padding-bottom: 0px;
    margin: 30px 0 30px;
  }

  .blog-search {
    text-align: center;
  }
  .blog-search .categary-select select,
  .blog-search .seach-box input {
    display: inline-block;
    margin: 0 !important;
    /* width: 200px !important; */
  }
  .blog-search span {
    display: block;
    margin: 0 0 20px;
  }
  .blog-search span:last-child {
    margin: 0;
  }
  .categary-select {
    float: none;
  }
  .blog-search .categary-select label {
    display: block;
    margin-right: 0;
  }

  .apply-form .inner-block .form-action {
    margin-right: 30px !important;
    margin-bottom: 15px !important;
  }
  .news-page .inner-block .text h4 {
    min-height: 0;
  }
  .registration-form .vendor-tab-list .btn::before {
    display: none;
  }
  .profile-view .edit-profile {
    padding: 20px 10px;
  }
  .profile-view .registration-form {
    padding: 20px 15px;
  }
  .profile-view .edit-profile .view-info {
    padding: 20px 10px;
  }
  .profile-sec .image-container {
    width: 150px;
    height: 150px;
  }
  .profile-info .view-page .profile-sec,
  .profile-info .profile-sec .profile-show p {
    text-align: center;
    display: block;
  }
  .profile-info .profile-sec .profile-show .city::before,
  .profile-info .profile-sec .profile-show .phone::before,
  .profile-info .profile-sec .profile-show .mail::before {
    margin-left: -25px;
    left: auto;
  }
  .profile-info .profile-sec .profile-show .city {
    display: inline-block;
  }
  .profile-info .profile-sec .profile-show .city::before {
    margin-left: -30px;
  }
  .profile-info .view-page .profile-sec .image-sec {
    width: auto;
  }
  .profile-info .view-page .profile-sec .profile-show {
    display: block;
  }
  .speciality-field.edit-page .form-group,
  .license-field.edit-page .form-group {
    margin: 0 0 10px;
  }
  .registration-form .edit-profile .remove-btn {
    bottom: 0;
  }

  .registration-form .edit-profile .certification-fields,
  .registration-form .edit-profile .license-field .license,
  .registration-form .edit-profile .speciality-field .row,
  .registration-form .license-field .license {
    margin-bottom: 30px;
  }
  .registration-form .edit-profile .certification-fields .remove-btn,
  .registration-form .edit-profile .speciality-field .remove-btn {
    bottom: -15px;
  }

  .registration-form .license-field .remove-btn {
    bottom: -20px;
  }
  .team-detail-blk .phone::before,
  .team-detail-blk .mail::before {
    left: auto;
    margin-left: -25px;
  }
  .newsdetails-block .share-blk {
    margin: 10px 0 10px;
  }
  .about-block .special-blk {
    padding: 30px 10px;
  }

  .file-upload .file-label {
    width: 125px;
    font-size: 14px !important;
    padding: 0 20px 0 50px !important;
  }
  .certification .row .form-group .previewText,
  .image-container {
    width: 60px;
    height: 50px;
  }
  .main-page .form-group label {
    font-size: 13px;
  }
  .file-upload span {
    font-size: 14px;
  }
  .city-name {
    font-size: 14px;
    line-height: 20px;
  }
}

/*Extra small devices (portrait phones, less than 480px)*/
@media only screen and (max-width: 480px) {
  .dollar-sign {
    left: 10px !important;
  }
  .latestjobs .inner-content .forward-link {
    font-size: 12px;
  }
  .subscribe-block .form-block {
    background: none;
    border-radius: 0;
    width: 100%;
    text-align: center;
  }
  .subscribe-block .form-block input[type="text"] {
    width: 100%;
    margin-bottom: 20px;
  }

  .subscribe-block .button {
    background: none;
    border: 2px solid #21ace3;
    width: 130px;
    float: none;
  }

  .social-block .com-block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .social-block .com-block:last-child {
    margin: 0;
  }

  /* Recruiters Page*/
  .team-block .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .team-block .card .card-title,
  .team-block .card .card-text {
    min-height: 0;
  }

  /* latestjobs style*/
  /* .latestjobs .inner-content .job-info li.btn-section .button {
    width: 120px;
    padding: 0 20px 0 20px;
  } */

  /* Footer style*/
  footer .nav .nav-item {
    min-width: 100%;
  }
  footer .nav .nav-item a {
    padding: 0 0 5px;
  }
  footer .footer-inner {
    text-align: center;
  }
  footer .address::before,
  footer .phone::before,
  footer .mail::before {
    left: auto;
    margin-left: -25px;
  }
  footer .footer-logo,
  footer .contact-block .pic {
    margin: 0 auto;
  }
  footer .contact-block .pic.seal {
    width: 170px;
  }

  .benefits-blk .team-block .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .form-check input + label {
    font-size: 14px;
  }
  .forward-link {
    font-size: 12px;
    padding: 0 !important;
  }
  .forward-link::before {
    display: none;
  }
  .job-details-block .block-box .inner-text {
    padding: 20px 10px;
  }
  .properties-blk .block {
    padding: 0 5px;
  }
  .properties-blk figure.pic {
    width: 80px;
    height: 70px;
  }
  .properties-blk .block {
    margin-bottom: 20px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grey-block {
    padding: 10px 10px;
  }
  .team-detail-blk .inner-block .left-block .pic,
  .newsdetails-block .event-sec .pic {
    width: 100%;
  }
  .related-news-blk .related-news,
  .mostview-blk .most-viewed {
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    border-bottom: 1px solid #cccfde;
  }

  .newsdetails-block .date,
  .author {
    padding: 0 15px 10px 30px;
    font-size: 14px;
  }

  .newsdetails-block .image-gallery-thumbnail {
    width: 31%;
    height: 90px;
  }
  .newsdetails-block .inner-content .category {
    bottom: 130px;
    padding: 10px 25px 10px 20px;
    font-size: 12px;
  }

  .profile-block .team-block .card .pic {
    width: 300px;
  }
  .newsdetails-block .image-gallery-slide-wrapper {
    height: 250px;
  }
  .housing-info .house-list li {
    width: 100%;
    padding: 0 10px 10px 30px;
    font-size: 13px;
  }
  .housing-info .detail-block .block-box {
    padding: 20px 20px 10px;
  }

  .housing-info .filter-blk .filter-fielld {
    margin-right: 5px;
  }
  .housing-info .filter-blk .filter-fielld label {
    text-align: center;
  }
  .housing-info .filter-blk .filter-fielld .form-block,
  .housing-info .filter-blk .filter-fielld .select-box {
    margin: 0 auto 10px !important;
  }
  .housing-info .filter-blk .filter-fielld {
    display: block;
  }
  .about-block .right-block .pic {
    margin: 0 auto;
    max-width: 300px;
  }
  .about-block .right-block .pic::before {
    display: none;
  }
  .news-block .block-box .pic {
    height: 200px;
  }
  .registration-form .block {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .registration-form {
    padding: 30px 10px;
  }
  .accolades-sec .row .form-group,
  .accolades,
  .accolades-sec .row.first-row .form-group {
    margin: 20px 0 0 !important;
  }
  .remove-btn,
  .accolades .remove-btn {
    bottom: 10px;
    position: relative;
  }
  .accolades-sec .row.first-row .block:first-child .form-group {
    margin: 0 !important;
  }
  .preferences .add-btn,
  .preferences .remove-btn {
    right: -5px;
  }
  .approval-block .sealpic-blk {
    width: 160px;
    height: 160px;
  }

  .makeiteasy-page .block .pic {
    width: 270px;
    height: 200px;
  }
  .makeiteasy-page .small-title {
    font-size: 22px;
    line-height: 28px;
    padding: 0 0 15px;
  }

  .login-page .inner-block .pic {
    height: 200px;
  }

  .apply-form .small-block {
    flex: 0 0 25.666667% !important;
    max-width: 25.666667% !important;
    padding-right: 0;
  }

  .registration-form .block.widht-40 {
    flex: 0 0 73.666667% !important;
    max-width: 73.666667% !important;
  }
  .related-jobs .slick-prev {
    left: -10px;
  }
  .related-jobs .slick-next {
    right: -10px;
  }
  .registration-form .vendor-tab-list .btn::before {
    display: none;
  }

  .registration-form .button {
    min-width: 110px;
  }
  .registration-form .edit-profile .speciality-field .remove-btn,
  .registration-form .edit-profile .license-field .remove-btn,
  .registration-form .edit-profile .certification-fields .remove-btn {
    bottom: -25px;
  }
  .author-blk .inner-sec {
    text-align: center;
  }
  .author-blk .text {
    margin-top: 10px;
    width: 100%;
  }
  .author-blk .text .social-icons {
    display: block;
    margin: 20px auto;
    width: 140px;
  }
  .author-blk .author-pic {
    margin: 0;
  }
  .about-block .info-box {
    width: 300px;
  }
  .properties-blk .prop-list .list {
    width: 100%;
  }

  .testimonial-page .figure-sec .pic,
  .properties-blk .content .pic,
  .job-details-block .properties-blk .content .pic {
    height: 250px;
  }
  .edit-profile .certification .file-upload .image-container {
    width: 60px;
  }
  .certification:before {
    display: none;
  }
  .edit-profile .file-upload .file-label {
    width: 100%;
    margin-bottom: 20px;
  }
  .my-story .form-group img,
  .my-story .form-group iframe {
    width: 100% !important;
    height: 150px !important;
  }
  .my-story .form-control-file {
    width: 100%;
    font-size: 12px;
    padding: 10px 15px;
  }
  /* .registration-form .button {
    height: 40px;
    line-height: 40px;
    min-width: 100px;
    padding: 0 10px 0 10px;
    font-size: 14px !important;
  } */
  .file-upload .file-label {
    width: 100%;
  }
  .certification .row .form-group .previewText {
    display: none;
  }
  .edit-profile .certification-fields {
    margin-top: 40px !important;
  }
  .certification-fields .click-link {
    margin: 20px 0 10px;
  }
  .certification h4 {
    padding-left: 0;
  }
  .registration-form h4 {
    font-size: 14px !important;
  }
  .view-page label {
    padding-right: 60px;
  }
  .socila-info .social-icons {
    display: block;
    margin-top: 10px;
  }
  .registration-form .license-field .remove-btn,
  .certification .remove-btn {
    bottom: 0;
    position: relative;
  }
  .preferences .remove-btn {
    bottom: -5px;
  }
  .preferences .add-btn {
    top: 50px;
    right: 0;
  }
  .latestjobs .inner-content .job-info {
    width: 93%;
    padding: 10px;
  }
  .latestjobs .inner-content .job-info li.job-location {
    /* width: 100px; */
    display: inline-block;
    margin: 0 10px 0 0 !important;
    min-width: 100px !important;
    height: 90px !important;
  }
  .latestjobs .inner-content .job-location h5 {
    line-height: 16px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 480px) {
  .searchjobs-block .show-selected1,
  .searchjobs-block .up-selected1 {
    padding-right: 25px !important;
    padding-left: 5px !important;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 992px) {
  .menu-desktop-hide {
    display: none;
    color: #fff;
  }
}
